'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var addAll = require('./add-all.js');
var add = require('./add.js');
var ls = require('./ls.js');
var rmAll = require('./rm-all.js');
var rm = require('./rm.js');
var index = require('./remote/index.js');

function createPin(config) {
  return {
    addAll: addAll.createAddAll(config),
    add: add.createAdd(config),
    ls: ls.createLs(config),
    rmAll: rmAll.createRmAll(config),
    rm: rm.createRm(config),
    remote: index.createRemote(config)
  };
}

exports.createPin = createPin;
