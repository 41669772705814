'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const createUnsubscribe = (options, subsTracker) => {
  async function unsubscribe(topic, handler) {
    subsTracker.unsubscribe(topic, handler);
  }
  return unsubscribe;
};

exports.createUnsubscribe = createUnsubscribe;
