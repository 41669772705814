'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createStat({repo}) {
  async function stat(options = {}) {
    const stats = await repo.stat();
    return {
      numObjects: BigInt(stats.numObjects.toString()),
      repoSize: BigInt(stats.repoSize.toString()),
      repoPath: stats.repoPath,
      version: `${ stats.version }`,
      storageMax: BigInt(stats.storageMax.toString())
    };
  }
  return withTimeoutOption.withTimeoutOption(stat);
}

exports.createStat = createStat;
