'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var add = require('./add.js');
var addAll = require('./add-all.js');
var ls = require('./ls.js');
var rm = require('./rm.js');
var rmAll = require('./rm-all.js');

class PinAPI {
  constructor({codecs, repo}) {
    const addAll$1 = addAll.createAddAll({
      codecs,
      repo
    });
    this.addAll = addAll$1;
    this.add = add.createAdd({ addAll: addAll$1 });
    const rmAll$1 = rmAll.createRmAll({
      codecs,
      repo
    });
    this.rmAll = rmAll$1;
    this.rm = rm.createRm({ rmAll: rmAll$1 });
    this.ls = ls.createLs({
      codecs,
      repo
    });
    this.remote = {
      add: (cid, options = {}) => Promise.reject(new Error('Not implemented')),
      ls: async function* (query, options = {}) {
        return Promise.reject(new Error('Not implemented'));
      },
      rm: (query, options = {}) => Promise.reject(new Error('Not implemented')),
      rmAll: (query, options = {}) => Promise.reject(new Error('Not implemented')),
      service: {
        add: (name, credentials) => Promise.reject(new Error('Not implemented')),
        rm: (name, options = {}) => Promise.reject(new Error('Not implemented')),
        ls: (options = {}) => Promise.reject(new Error('Not implemented'))
      }
    };
  }
}

exports.PinAPI = PinAPI;
