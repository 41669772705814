'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var abortSignal = require('../lib/abort-signal.js');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var nativeAbortController = require('native-abort-controller');
var cid = require('multiformats/cid');

const createImport = configure.configure(api => {
  async function* dagImport(source, options = {}) {
    const controller = new nativeAbortController.AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const {headers, body} = await multipartRequest.multipartRequest(source, controller, options.headers);
    const res = await api.post('dag/import', {
      signal,
      headers,
      body,
      searchParams: toUrlSearchParams.toUrlSearchParams({ 'pin-roots': options.pinRoots })
    });
    for await (const {Root} of res.ndjson()) {
      if (Root !== undefined) {
        const {
          Cid: {'/': Cid},
          PinErrorMsg
        } = Root;
        yield {
          root: {
            cid: cid.CID.parse(Cid),
            pinErrorMsg: PinErrorMsg
          }
        };
      }
    }
  }
  return dagImport;
});

exports.createImport = createImport;
