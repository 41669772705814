'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var toUrlSearchParams = require('../../../lib/to-url-search-params.js');
var utils = require('./utils.js');

function createAdd(client) {
  async function add(name, options) {
    const {endpoint, key, headers, timeout, signal} = options;
    await client.post('pin/remote/service/add', {
      timeout,
      signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: [
          name,
          utils.encodeEndpoint(endpoint),
          key
        ]
      }),
      headers
    });
  }
  return add;
}

exports.createAdd = createAdd;
