'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var addLink = require('./add-link.js');
var appendData = require('./append-data.js');
var rmLink = require('./rm-link.js');
var setData = require('./set-data.js');

function createPatch(config) {
  return {
    addLink: addLink.createAddLink(config),
    appendData: appendData.createAppendData(config),
    rmLink: rmLink.createRmLink(config),
    setData: setData.createSetData(config)
  };
}

exports.createPatch = createPatch;
