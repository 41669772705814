'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var objectToCamelWithMetadata = require('../lib/object-to-camel-with-metadata.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createLs = configure.configure(api => {
  async function* ls(path, options = {}) {
    if (!path) {
      throw new Error('ipfs.files.ls requires a path');
    }
    const res = await api.post('files/ls', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: cid.CID.asCID(path) ? `/ipfs/${ path }` : path,
        long: true,
        ...options,
        stream: true
      }),
      headers: options.headers
    });
    for await (const result of res.ndjson()) {
      if ('Entries' in result) {
        for (const entry of result.Entries || []) {
          yield toCoreInterface(objectToCamelWithMetadata.objectToCamelWithMetadata(entry));
        }
      } else {
        yield toCoreInterface(objectToCamelWithMetadata.objectToCamelWithMetadata(result));
      }
    }
  }
  return ls;
});
function toCoreInterface(entry) {
  if (entry.hash) {
    entry.cid = cid.CID.parse(entry.hash);
  }
  delete entry.hash;
  entry.type = entry.type === 1 ? 'directory' : 'file';
  return entry;
}

exports.createLs = createLs;
