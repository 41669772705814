'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../../lib/object-to-camel.js');
var configure = require('../../lib/configure.js');
var toUrlSearchParams = require('../../lib/to-url-search-params.js');

const createState = configure.configure(api => {
  async function state(options = {}) {
    const res = await api.post('name/pubsub/state', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    });
    return objectToCamel.objectToCamel(await res.json());
  }
  return state;
});

exports.createState = createState;
