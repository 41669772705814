'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var toCidAndPath = require('ipfs-core-utils/to-cid-and-path');
var utils = require('../../utils.js');

function createResolve({repo, codecs, preload}) {
  async function dagResolve(ipfsPath, options = {}) {
    const {cid} = toCidAndPath.toCidAndPath(ipfsPath);
    if (options.preload !== false) {
      preload(cid);
    }
    return utils.resolvePath(repo, codecs, ipfsPath, options);
  }
  return withTimeoutOption.withTimeoutOption(dagResolve);
}

exports.createResolve = createResolve;
