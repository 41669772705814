'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var data = require('./data.js');
var get = require('./get.js');
var links = require('./links.js');
var _new = require('./new.js');
var put = require('./put.js');
var stat = require('./stat.js');
var index = require('./patch/index.js');

class ObjectAPI {
  constructor({repo, codecs, preload}) {
    this.data = data.createData({
      repo,
      preload
    });
    this.get = get.createGet({
      repo,
      preload
    });
    this.links = links.createLinks({
      repo,
      codecs
    });
    this.new = _new.createNew({
      repo,
      preload
    });
    this.put = put.createPut({
      repo,
      preload
    });
    this.stat = stat.createStat({
      repo,
      preload
    });
    this.patch = new index.ObjectPatchAPI({
      repo,
      preload
    });
  }
}

exports.ObjectAPI = ObjectAPI;
