'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var add = require('./add.js');
var clear = require('./clear.js');
var list = require('./list.js');
var reset = require('./reset.js');
var rm = require('./rm.js');

function createBootstrap(config) {
  return {
    add: add.createAdd(config),
    clear: clear.createClear(config),
    list: list.createList(config),
    reset: reset.createReset(config),
    rm: rm.createRm(config)
  };
}

exports.createBootstrap = createBootstrap;
