'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createPublish = configure.configure(api => {
  async function publish(path, options = {}) {
    const res = await api.post('name/publish', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: `${ path }`,
        ...options
      }),
      headers: options.headers
    });
    return objectToCamel.objectToCamel(await res.json());
  }
  return publish;
});

exports.createPublish = createPublish;
