'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var multiaddr = require('multiaddr');

function createList({repo}) {
  async function list(options = {}) {
    const peers = await repo.config.get('Bootstrap', options);
    return { Peers: (peers || []).map(ma => new multiaddr.Multiaddr(ma)) };
  }
  return withTimeoutOption.withTimeoutOption(list);
}

exports.createList = createList;
