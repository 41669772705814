'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('./lib/configure.js');
var toUrlSearchParams = require('./lib/to-url-search-params.js');

const createGet = configure.configure(api => {
  async function* get(path, options = {}) {
    const opts = {
      arg: `${ path instanceof Uint8Array ? cid.CID.decode(path) : path }`,
      ...options
    };
    if (opts.compressionLevel) {
      opts['compression-level'] = opts.compressionLevel;
      delete opts.compressionLevel;
    }
    const res = await api.post('get', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(opts),
      headers: options.headers
    });
    yield* res.iterator();
  }
  return get;
});

exports.createGet = createGet;
