'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var constants = require('ipfs-repo/constants');

function createVersion({repo}) {
  async function version(options = {}) {
    try {
      await repo._checkInitialized(options);
    } catch (err) {
      const match = [
        /Key not found in database \[\/version\]/,
        /ENOENT/,
        /repo is not initialized yet/
      ].some(m => {
        return m.test(err.message);
      });
      if (match) {
        return constants.repoVersion;
      }
      throw err;
    }
    return repo.version.get();
  }
  return withTimeoutOption.withTimeoutOption(version);
}

exports.createVersion = createVersion;
