'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var publish = require('./publish.js');
var resolve = require('./resolve.js');
var index = require('./pubsub/index.js');

class NameAPI {
  constructor({dns, ipns, repo, codecs, peerId, isOnline, keychain, options}) {
    this.publish = publish.createPublish({
      ipns,
      repo,
      codecs,
      peerId,
      isOnline,
      keychain
    });
    this.resolve = resolve.createResolve({
      dns,
      ipns,
      peerId,
      isOnline,
      options
    });
    this.pubsub = new index.PubSubAPI({
      ipns,
      options
    });
  }
}

exports.NameAPI = NameAPI;
