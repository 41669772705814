'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createTail = configure.configure(api => {
  async function* tail(options = {}) {
    const res = await api.post('log/tail', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    });
    yield* res.ndjson();
  }
  return tail;
});

exports.createTail = createTail;
