'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');

function cleanCid(cid$1) {
  if (cid$1 instanceof Uint8Array) {
    return cid.CID.decode(cid$1);
  }
  return cid.CID.parse(cid$1.toString());
}

exports.cleanCid = cleanCid;
