'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var multiaddr = require('multiaddr');
var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createProvide = configure.configure(api => {
  async function* provide(cids, options = { recursive: false }) {
    const cidArr = Array.isArray(cids) ? cids : [cids];
    const res = await api.post('dht/provide', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: cidArr.map(cid => cid.toString()),
        ...options
      }),
      headers: options.headers
    });
    for await (let message of res.ndjson()) {
      message = objectToCamel.objectToCamel(message);
      if (message.responses) {
        message.responses = message.responses.map(({ID, Addrs}) => ({
          id: ID,
          addrs: (Addrs || []).map(a => new multiaddr.Multiaddr(a))
        }));
      } else {
        message.responses = [];
      }
      yield message;
    }
  }
  return provide;
});

exports.createProvide = createProvide;
