'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const SendingQuery = 0;
const PeerResponse = 1;
const FinalPeer = 2;
const QueryError = 3;
const Provider = 4;
const Value = 5;
const AddingPeer = 6;
const DialingPeer = 7;

exports.AddingPeer = AddingPeer;
exports.DialingPeer = DialingPeer;
exports.FinalPeer = FinalPeer;
exports.PeerResponse = PeerResponse;
exports.Provider = Provider;
exports.QueryError = QueryError;
exports.SendingQuery = SendingQuery;
exports.Value = Value;
