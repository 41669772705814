'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var normaliseContent = require('./normalise-content.js');
var normalise = require('./normalise.js');

function normaliseInput(input) {
  return normalise.normalise(input, normaliseContent.normaliseContent);
}

exports.normaliseInput = normaliseInput;
