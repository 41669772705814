'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var multiaddr = require('multiaddr');
var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var abortSignal = require('../lib/abort-signal.js');
var nativeAbortController = require('native-abort-controller');
var toString = require('uint8arrays/to-string');

const createPut = configure.configure(api => {
  async function* put(key, value, options = {}) {
    const controller = new nativeAbortController.AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const res = await api.post('dht/put', {
      signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: toString.toString(key),
        ...options
      }),
      ...await multipartRequest.multipartRequest(value, controller, options.headers)
    });
    for await (let message of res.ndjson()) {
      message = objectToCamel.objectToCamel(message);
      if (message.responses) {
        message.responses = message.responses.map(({ID, Addrs}) => ({
          id: ID,
          addrs: (Addrs || []).map(a => new multiaddr.Multiaddr(a))
        }));
      }
      yield message;
    }
  }
  return put;
});

exports.createPut = createPut;
