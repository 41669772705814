'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var addrs = require('./addrs.js');
var connect = require('./connect.js');
var disconnect = require('./disconnect.js');
var localAddrs = require('./local-addrs.js');
var peers = require('./peers.js');

class SwarmAPI {
  constructor({network}) {
    this.addrs = addrs.createAddrs({ network });
    this.connect = connect.createConnect({ network });
    this.disconnect = disconnect.createDisconnect({ network });
    this.localAddrs = localAddrs.createLocalAddrs({ network });
    this.peers = peers.createPeers({ network });
  }
}

exports.SwarmAPI = SwarmAPI;
