'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var errors = require('../errors.js');
var utils = require('../utils.js');

class Service {
  static create({start, stop}) {
    return new Service(start, stop);
  }
  static async start(service, options) {
    const {state, activate} = service;
    switch (state.status) {
    case 'stopped': {
        try {
          const promise = activate(options);
          service.state = {
            status: 'starting',
            ready: promise
          };
          const result = await promise;
          service.state = {
            status: 'started',
            value: result
          };
          return result;
        } catch (error) {
          service.state = { status: 'stopped' };
          throw error;
        }
      }
    case 'starting': {
        throw new errors.AlreadyStartingError();
      }
    case 'started': {
        throw new errors.AlreadyStartedError();
      }
    case 'stopping': {
        await state.ready;
        return await Service.start(service, options);
      }
    default: {
        return Service.panic(service);
      }
    }
  }
  static async stop(service) {
    const {state, deactivate} = service;
    switch (state.status) {
    case 'stopped': {
        break;
      }
    case 'starting': {
        try {
          await state.ready;
        } catch (_) {
        }
        return await Service.stop(service);
      }
    case 'stopping': {
        return await state.ready;
      }
    case 'started': {
        if (deactivate) {
          await deactivate(state.value);
        }
        service.state = { status: 'stopped' };
        break;
      }
    default: {
        Service.panic(state);
      }
    }
  }
  static try({state}) {
    switch (state.status) {
    case 'started':
      return state.value;
    default:
      return null;
    }
  }
  static async use({state}, options) {
    switch (state.status) {
    case 'started':
      return state.value;
    case 'starting':
      return await utils.withTimeout(state.ready, options);
    default:
      throw new errors.NotStartedError();
    }
  }
  static panic({state}) {
    const status = JSON.stringify({ status: state.status });
    throw RangeError(`Service in invalid state ${ status }, should never happen if you see this please report a bug`);
  }
  constructor(activate, deactivate) {
    this.activate = activate;
    this.deactivate = deactivate;
    this.state = { status: 'stopped' };
  }
  async use(options) {
    return await Service.use(this, options);
  }
  try() {
    return Service.try(this);
  }
}

exports.Service = Service;
