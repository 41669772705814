'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cmds = require('./cmds.js');
var net = require('./net.js');
var sys = require('./sys.js');

function createDiag(config) {
  return {
    cmds: cmds.createCmds(config),
    net: net.createNet(config),
    sys: sys.createSys(config)
  };
}

exports.createDiag = createDiag;
