'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var local = require('./local.js');

const createRefs = configure.configure((api, opts) => {
  const refs = async function* (args, options = {}) {
    const argsArr = Array.isArray(args) ? args : [args];
    const res = await api.post('refs', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: argsArr.map(arg => `${ arg instanceof Uint8Array ? cid.CID.decode(arg) : arg }`),
        ...options
      }),
      headers: options.headers,
      transform: objectToCamel.objectToCamel
    });
    yield* res.ndjson();
  };
  return Object.assign(refs, { local: local.createLocal(opts) });
});

exports.createRefs = createRefs;
