'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var multiaddr = require('multiaddr');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var responseTypes = require('./response-types.js');

const createFindPeer = configure.configure(api => {
  async function findPeer(peerId, options = {}) {
    const res = await api.post('dht/findpeer', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: peerId,
        ...options
      }),
      headers: options.headers
    });
    for await (const data of res.ndjson()) {
      if (data.Type === responseTypes.FinalPeer && data.Responses) {
        const {ID, Addrs} = data.Responses[0];
        return {
          id: ID,
          addrs: (Addrs || []).map(a => new multiaddr.Multiaddr(a))
        };
      }
    }
    throw new Error('not found');
  }
  return findPeer;
});

exports.createFindPeer = createFindPeer;
