'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var events = require('events');
var toString = require('uint8arrays/to-string');
var constants = require('./constants.js');
var index = require('./utils/index.js');

const unwantEvent = cid => `unwant:${ toString.toString(cid.multihash.bytes, 'base64') }`;
const blockEvent = cid => `block:${ toString.toString(cid.multihash.bytes, 'base64') }`;
class Notifications extends events.EventEmitter {
  constructor(peerId) {
    super();
    this.setMaxListeners(constants.maxListeners);
    this._log = index.logger(peerId, 'notif');
  }
  hasBlock(cid, block) {
    const event = blockEvent(cid);
    this._log(event);
    this.emit(event, block);
  }
  wantBlock(cid, options = {}) {
    if (!cid) {
      throw new Error('Not a valid cid');
    }
    const blockEvt = blockEvent(cid);
    const unwantEvt = unwantEvent(cid);
    this._log(`wantBlock:${ cid }`);
    return new Promise((resolve, reject) => {
      const onUnwant = () => {
        this.removeListener(blockEvt, onBlock);
        reject(new Error(`Block for ${ cid } unwanted`));
      };
      const onBlock = data => {
        this.removeListener(unwantEvt, onUnwant);
        resolve(data);
      };
      this.once(unwantEvt, onUnwant);
      this.once(blockEvt, onBlock);
      if (options && options.signal) {
        options.signal.addEventListener('abort', () => {
          this.removeListener(blockEvt, onBlock);
          this.removeListener(unwantEvt, onUnwant);
          reject(new Error(`Want for ${ cid } aborted`));
        });
      }
    });
  }
  unwantBlock(cid) {
    const event = unwantEvent(cid);
    this._log(event);
    this.emit(event);
  }
}

exports.Notifications = Notifications;
