'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cancel = require('./cancel.js');
var state = require('./state.js');
var subs = require('./subs.js');

function createPubsub(config) {
  return {
    cancel: cancel.createCancel(config),
    state: state.createState(config),
    subs: subs.createSubs(config)
  };
}

exports.createPubsub = createPubsub;
