'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var toUrlSearchParams = require('../../../lib/to-url-search-params.js');

function createRm(client) {
  async function rm(name, options = {}) {
    await client.post('pin/remote/service/rm', {
      signal: options.signal,
      headers: options.headers,
      searchParams: toUrlSearchParams.toUrlSearchParams({ arg: name })
    });
  }
  return rm;
}

exports.createRm = createRm;
