'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var get = require('./get.js');
var put = require('./put.js');
var rm = require('./rm.js');
var stat = require('./stat.js');

function createBlock(config) {
  return {
    get: get.createGet(config),
    put: put.createPut(config),
    rm: rm.createRm(config),
    stat: stat.createStat(config)
  };
}

exports.createBlock = createBlock;
