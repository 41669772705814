'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var base58 = require('multiformats/bases/base58');
var index = require('../message/index.js');
var index$2 = require('../wantlist/index.js');
var ledger = require('./ledger.js');
var reqQueue = require('./req-queue.js');
var taskMerger = require('./task-merger.js');
var index$1 = require('../utils/index.js');

const WantType = index.BitswapMessage.WantType;
const TARGET_MESSAGE_SIZE = 16 * 1024;
const MAX_SIZE_REPLACE_HAS_WITH_BLOCK = 1024;
class DecisionEngine {
  constructor(peerId, blockstore, network, stats, opts = {}) {
    this._log = index$1.logger(peerId, 'engine');
    this.blockstore = blockstore;
    this.network = network;
    this._stats = stats;
    this._opts = this._processOpts(opts);
    this.ledgerMap = new Map();
    this._running = false;
    this._requestQueue = new reqQueue.RequestQueue(taskMerger.TaskMerger);
  }
  _processOpts(opts) {
    return {
      maxSizeReplaceHasWithBlock: MAX_SIZE_REPLACE_HAS_WITH_BLOCK,
      targetMessageSize: TARGET_MESSAGE_SIZE,
      ...opts
    };
  }
  _scheduleProcessTasks() {
    setTimeout(() => {
      this._processTasks();
    });
  }
  async _processTasks() {
    if (!this._running) {
      return;
    }
    const {peerId, tasks, pendingSize} = this._requestQueue.popTasks(this._opts.targetMessageSize);
    if (tasks.length === 0) {
      return;
    }
    const msg = new index.BitswapMessage(false);
    msg.setPendingBytes(pendingSize);
    const blockCids = [];
    const blockTasks = new Map();
    for (const task of tasks) {
      const cid$1 = cid.CID.parse(task.topic);
      if (task.data.haveBlock) {
        if (task.data.isWantBlock) {
          blockCids.push(cid$1);
          blockTasks.set(task.topic, task.data);
        } else {
          msg.addHave(cid$1);
        }
      } else {
        msg.addDontHave(cid$1);
      }
    }
    const blocks = await this._getBlocks(blockCids);
    for (const [topic, taskData] of blockTasks) {
      const cid$1 = cid.CID.parse(topic);
      const blk = blocks.get(topic);
      if (blk) {
        msg.addBlock(cid$1, blk);
      } else {
        if (taskData.sendDontHave) {
          msg.addDontHave(cid$1);
        }
      }
    }
    if (msg.empty) {
      peerId && this._requestQueue.tasksDone(peerId, tasks);
      this._scheduleProcessTasks();
      return;
    }
    try {
      peerId && await this.network.sendMessage(peerId, msg);
      for (const [cidStr, block] of blocks.entries()) {
        peerId && this.messageSent(peerId, cid.CID.parse(cidStr), block);
      }
    } catch (err) {
      this._log.error(err);
    }
    peerId && this._requestQueue.tasksDone(peerId, tasks);
    this._scheduleProcessTasks();
  }
  wantlistForPeer(peerId) {
    const peerIdStr = peerId.toB58String();
    const ledger = this.ledgerMap.get(peerIdStr);
    return ledger ? ledger.wantlist.sortedEntries() : new Map();
  }
  ledgerForPeer(peerId) {
    const peerIdStr = peerId.toB58String();
    const ledger = this.ledgerMap.get(peerIdStr);
    if (!ledger) {
      return null;
    }
    return {
      peer: ledger.partner,
      value: ledger.debtRatio(),
      sent: ledger.accounting.bytesSent,
      recv: ledger.accounting.bytesRecv,
      exchanged: ledger.exchangeCount
    };
  }
  peers() {
    return Array.from(this.ledgerMap.values()).map(l => l.partner);
  }
  receivedBlocks(blocks) {
    if (!blocks.length) {
      return;
    }
    for (const ledger of this.ledgerMap.values()) {
      for (const block of blocks) {
        const want = ledger.wantlistContains(block.cid);
        if (!want) {
          continue;
        }
        const blockSize = block.data.length;
        const isWantBlock = this._sendAsBlock(want.wantType, blockSize);
        let entrySize = blockSize;
        if (!isWantBlock) {
          entrySize = index.BitswapMessage.blockPresenceSize(want.cid);
        }
        this._requestQueue.pushTasks(ledger.partner, [{
            topic: want.cid.toString(base58.base58btc),
            priority: want.priority,
            size: entrySize,
            data: {
              blockSize,
              isWantBlock,
              haveBlock: true,
              sendDontHave: false
            }
          }]);
      }
    }
    this._scheduleProcessTasks();
  }
  async messageReceived(peerId, msg) {
    const ledger = this._findOrCreate(peerId);
    if (msg.empty) {
      return;
    }
    if (msg.full) {
      ledger.wantlist = new index$2.Wantlist();
    }
    this._updateBlockAccounting(msg.blocks, ledger);
    if (msg.wantlist.size === 0) {
      this._scheduleProcessTasks();
      return;
    }
    const cancels = [];
    const wants = [];
    msg.wantlist.forEach(entry => {
      if (entry.cancel) {
        ledger.cancelWant(entry.cid);
        cancels.push(entry.cid);
      } else {
        ledger.wants(entry.cid, entry.priority, entry.wantType);
        wants.push(entry);
      }
    });
    this._cancelWants(peerId, cancels);
    await this._addWants(peerId, wants);
    this._scheduleProcessTasks();
  }
  _cancelWants(peerId, cids) {
    for (const c of cids) {
      this._requestQueue.remove(c.toString(base58.base58btc), peerId);
    }
  }
  async _addWants(peerId, wants) {
    const blockSizes = await this._getBlockSizes(wants.map(w => w.cid));
    const tasks = [];
    for (const want of wants) {
      const id = want.cid.toString(base58.base58btc);
      const blockSize = blockSizes.get(id);
      if (blockSize == null) {
        if (want.sendDontHave) {
          tasks.push({
            topic: id,
            priority: want.priority,
            size: index.BitswapMessage.blockPresenceSize(want.cid),
            data: {
              isWantBlock: want.wantType === WantType.Block,
              blockSize: 0,
              haveBlock: false,
              sendDontHave: want.sendDontHave
            }
          });
        }
      } else {
        const isWantBlock = this._sendAsBlock(want.wantType, blockSize);
        let entrySize = blockSize;
        if (!isWantBlock) {
          entrySize = index.BitswapMessage.blockPresenceSize(want.cid);
        }
        tasks.push({
          topic: id,
          priority: want.priority,
          size: entrySize,
          data: {
            isWantBlock,
            blockSize,
            haveBlock: true,
            sendDontHave: want.sendDontHave
          }
        });
      }
      this._requestQueue.pushTasks(peerId, tasks);
    }
  }
  _sendAsBlock(wantType, blockSize) {
    return wantType === WantType.Block || blockSize <= this._opts.maxSizeReplaceHasWithBlock;
  }
  async _getBlockSizes(cids) {
    const blocks = await this._getBlocks(cids);
    return new Map([...blocks].map(([k, v]) => [
      k,
      v.length
    ]));
  }
  async _getBlocks(cids) {
    const res = new Map();
    await Promise.all(cids.map(async cid => {
      try {
        const block = await this.blockstore.get(cid);
        res.set(cid.toString(base58.base58btc), block);
      } catch (e) {
        if (e.code !== 'ERR_NOT_FOUND') {
          this._log.error('failed to query blockstore for %s: %s', cid, e);
        }
      }
    }));
    return res;
  }
  _updateBlockAccounting(blocksMap, ledger) {
    for (const block of blocksMap.values()) {
      this._log('got block (%s bytes)', block.length);
      ledger.receivedBytes(block.length);
    }
  }
  messageSent(peerId, cid, block) {
    const ledger = this._findOrCreate(peerId);
    ledger.sentBytes(block.length);
    ledger.wantlist.remove(cid);
  }
  numBytesSentTo(peerId) {
    return this._findOrCreate(peerId).accounting.bytesSent;
  }
  numBytesReceivedFrom(peerId) {
    return this._findOrCreate(peerId).accounting.bytesRecv;
  }
  peerDisconnected(_peerId) {
  }
  _findOrCreate(peerId) {
    const peerIdStr = peerId.toB58String();
    const ledger$1 = this.ledgerMap.get(peerIdStr);
    if (ledger$1) {
      return ledger$1;
    }
    const l = new ledger.Ledger(peerId);
    this.ledgerMap.set(peerIdStr, l);
    if (this._stats) {
      this._stats.push(peerIdStr, 'peerCount', 1);
    }
    return l;
  }
  start() {
    this._running = true;
  }
  stop() {
    this._running = false;
  }
}

exports.DecisionEngine = DecisionEngine;
