'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var multiaddr = require('multiaddr');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createPeers = configure.configure(api => {
  async function peers(options = {}) {
    const res = await api.post('swarm/peers', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    });
    const {Peers} = await res.json();
    return (Peers || []).map(peer => {
      return {
        addr: new multiaddr.Multiaddr(peer.Addr),
        peer: peer.Peer,
        muxer: peer.Muxer,
        latency: peer.Latency,
        streams: peer.Streams,
        direction: peer.Direction == null ? undefined : peer.Direction === 0 ? 'inbound' : 'outbound'
      };
    });
  }
  return peers;
});

exports.createPeers = createPeers;
