'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createGet({preload, repo}) {
  async function get(cid, options = {}) {
    if (options.preload !== false) {
      preload(cid);
    }
    return repo.blocks.get(cid, options);
  }
  return withTimeoutOption.withTimeoutOption(get);
}

exports.createGet = createGet;
