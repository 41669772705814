'use strict';

var memory = require('./locks/memory.js');

var defaultOptions = {
  autoMigrate: true,
  onMigrationProgress: () => {
  },
  repoOwner: true,
  repoLock: memory.MemoryLock
};

module.exports = defaultOptions;
