'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createPeers({network}) {
  async function peers(options = {}) {
    const {libp2p} = await network.use(options);
    const peers = [];
    for (const [peerId, connections] of libp2p.connections) {
      for (const connection of connections) {
        const peer = {
          addr: connection.remoteAddr,
          peer: peerId
        };
        if (options.verbose || options.direction) {
          peer.direction = connection.stat.direction;
        }
        if (options.verbose) {
          peer.muxer = connection.stat.multiplexer;
          peer.latency = 'n/a';
          peer.streams = [];
        }
        peers.push(peer);
      }
    }
    return peers;
  }
  return withTimeoutOption.withTimeoutOption(peers);
}

exports.createPeers = createPeers;
