'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../../lib/configure.js');
var toUrlSearchParams = require('../../lib/to-url-search-params.js');

const createRmLink = configure.configure(api => {
  async function rmLink(cid$1, dLink, options = {}) {
    const res = await api.post('object/patch/rm-link', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: [
          `${ cid$1 }`,
          dLink.Name || dLink.name || null
        ],
        ...options
      }),
      headers: options.headers
    });
    const {Hash} = await res.json();
    return cid.CID.parse(Hash);
  }
  return rmLink;
});

exports.createRmLink = createRmLink;
