'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var get = require('./get.js');

function createData({repo, preload}) {
  const get$1 = get.createGet({
    repo,
    preload
  });
  async function data(multihash, options = {}) {
    const node = await get$1(multihash, options);
    return node.Data || new Uint8Array(0);
  }
  return withTimeoutOption.withTimeoutOption(data);
}

exports.createData = createData;
