'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var normaliseInput = require('ipfs-core-utils/pins/normalise-input');
var utils = require('../../utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var pinTypes = require('ipfs-repo/pin-types');

function createRmAll({repo, codecs}) {
  async function* rmAll(source, _options = {}) {
    const release = await repo.gcLock.readLock();
    try {
      for await (const {path, recursive} of normaliseInput.normaliseInput(source)) {
        const {cid} = await utils.resolvePath(repo, codecs, path);
        const {pinned, reason} = await repo.pins.isPinnedWithType(cid, pinTypes.PinTypes.all);
        if (!pinned) {
          throw new Error(`${ cid } is not pinned`);
        }
        switch (reason) {
        case pinTypes.PinTypes.recursive:
          if (!recursive) {
            throw new Error(`${ cid } is pinned recursively`);
          }
          await repo.pins.unpin(cid);
          yield cid;
          break;
        case pinTypes.PinTypes.direct:
          await repo.pins.unpin(cid);
          yield cid;
          break;
        default:
          throw new Error(`${ cid } is pinned indirectly under ${ reason }`);
        }
      }
    } finally {
      release();
    }
  }
  return withTimeoutOption.withTimeoutOption(rmAll);
}

exports.createRmAll = createRmAll;
