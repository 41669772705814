'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const PinTypes = {
  direct: 'direct',
  recursive: 'recursive',
  indirect: 'indirect',
  all: 'all'
};

exports.PinTypes = PinTypes;
