'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _export = require('./export.js');
var get = require('./get.js');
var _import = require('./import.js');
var put = require('./put.js');
var resolve = require('./resolve.js');

class DagAPI {
  constructor({repo, codecs, hashers, preload}) {
    this.export = _export.createExport({
      repo,
      preload,
      codecs
    });
    this.get = get.createGet({
      codecs,
      repo,
      preload
    });
    this.import = _import.createImport({ repo });
    this.resolve = resolve.createResolve({
      repo,
      codecs,
      preload
    });
    this.put = put.createPut({
      repo,
      codecs,
      hashers,
      preload
    });
  }
}

exports.DagAPI = DagAPI;
