'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var service = require('../utils/service.js');

function createStart({network, preload, peerId, keychain, repo, ipns, mfsPreload, print, options}) {
  const start = async () => {
    const {libp2p} = await service.Service.start(network, {
      peerId,
      repo,
      print,
      options
    });
    await Promise.all([
      ipns.startOnline({
        keychain,
        libp2p,
        peerId,
        repo
      }),
      preload.start(),
      mfsPreload.start()
    ]);
  };
  return start;
}

exports.createStart = createStart;
