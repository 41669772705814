'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var sortedMap = require('../utils/sorted-map.js');

const DefaultTaskMerger = {
  hasNewInfo() {
    return false;
  },
  merge() {
  }
};
class RequestQueue {
  constructor(taskMerger = DefaultTaskMerger) {
    this._taskMerger = taskMerger;
    this._byPeer = new sortedMap.SortedMap([], PeerTasks.compare);
  }
  pushTasks(peerId, tasks) {
    let peerTasks = this._byPeer.get(peerId.toB58String());
    if (!peerTasks) {
      peerTasks = new PeerTasks(peerId, this._taskMerger);
    }
    peerTasks.pushTasks(tasks);
    this._byPeer.set(peerId.toB58String(), peerTasks);
  }
  popTasks(targetMinBytes) {
    const peerTasks = this._head();
    if (peerTasks === undefined) {
      return {
        tasks: [],
        pendingSize: 0
      };
    }
    const {tasks, pendingSize} = peerTasks.popTasks(targetMinBytes);
    if (tasks.length === 0) {
      return {
        tasks,
        pendingSize
      };
    }
    const peerId = peerTasks.peerId;
    if (peerTasks.isIdle()) {
      this._byPeer.delete(peerId.toB58String());
    } else {
      this._byPeer.update(0);
    }
    return {
      peerId,
      tasks,
      pendingSize
    };
  }
  _head() {
    if (this._byPeer.size === 0) {
      return undefined;
    }
    for (const [, v] of this._byPeer) {
      return v;
    }
    return undefined;
  }
  remove(topic, peerId) {
    const peerTasks = this._byPeer.get(peerId.toB58String());
    peerTasks && peerTasks.remove(topic);
  }
  tasksDone(peerId, tasks) {
    const peerTasks = this._byPeer.get(peerId.toB58String());
    if (!peerTasks) {
      return;
    }
    const i = this._byPeer.indexOf(peerId.toB58String());
    for (const task of tasks) {
      peerTasks.taskDone(task);
    }
    this._byPeer.update(i);
  }
}
class PeerTasks {
  constructor(peerId, taskMerger) {
    this.peerId = peerId;
    this._taskMerger = taskMerger;
    this._activeTotalSize = 0;
    this._pending = new PendingTasks();
    this._active = new Set();
  }
  pushTasks(tasks) {
    for (const t of tasks) {
      this._pushTask(t);
    }
  }
  _pushTask(task) {
    if (!this._taskHasMoreInfoThanActiveTasks(task)) {
      return;
    }
    const existingTask = this._pending.get(task.topic);
    if (existingTask) {
      if (task.priority > existingTask.priority) {
        this._pending.updatePriority(task.topic, task.priority);
      }
      this._taskMerger.merge(task, existingTask);
      return;
    }
    this._pending.add(task);
  }
  _taskHasMoreInfoThanActiveTasks(task) {
    const tasksWithTopic = [];
    for (const activeTask of this._active) {
      if (activeTask.topic === task.topic) {
        tasksWithTopic.push(activeTask);
      }
    }
    if (tasksWithTopic.length === 0) {
      return true;
    }
    return this._taskMerger.hasNewInfo(task, tasksWithTopic);
  }
  popTasks(targetMinBytes) {
    let size = 0;
    const tasks = [];
    const pendingTasks = this._pending.tasks();
    for (let i = 0; i < pendingTasks.length && size < targetMinBytes; i++) {
      const task = pendingTasks[i];
      tasks.push(task);
      size += task.size;
      this._pending.delete(task.topic);
      this._activeTotalSize += task.size;
      this._active.add(task);
    }
    return {
      tasks,
      pendingSize: this._pending.totalSize
    };
  }
  taskDone(task) {
    if (this._active.has(task)) {
      this._activeTotalSize -= task.size;
      this._active.delete(task);
    }
  }
  remove(topic) {
    this._pending.delete(topic);
  }
  isIdle() {
    return this._pending.length === 0 && this._active.size === 0;
  }
  static compare(a, b) {
    if (a[1]._pending.length === 0) {
      return 1;
    }
    if (b[1]._pending.length === 0) {
      return -1;
    }
    if (a[1]._activeTotalSize === b[1]._activeTotalSize) {
      return b[1]._pending.length - a[1]._pending.length;
    }
    return a[1]._activeTotalSize - b[1]._activeTotalSize;
  }
}
class PendingTasks {
  constructor() {
    this._tasks = new sortedMap.SortedMap([], this._compare);
  }
  get length() {
    return this._tasks.size;
  }
  get totalSize() {
    return [...this._tasks.values()].reduce((a, t) => a + t.task.size, 0);
  }
  get(topic) {
    return (this._tasks.get(topic) || {}).task;
  }
  add(task) {
    this._tasks.set(task.topic, {
      created: Date.now(),
      task
    });
  }
  delete(topic) {
    this._tasks.delete(topic);
  }
  tasks() {
    return [...this._tasks.values()].map(i => i.task);
  }
  updatePriority(topic, priority) {
    const obj = this._tasks.get(topic);
    if (!obj) {
      return;
    }
    const i = this._tasks.indexOf(topic);
    obj.task.priority = priority;
    this._tasks.update(i);
  }
  _compare(a, b) {
    if (a[1].task.priority === b[1].task.priority) {
      return a[1].created - b[1].created;
    }
    return b[1].task.priority - a[1].task.priority;
  }
}

exports.RequestQueue = RequestQueue;
