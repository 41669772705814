'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _export = require('./export.js');
var gen = require('./gen.js');
var _import = require('./import.js');
var info = require('./info.js');
var list = require('./list.js');
var rename = require('./rename.js');
var rm = require('./rm.js');

function createKey(config) {
  return {
    export: _export.createExport(config),
    gen: gen.createGen(config),
    import: _import.createImport(config),
    info: info.createInfo(config),
    list: list.createList(config),
    rename: rename.createRename(config),
    rm: rm.createRm(config)
  };
}

exports.createKey = createKey;
