'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index$2 = require('../message/index.js');
var index = require('../wantlist/index.js');
var constants = require('../constants.js');
var msgQueue = require('./msg-queue.js');
var index$1 = require('../utils/index.js');
var base58 = require('multiformats/bases/base58');

class WantManager {
  constructor(peerId, network, stats) {
    this.peers = new Map();
    this.wantlist = new index.Wantlist(stats);
    this.network = network;
    this._stats = stats;
    this._peerId = peerId;
    this._log = index$1.logger(peerId, 'want');
  }
  _addEntries(cids, cancel, force) {
    const entries = cids.map((cid, i) => {
      return new index$2.BitswapMessage.Entry(cid, constants.kMaxPriority - i, index$2.BitswapMessage.WantType.Block, cancel);
    });
    entries.forEach(e => {
      if (e.cancel) {
        if (force) {
          this.wantlist.removeForce(e.cid.toString(base58.base58btc));
        } else {
          this.wantlist.remove(e.cid);
        }
      } else {
        this._log('adding to wl');
        this.wantlist.add(e.cid, e.priority);
      }
    });
    for (const p of this.peers.values()) {
      p.addEntries(entries);
    }
  }
  _startPeerHandler(peerId) {
    let mq = this.peers.get(peerId.toB58String());
    if (mq) {
      mq.refcnt++;
      return;
    }
    mq = new msgQueue.MsgQueue(this._peerId, peerId, this.network);
    const fullwantlist = new index$2.BitswapMessage(true);
    for (const entry of this.wantlist.entries()) {
      fullwantlist.addEntry(entry[1].cid, entry[1].priority);
    }
    mq.addMessage(fullwantlist);
    this.peers.set(peerId.toB58String(), mq);
    return mq;
  }
  _stopPeerHandler(peerId) {
    const mq = this.peers.get(peerId.toB58String());
    if (!mq) {
      return;
    }
    mq.refcnt--;
    if (mq.refcnt > 0) {
      return;
    }
    this.peers.delete(peerId.toB58String());
  }
  wantBlocks(cids, options = {}) {
    this._addEntries(cids, false);
    if (options && options.signal) {
      options.signal.addEventListener('abort', () => {
        this.cancelWants(cids);
      });
    }
  }
  unwantBlocks(cids) {
    this._log('unwant blocks: %s', cids.length);
    this._addEntries(cids, true, true);
  }
  cancelWants(cids) {
    this._log('cancel wants: %s', cids.length);
    this._addEntries(cids, true);
  }
  connectedPeers() {
    return Array.from(this.peers.keys());
  }
  connected(peerId) {
    this._startPeerHandler(peerId);
  }
  disconnected(peerId) {
    this._stopPeerHandler(peerId);
  }
  start() {
  }
  stop() {
    this.peers.forEach(mq => this.disconnected(mq.peerId));
  }
}

exports.WantManager = WantManager;
