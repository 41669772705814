'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var multiaddr = require('multiaddr');
var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createQuery = configure.configure(api => {
  async function* query(peerId, options = {}) {
    const res = await api.post('dht/query', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: peerId.toString(),
        ...options
      }),
      headers: options.headers
    });
    for await (let message of res.ndjson()) {
      message = objectToCamel.objectToCamel(message);
      message.responses = (message.responses || []).map(({ID, Addrs}) => ({
        id: ID,
        addrs: (Addrs || []).map(a => new multiaddr.Multiaddr(a))
      }));
      yield message;
    }
  }
  return query;
});

exports.createQuery = createQuery;
