'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createLinks = configure.configure(api => {
  async function links(cid$1, options = {}) {
    const res = await api.post('object/links', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: `${ cid$1 instanceof Uint8Array ? cid.CID.decode(cid$1) : cid$1 }`,
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    return (data.Links || []).map(l => ({
      Name: l.Name,
      Tsize: l.Size,
      Hash: cid.CID.parse(l.Hash)
    }));
  }
  return links;
});

exports.createLinks = createLinks;
