'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var bw = require('./bw.js');
var stat = require('../repo/stat.js');
var stat$1 = require('../bitswap/stat.js');

class StatsAPI {
  constructor({repo, network}) {
    this.repo = stat.createStat({ repo });
    this.bw = bw.createBw({ network });
    this.bitswap = stat$1.createStat({ network });
  }
}

exports.StatsAPI = StatsAPI;
