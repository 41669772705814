'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var add = require('./add.js');
var clear = require('./clear.js');
var list = require('./list.js');
var reset = require('./reset.js');
var rm = require('./rm.js');

class BootstrapAPI {
  constructor({repo}) {
    this.add = add.createAdd({ repo });
    this.list = list.createList({ repo });
    this.rm = rm.createRm({ repo });
    this.clear = clear.createClear({ repo });
    this.reset = reset.createReset({ repo });
  }
}

exports.BootstrapAPI = BootstrapAPI;
