'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createRename({keychain}) {
  const rename = async (oldName, newName) => {
    const key = await keychain.renameKey(oldName, newName);
    return {
      was: oldName,
      now: key.name,
      id: key.id,
      overwrite: false
    };
  };
  return withTimeoutOption.withTimeoutOption(rename);
}

exports.createRename = createRename;
