'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var responseTypes = require('./response-types.js');
var fromString = require('uint8arrays/from-string');
var toString = require('uint8arrays/to-string');

const createGet = configure.configure(api => {
  async function get(key, options = {}) {
    const res = await api.post('dht/get', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: key instanceof Uint8Array ? toString.toString(key) : key,
        ...options
      }),
      headers: options.headers
    });
    for await (const message of res.ndjson()) {
      if (message.Type === responseTypes.Value) {
        return fromString.fromString(message.Extra, 'base64pad');
      }
    }
    throw new Error('not found');
  }
  return get;
});

exports.createGet = createGet;
