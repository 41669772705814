'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var init = require('./init.js');
var errors = require('../errors.js');
var utils = require('../utils.js');
var fromString = require('uint8arrays/from-string');
var toString = require('uint8arrays/to-string');

async function getVersion(backends) {
  if (!await init.isRepoInitialized(backends)) {
    throw new errors.NotInitializedRepoError('Repo is not initialized!');
  }
  const store = backends.root;
  await store.open();
  try {
    return parseInt(toString.toString(await store.get(utils.VERSION_KEY)));
  } finally {
    await store.close();
  }
}
async function setVersion(version, backends) {
  if (!backends) {
    throw new errors.MissingRepoOptionsError('Please pass repo options when trying to open a repo');
  }
  const store = backends.root;
  await store.open();
  await store.put(utils.VERSION_KEY, fromString.fromString(String(version)));
  await store.close();
}

exports.getVersion = getVersion;
exports.setVersion = setVersion;
