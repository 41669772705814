'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createImport({keychain}) {
  const importKey = (name, pem, password) => {
    return keychain.importKey(name, pem, password);
  };
  return withTimeoutOption.withTimeoutOption(importKey);
}

exports.createImport = createImport;
