'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');

function createAdd(client) {
  async function add(cid, {timeout, signal, headers, ...query}) {
    const response = await client.post('pin/remote/add', {
      timeout,
      signal,
      headers,
      searchParams: utils.encodeAddParams({
        cid,
        ...query
      })
    });
    return utils.decodePin(await response.json());
  }
  return add;
}

exports.createAdd = createAdd;
