'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('./lib/object-to-camel.js');
var multiaddr = require('multiaddr');
var configure = require('./lib/configure.js');
var toUrlSearchParams = require('./lib/to-url-search-params.js');

const createId = configure.configure(api => {
  async function id(options = {}) {
    const res = await api.post('id', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: options.peerId ? options.peerId.toString() : undefined,
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    const output = { ...objectToCamel.objectToCamel(data) };
    if (output.addresses) {
      output.addresses = output.addresses.map(ma => new multiaddr.Multiaddr(ma));
    }
    return output;
  }
  return id;
});

exports.createId = createId;
