'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

class LockExistsError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LockExistsError';
    this.code = LockExistsError.code;
  }
}
LockExistsError.code = 'ERR_LOCK_EXISTS';
class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotFoundError';
    this.code = NotFoundError.code;
  }
}
NotFoundError.code = 'ERR_NOT_FOUND';
class InvalidRepoVersionError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidRepoVersionError';
    this.code = InvalidRepoVersionError.code;
  }
}
InvalidRepoVersionError.code = 'ERR_INVALID_REPO_VERSION';
const ERR_REPO_NOT_INITIALIZED = 'ERR_REPO_NOT_INITIALIZED';
const ERR_REPO_ALREADY_OPEN = 'ERR_REPO_ALREADY_OPEN';
const ERR_REPO_ALREADY_CLOSED = 'ERR_REPO_ALREADY_CLOSED';

exports.ERR_REPO_ALREADY_CLOSED = ERR_REPO_ALREADY_CLOSED;
exports.ERR_REPO_ALREADY_OPEN = ERR_REPO_ALREADY_OPEN;
exports.ERR_REPO_NOT_INITIALIZED = ERR_REPO_NOT_INITIALIZED;
exports.InvalidRepoVersionError = InvalidRepoVersionError;
exports.LockExistsError = LockExistsError;
exports.NotFoundError = NotFoundError;
