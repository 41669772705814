'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createDisconnect({network}) {
  async function disconnect(addr, options = {}) {
    const {libp2p} = await network.use(options);
    await libp2p.hangUp(addr);
  }
  return withTimeoutOption.withTimeoutOption(disconnect);
}

exports.createDisconnect = createDisconnect;
