'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var ls = require('./ls.js');
var peers = require('./peers.js');
var publish = require('./publish.js');
var subscribe = require('./subscribe.js');
var unsubscribe = require('./unsubscribe.js');
var subscriptionTracker = require('./subscription-tracker.js');

function createPubsub(config) {
  const subscriptionTracker$1 = new subscriptionTracker.SubscriptionTracker();
  return {
    ls: ls.createLs(config),
    peers: peers.createPeers(config),
    publish: publish.createPublish(config),
    subscribe: subscribe.createSubscribe(config, subscriptionTracker$1),
    unsubscribe: unsubscribe.createUnsubscribe(config, subscriptionTracker$1)
  };
}

exports.createPubsub = createPubsub;
