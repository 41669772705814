'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createRm = configure.configure(api => {
  async function rm(name, options = {}) {
    const res = await api.post('key/rm', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: name,
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    return objectToCamel.objectToCamel(data.Keys[0]);
  }
  return rm;
});

exports.createRm = createRm;
