'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createExport({keychain}) {
  const exportKey = (name, password) => keychain.exportKey(name, password);
  return withTimeoutOption.withTimeoutOption(exportKey);
}

exports.createExport = createExport;
