'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

const DEFAULT_KEY_TYPE = 'ed25519';
const DEFAULT_KEY_SIZE = 2048;
function createGen({keychain}) {
  const gen = (name, options = {
    type: DEFAULT_KEY_TYPE,
    size: DEFAULT_KEY_SIZE
  }) => {
    return keychain.createKey(name, options.type || DEFAULT_KEY_TYPE, options.size || DEFAULT_KEY_SIZE);
  };
  return withTimeoutOption.withTimeoutOption(gen);
}

exports.createGen = createGen;
