'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var normaliseContent_browser = require('./normalise-content.browser.js');
var normalise = require('./normalise.js');

function normaliseInput(input) {
  return normalise.normalise(input, normaliseContent_browser.normaliseContent);
}

exports.normaliseInput = normaliseInput;
