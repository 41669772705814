'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var gc = require('./gc.js');
var stat = require('./stat.js');
var version = require('./version.js');

function createRepo(config) {
  return {
    gc: gc.createGc(config),
    stat: stat.createStat(config),
    version: version.createVersion(config)
  };
}

exports.createRepo = createRepo;
