'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var data = require('./data.js');
var get = require('./get.js');
var links = require('./links.js');
var _new = require('./new.js');
var put = require('./put.js');
var stat = require('./stat.js');
var index = require('./patch/index.js');

function createObject(codecs, config) {
  return {
    data: data.createData(config),
    get: get.createGet(config),
    links: links.createLinks(config),
    new: _new.createNew(config),
    put: put.createPut(codecs, config),
    stat: stat.createStat(config),
    patch: index.createPatch(config)
  };
}

exports.createObject = createObject;
