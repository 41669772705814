'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createPut({repo, codecs, hashers, preload}) {
  async function put(dagNode, options = {}) {
    const release = options.pin ? await repo.gcLock.readLock() : null;
    try {
      const codecName = options.format || 'dag-cbor';
      const cidVersion = options.version != null ? options.version : codecName === 'dag-pb' ? 0 : 1;
      const codec = await codecs.getCodec(codecName);
      if (!codec) {
        throw new Error(`Unknown codec ${ options.format }, please configure additional BlockCodecs for this IPFS instance`);
      }
      const hasher = await hashers.getHasher(options.hashAlg || 'sha2-256');
      if (!hasher) {
        throw new Error(`Unknown hash algorithm ${ options.hashAlg }, please configure additional MultihashHashers for this IPFS instance`);
      }
      const buf = codec.encode(dagNode);
      const hash = await hasher.digest(buf);
      const cid$1 = cid.CID.create(cidVersion, codec.code, hash);
      await repo.blocks.put(cid$1, buf, { signal: options.signal });
      if (options.pin) {
        await repo.pins.pinRecursively(cid$1);
      }
      if (options.preload !== false) {
        preload(cid$1);
      }
      return cid$1;
    } finally {
      if (release) {
        release();
      }
    }
  }
  return withTimeoutOption.withTimeoutOption(put);
}

exports.createPut = createPut;
