'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const TaskMerger = {
  hasNewInfo(task, tasksWithTopic) {
    let haveBlock = false;
    let isWantBlock = false;
    for (const existing of tasksWithTopic) {
      if (existing.data.haveBlock) {
        haveBlock = true;
      }
      if (existing.data.isWantBlock) {
        isWantBlock = true;
      }
    }
    if (!isWantBlock && task.data.isWantBlock) {
      return true;
    }
    if (!haveBlock && task.data.haveBlock) {
      return true;
    }
    return false;
  },
  merge(newTask, existingTask) {
    const taskData = newTask.data;
    const existingData = existingTask.data;
    if (!existingData.haveBlock && taskData.haveBlock) {
      existingData.haveBlock = taskData.haveBlock;
      existingData.blockSize = taskData.blockSize;
    }
    if (!existingData.isWantBlock && taskData.isWantBlock) {
      existingData.isWantBlock = true;
      if (!existingData.haveBlock || taskData.haveBlock) {
        existingData.haveBlock = taskData.haveBlock;
        existingTask.size = newTask.size;
      }
    }
    if (existingData.isWantBlock && existingData.haveBlock) {
      existingTask.size = existingData.blockSize;
    }
  }
};

exports.TaskMerger = TaskMerger;
