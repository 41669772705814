'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../lib/configure.js');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var abortSignal = require('../lib/abort-signal.js');
var nativeAbortController = require('native-abort-controller');

const createPut = (codecs, options) => {
  const fn = configure.configure(api => {
    const put = async (dagNode, options = {}) => {
      const settings = {
        format: 'dag-cbor',
        hashAlg: 'sha2-256',
        inputEnc: 'raw',
        ...options
      };
      const codec = await codecs.getCodec(settings.format);
      const serialized = codec.encode(dagNode);
      const controller = new nativeAbortController.AbortController();
      const signal = abortSignal.abortSignal(controller.signal, settings.signal);
      const res = await api.post('dag/put', {
        timeout: settings.timeout,
        signal,
        searchParams: toUrlSearchParams.toUrlSearchParams(settings),
        ...await multipartRequest.multipartRequest(serialized, controller, settings.headers)
      });
      const data = await res.json();
      return cid.CID.parse(data.Cid['/']);
    };
    return put;
  });
  return fn(options);
};

exports.createPut = createPut;
