'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var chmod = require('./chmod.js');
var cp = require('./cp.js');
var flush = require('./flush.js');
var ls = require('./ls.js');
var mkdir = require('./mkdir.js');
var mv = require('./mv.js');
var read = require('./read.js');
var rm = require('./rm.js');
var stat = require('./stat.js');
var touch = require('./touch.js');
var write = require('./write.js');

function createFiles(config) {
  return {
    chmod: chmod.createChmod(config),
    cp: cp.createCp(config),
    flush: flush.createFlush(config),
    ls: ls.createLs(config),
    mkdir: mkdir.createMkdir(config),
    mv: mv.createMv(config),
    read: read.createRead(config),
    rm: rm.createRm(config),
    stat: stat.createStat(config),
    touch: touch.createTouch(config),
    write: write.createWrite(config)
  };
}

exports.createFiles = createFiles;
