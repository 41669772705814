'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createList = configure.configure(api => {
  async function list(options = {}) {
    const res = await api.post('key/list', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    });
    const data = await res.json();
    return (data.Keys || []).map(k => objectToCamel.objectToCamel(k));
  }
  return list;
});

exports.createList = createList;
