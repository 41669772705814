'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');

function createRm(client) {
  async function rm({timeout, signal, headers, ...query}) {
    await client.post('pin/remote/rm', {
      timeout,
      signal,
      headers,
      searchParams: utils.encodeQuery({
        ...query,
        all: false
      })
    });
  }
  return rm;
}

exports.createRm = createRm;
