'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var core = require('../../lib/core.js');
var add = require('./add.js');
var ls = require('./ls.js');
var rm = require('./rm.js');
var rmAll = require('./rm-all.js');
var index = require('./service/index.js');

function createRemote(config) {
  const client = new core.Client(config);
  return {
    add: add.createAdd(client),
    ls: ls.createLs(client),
    rm: rm.createRm(client),
    rmAll: rmAll.createRmAll(client),
    service: index.createService(config)
  };
}

exports.createRemote = createRemote;
