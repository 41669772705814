'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createRm({repo}) {
  async function rm(multiaddr, options = {}) {
    if (!utils.isValidMultiaddr(multiaddr)) {
      throw new Error(`${ multiaddr } is not a valid Multiaddr`);
    }
    const config = await repo.config.getAll(options);
    config.Bootstrap = (config.Bootstrap || []).filter(ma => ma.toString() !== multiaddr.toString());
    await repo.config.replace(config);
    return { Peers: [multiaddr] };
  }
  return withTimeoutOption.withTimeoutOption(rm);
}

exports.createRm = createRm;
