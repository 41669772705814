'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var base = require('./base.js');
var key = require('interface-datastore/key');
var errors = require('./errors.js');

class MemoryDatastore extends base.BaseDatastore {
  constructor() {
    super();
    this.data = {};
  }
  open() {
    return Promise.resolve();
  }
  close() {
    return Promise.resolve();
  }
  async put(key, val) {
    this.data[key.toString()] = val;
  }
  async get(key) {
    const exists = await this.has(key);
    if (!exists)
      throw errors.notFoundError();
    return this.data[key.toString()];
  }
  async has(key) {
    return this.data[key.toString()] !== undefined;
  }
  async delete(key) {
    delete this.data[key.toString()];
  }
  async *_all() {
    yield* Object.entries(this.data).map(([key$1, value]) => ({
      key: new key.Key(key$1),
      value
    }));
  }
  async *_allKeys() {
    yield* Object.entries(this.data).map(([key$1]) => new key.Key(key$1));
  }
}

exports.MemoryDatastore = MemoryDatastore;
