'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const SECOND = 1000;
const maxProvidersPerRequest = 3;
const providerRequestTimeout = 10 * SECOND;
const hasBlockTimeout = 15 * SECOND;
const provideTimeout = 15 * SECOND;
const kMaxPriority = Math.pow(2, 31) - 1;
const maxListeners = 1000;
const wantlistSendDebounceMs = 1;

exports.hasBlockTimeout = hasBlockTimeout;
exports.kMaxPriority = kMaxPriority;
exports.maxListeners = maxListeners;
exports.maxProvidersPerRequest = maxProvidersPerRequest;
exports.provideTimeout = provideTimeout;
exports.providerRequestTimeout = providerRequestTimeout;
exports.wantlistSendDebounceMs = wantlistSendDebounceMs;
