'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var fromString = require('uint8arrays/from-string');

const createGet = configure.configure(api => {
  async function get(cid$1, options = {}) {
    const res = await api.post('object/get', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: `${ cid$1 instanceof Uint8Array ? cid.CID.decode(cid$1) : cid$1 }`,
        dataEncoding: 'base64',
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    return {
      Data: fromString.fromString(data.Data, 'base64pad'),
      Links: (data.Links || []).map(link => ({
        Name: link.Name,
        Hash: cid.CID.parse(link.Hash),
        Tsize: link.Size
      }))
    };
  }
  return get;
});

exports.createGet = createGet;
