'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createStat({network}) {
  async function stat(options = {}) {
    const bitswap = (await network.use(options)).bitswap;
    const snapshot = bitswap.stat().snapshot;
    return {
      provideBufLen: parseInt(snapshot.providesBufferLength.toString()),
      blocksReceived: BigInt(snapshot.blocksReceived.toString()),
      wantlist: Array.from(bitswap.getWantlist()).map(e => e[1].cid),
      peers: bitswap.peers().map(id => id.toB58String()),
      dupBlksReceived: BigInt(snapshot.dupBlksReceived.toString()),
      dupDataReceived: BigInt(snapshot.dupDataReceived.toString()),
      dataReceived: BigInt(snapshot.dataReceived.toString()),
      blocksSent: BigInt(snapshot.blocksSent.toString()),
      dataSent: BigInt(snapshot.dataSent.toString())
    };
  }
  return withTimeoutOption.withTimeoutOption(stat);
}

exports.createStat = createStat;
