'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

class SortedMap extends Map {
  constructor(entries, cmp) {
    super();
    this._cmp = cmp || this._defaultSort;
    this._keys = [];
    for (const [k, v] of entries || []) {
      this.set(k, v);
    }
  }
  update(i) {
    if (i < 0 || i >= this._keys.length) {
      return;
    }
    const k = this._keys[i];
    this._keys.splice(i, 1);
    const newIdx = this._find(k);
    this._keys.splice(newIdx, 0, k);
  }
  set(k, v) {
    if (this.has(k)) {
      const i = this.indexOf(k);
      this._keys.splice(i, 1);
    }
    super.set(k, v);
    const i = this._find(k);
    this._keys.splice(i, 0, k);
    return this;
  }
  clear() {
    super.clear();
    this._keys = [];
  }
  delete(k) {
    if (!this.has(k)) {
      return false;
    }
    const i = this.indexOf(k);
    this._keys.splice(i, 1);
    return super.delete(k);
  }
  indexOf(k) {
    if (!this.has(k)) {
      return -1;
    }
    const i = this._find(k);
    if (this._keys[i] === k) {
      return i;
    }
    for (let j = 1; j < this._keys.length; j++) {
      if (this._keys[i + j] === k)
        return i + j;
      if (this._keys[i - j] === k)
        return i - j;
    }
    return -1;
  }
  _find(k) {
    let lower = 0;
    let upper = this._keys.length;
    while (lower < upper) {
      const pivot = lower + upper >>> 1;
      const cmp = this._kCmp(this._keys[pivot], k);
      if (cmp < 0) {
        lower = pivot + 1;
      } else if (cmp > 0) {
        upper = pivot;
      } else {
        return pivot;
      }
    }
    return lower;
  }
  *keys() {
    for (const k of this._keys) {
      yield k;
    }
    return undefined;
  }
  *values() {
    for (const k of this._keys) {
      yield this.get(k);
    }
    return undefined;
  }
  *entries() {
    for (const k of this._keys) {
      yield [
        k,
        this.get(k)
      ];
    }
    return undefined;
  }
  *[Symbol.iterator]() {
    yield* this.entries();
  }
  forEach(cb, thisArg) {
    if (!cb) {
      return;
    }
    for (const k of this._keys) {
      cb.apply(thisArg, [[
          k,
          this.get(k)
        ]]);
    }
  }
  _defaultSort(a, b) {
    if (a[0] < b[0])
      return -1;
    if (b[0] < a[0])
      return 1;
    return 0;
  }
  _kCmp(a, b) {
    return this._cmp([
      a,
      this.get(a)
    ], [
      b,
      this.get(b)
    ]);
  }
}

exports.SortedMap = SortedMap;
