'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createAdd({repo}) {
  async function add(multiaddr, options = {}) {
    if (!utils.isValidMultiaddr(multiaddr)) {
      throw new Error(`${ multiaddr } is not a valid Multiaddr`);
    }
    const config = await repo.config.getAll(options);
    const boostrappers = config.Bootstrap || [];
    boostrappers.push(multiaddr.toString());
    config.Bootstrap = Array.from(new Set(boostrappers)).sort((a, b) => a.localeCompare(b));
    await repo.config.replace(config);
    return { Peers: [multiaddr] };
  }
  return withTimeoutOption.withTimeoutOption(add);
}

exports.createAdd = createAdd;
