'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../lib/configure.js');
var normaliseInput = require('ipfs-core-utils/pins/normalise-input');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createRmAll = configure.configure(api => {
  async function* rmAll(source, options = {}) {
    for await (const {path, recursive} of normaliseInput.normaliseInput(source)) {
      const searchParams = new URLSearchParams(options.searchParams);
      searchParams.append('arg', `${ path }`);
      if (recursive != null)
        searchParams.set('recursive', String(recursive));
      const res = await api.post('pin/rm', {
        signal: options.signal,
        headers: options.headers,
        searchParams: toUrlSearchParams.toUrlSearchParams({
          ...options,
          arg: `${ path }`,
          recursive
        })
      });
      for await (const pin of res.ndjson()) {
        if (pin.Pins) {
          yield* pin.Pins.map(cid$1 => cid.CID.parse(cid$1));
          continue;
        }
        yield cid.CID.parse(pin);
      }
    }
  }
  return rmAll;
});

exports.createRmAll = createRmAll;
