'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createConnect({network}) {
  async function connect(addr, options = {}) {
    const {libp2p} = await network.use(options);
    await libp2p.dial(addr, options);
  }
  return withTimeoutOption.withTimeoutOption(connect);
}

exports.createConnect = createConnect;
