'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('./object-to-camel.js');

function objectToCamelWithMetadata(entry) {
  const file = objectToCamel.objectToCamel(entry);
  if (Object.prototype.hasOwnProperty.call(file, 'mode')) {
    file.mode = parseInt(file.mode, 8);
  }
  if (Object.prototype.hasOwnProperty.call(file, 'mtime')) {
    file.mtime = {
      secs: file.mtime,
      nsecs: file.mtimeNsecs || 0
    };
    delete file.mtimeNsecs;
  }
  return file;
}

exports.objectToCamelWithMetadata = objectToCamelWithMetadata;
