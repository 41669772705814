'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('../../utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var normaliseInput = require('ipfs-core-utils/pins/normalise-input');
var pinTypes = require('ipfs-repo/pin-types');

function createAddAll({repo, codecs}) {
  async function* addAll(source, options = {}) {
    const pinAdd = async function* () {
      for await (const {path, recursive, metadata} of normaliseInput.normaliseInput(source)) {
        const {cid} = await utils.resolvePath(repo, codecs, path);
        const {reason} = await repo.pins.isPinnedWithType(cid, [
          pinTypes.PinTypes.recursive,
          pinTypes.PinTypes.direct
        ]);
        if (reason === 'recursive' && !recursive) {
          throw new Error(`${ cid } already pinned recursively`);
        }
        if (recursive) {
          await repo.pins.pinRecursively(cid, { metadata });
        } else {
          await repo.pins.pinDirectly(cid, { metadata });
        }
        yield cid;
      }
    };
    const lock = Boolean(options.lock);
    if (!lock) {
      yield* pinAdd();
      return;
    }
    const release = await repo.gcLock.readLock();
    try {
      yield* pinAdd();
    } finally {
      release();
    }
  }
  return withTimeoutOption.withTimeoutOption(addAll);
}

exports.createAddAll = createAddAll;
