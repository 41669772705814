'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createUnwant({network}) {
  async function unwant(cids, options = {}) {
    const {bitswap} = await network.use(options);
    if (!Array.isArray(cids)) {
      cids = [cids];
    }
    return bitswap.unwant(cids);
  }
  return withTimeoutOption.withTimeoutOption(unwant);
}

exports.createUnwant = createUnwant;
