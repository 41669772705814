'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createWantlist({network}) {
  async function wantlist(options = {}) {
    const {bitswap} = await network.use(options);
    const list = bitswap.getWantlist();
    return Array.from(list).map(e => e[1].cid);
  }
  return withTimeoutOption.withTimeoutOption(wantlist);
}

exports.createWantlist = createWantlist;
