'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var stat = require('../bitswap/stat.js');
var stat$1 = require('../repo/stat.js');
var bw = require('./bw.js');

function createStats(config) {
  return {
    bitswap: stat.createStat(config),
    repo: stat$1.createStat(config),
    bw: bw.createBw(config)
  };
}

exports.createStats = createStats;
