'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var dns = require('ipfs-core-config/dns');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function fqdnFixups(domain) {
  if (domain.endsWith('.eth')) {
    domain = domain.replace(/.eth$/, '.eth.link');
  }
  return domain;
}
function createDns() {
  const resolveDNS = async (domain, options = { recursive: true }) => {
    if (typeof domain !== 'string') {
      throw new Error('Invalid arguments, domain must be a string');
    }
    domain = fqdnFixups(domain);
    return dns.resolveDnslink(domain, options);
  };
  return withTimeoutOption.withTimeoutOption(resolveDNS);
}

exports.createDns = createDns;
