'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createList({keychain}) {
  const list = () => keychain.listKeys();
  return withTimeoutOption.withTimeoutOption(list);
}

exports.createList = createList;
