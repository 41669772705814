'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createInfo({keychain}) {
  const info = name => keychain.findKeyByName(name);
  return withTimeoutOption.withTimeoutOption(info);
}

exports.createInfo = createInfo;
