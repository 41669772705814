'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var multiaddr = require('multiaddr');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var responseTypes = require('./response-types.js');

const createFindProvs = configure.configure(api => {
  async function* findProvs(cid, options = {}) {
    const res = await api.post('dht/findprovs', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: cid.toString(),
        ...options
      }),
      headers: options.headers
    });
    for await (const message of res.ndjson()) {
      if (message.Type === responseTypes.Provider && message.Responses) {
        for (const {ID, Addrs} of message.Responses) {
          yield {
            id: ID,
            addrs: (Addrs || []).map(a => new multiaddr.Multiaddr(a))
          };
        }
      }
    }
  }
  return findProvs;
});

exports.createFindProvs = createFindProvs;
