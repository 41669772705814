'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');

function createLs(client) {
  async function* ls({timeout, signal, headers, ...query}) {
    const response = await client.post('pin/remote/ls', {
      timeout,
      signal,
      headers,
      searchParams: utils.encodeQuery(query)
    });
    for await (const pin of response.ndjson()) {
      yield utils.decodePin(pin);
    }
  }
  return ls;
}

exports.createLs = createLs;
