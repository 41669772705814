'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var version = require('../version.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createVersion({repo}) {
  async function version$1(_options = {}) {
    const repoVersion = await repo.version.get();
    return {
      version: version.ipfsCore,
      commit: version.commit,
      repo: `${ repoVersion }`,
      'ipfs-core': version.ipfsCore,
      'interface-ipfs-core': version.interfaceIpfsCore
    };
  }
  return withTimeoutOption.withTimeoutOption(version$1);
}

exports.createVersion = createVersion;
