'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('./lib/configure.js');

const createGetEndpointConfig = configure.configure(api => {
  return () => {
    const url = new URL(api.opts.base || '');
    return {
      host: url.hostname,
      port: url.port,
      protocol: url.protocol,
      pathname: url.pathname,
      'api-path': url.pathname
    };
  };
});

exports.createGetEndpointConfig = createGetEndpointConfig;
