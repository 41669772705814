'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var wantlist = require('./wantlist.js');
var wantlistForPeer = require('./wantlist-for-peer.js');
var stat = require('./stat.js');
var unwant = require('./unwant.js');

function createBitswap(config) {
  return {
    wantlist: wantlist.createWantlist(config),
    wantlistForPeer: wantlistForPeer.createWantlistForPeer(config),
    unwant: unwant.createUnwant(config),
    stat: stat.createStat(config)
  };
}

exports.createBitswap = createBitswap;
