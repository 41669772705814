'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

class NonReversibleMigrationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NonReversibleMigrationError';
    this.code = NonReversibleMigrationError.code;
    this.message = message;
  }
}
NonReversibleMigrationError.code = 'ERR_NON_REVERSIBLE_MIGRATION';
class NotInitializedRepoError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotInitializedRepoError';
    this.code = NotInitializedRepoError.code;
    this.message = message;
  }
}
NotInitializedRepoError.code = 'ERR_NOT_INITIALIZED_REPO';
class RequiredParameterError extends Error {
  constructor(message) {
    super(message);
    this.name = 'RequiredParameterError';
    this.code = RequiredParameterError.code;
    this.message = message;
  }
}
RequiredParameterError.code = 'ERR_REQUIRED_PARAMETER';
class InvalidValueError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidValueError';
    this.code = InvalidValueError.code;
    this.message = message;
  }
}
InvalidValueError.code = 'ERR_INVALID_VALUE';
class MissingRepoOptionsError extends Error {
  constructor(message) {
    super(message);
    this.name = 'MissingRepoOptionsError';
    this.code = MissingRepoOptionsError.code;
    this.message = message;
  }
}
MissingRepoOptionsError.code = 'ERR_MISSING_REPO_OPTIONS';

exports.InvalidValueError = InvalidValueError;
exports.MissingRepoOptionsError = MissingRepoOptionsError;
exports.NonReversibleMigrationError = NonReversibleMigrationError;
exports.NotInitializedRepoError = NotInitializedRepoError;
exports.RequiredParameterError = RequiredParameterError;
