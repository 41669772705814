'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createState({ipns, options}) {
  const experimental = options.EXPERIMENTAL;
  async function state(_options = {}) {
    try {
      return { enabled: Boolean(utils.getPubsubRouting(ipns, experimental)) };
    } catch (err) {
      return { enabled: false };
    }
  }
  return withTimeoutOption.withTimeoutOption(state);
}

exports.createState = createState;
