'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createCancel({ipns, options}) {
  const experimental = options.EXPERIMENTAL;
  async function cancel(name, options = {}) {
    const pubsub = utils.getPubsubRouting(ipns, experimental);
    return pubsub.cancel(name, options);
  }
  return withTimeoutOption.withTimeoutOption(cancel);
}

exports.createCancel = createCancel;
