'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('./lib/object-to-camel.js');
var configure = require('./lib/configure.js');
var toUrlSearchParams = require('./lib/to-url-search-params.js');

const createPing = configure.configure(api => {
  async function* ping(peerId, options = {}) {
    const res = await api.post('ping', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: `${ peerId }`,
        ...options
      }),
      headers: options.headers,
      transform: objectToCamel.objectToCamel
    });
    yield* res.ndjson();
  }
  return ping;
});

exports.createPing = createPing;
