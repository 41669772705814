'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../lib/configure.js');
var normaliseInput = require('ipfs-core-utils/pins/normalise-input');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createAddAll = configure.configure(api => {
  async function* addAll(source, options = {}) {
    for await (const {path, recursive, metadata} of normaliseInput.normaliseInput(source)) {
      const res = await api.post('pin/add', {
        signal: options.signal,
        searchParams: toUrlSearchParams.toUrlSearchParams({
          ...options,
          arg: path,
          recursive,
          metadata: metadata ? JSON.stringify(metadata) : undefined,
          stream: true
        }),
        headers: options.headers
      });
      for await (const pin of res.ndjson()) {
        if (pin.Pins) {
          for (const cid$1 of pin.Pins) {
            yield cid.CID.parse(cid$1);
          }
          continue;
        }
        yield cid.CID.parse(pin);
      }
    }
  }
  return addAll;
});

exports.createAddAll = createAddAll;
