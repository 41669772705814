'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var concat = require('uint8arrays/concat');
var get = require('../get.js');
var put = require('../put.js');

function createAppendData({repo, preload}) {
  const get$1 = get.createGet({
    repo,
    preload
  });
  const put$1 = put.createPut({
    repo,
    preload
  });
  async function appendData(cid, data, options = {}) {
    const node = await get$1(cid, options);
    const newData = concat.concat([
      node.Data || [],
      data
    ]);
    return put$1({
      ...node,
      Data: newData
    }, options);
  }
  return withTimeoutOption.withTimeoutOption(appendData);
}

exports.createAppendData = createAppendData;
