'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var publish = require('./publish.js');
var resolve = require('./resolve.js');
var index = require('./pubsub/index.js');

function createName(config) {
  return {
    publish: publish.createPublish(config),
    resolve: resolve.createResolve(config),
    pubsub: index.createPubsub(config)
  };
}

exports.createName = createName;
