'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var base58 = require('multiformats/bases/base58');

class WantListEntry {
  constructor(cid, priority, wantType) {
    this._refCounter = 1;
    this.cid = cid;
    this.priority = priority || 1;
    this.wantType = wantType;
  }
  inc() {
    this._refCounter += 1;
  }
  dec() {
    this._refCounter = Math.max(0, this._refCounter - 1);
  }
  hasRefs() {
    return this._refCounter > 0;
  }
  get [Symbol.toStringTag]() {
    const cidStr = this.cid.toString(base58.base58btc);
    return `WantlistEntry <key: ${ cidStr }, priority: ${ this.priority }, refs: ${ this._refCounter }>`;
  }
  equals(other) {
    return this._refCounter === other._refCounter && this.cid.equals(other.cid) && this.priority === other.priority && this.wantType === other.wantType;
  }
}

exports.WantListEntry = WantListEntry;
