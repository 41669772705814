'use strict';

var index = require('./migration-8/index.js');
var index$1 = require('./migration-9/index.js');
var index$2 = require('./migration-10/index.js');
var index$3 = require('./migration-11/index.js');

const emptyMigration = {
  description: 'Empty migration.',
  migrate: () => {
  },
  revert: () => {
  },
  empty: true
};
var defaultMigrations = [
  Object.assign({ version: 1 }, emptyMigration),
  Object.assign({ version: 2 }, emptyMigration),
  Object.assign({ version: 3 }, emptyMigration),
  Object.assign({ version: 4 }, emptyMigration),
  Object.assign({ version: 5 }, emptyMigration),
  Object.assign({ version: 6 }, emptyMigration),
  Object.assign({ version: 7 }, emptyMigration),
  index.migration,
  index$1.migration,
  index$2.migration,
  index$3.migration
];

module.exports = defaultMigrations;
