'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createCmds = configure.configure(api => {
  async function cmds(options = {}) {
    const res = await api.post('diag/cmds', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    });
    return res.json();
  }
  return cmds;
});

exports.createCmds = createCmds;
