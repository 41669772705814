'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _export = require('./export.js');
var get = require('./get.js');
var _import = require('./import.js');
var put = require('./put.js');
var resolve = require('./resolve.js');

function createDag(codecs, config) {
  return {
    export: _export.createExport(config),
    get: get.createGet(codecs, config),
    import: _import.createImport(config),
    put: put.createPut(codecs, config),
    resolve: resolve.createResolve(config)
  };
}

exports.createDag = createDag;
