[
	77591, 22417, 43971, 28421, 740, 29829, 71467, 228973, 196661, 78537, 27689, 36431, 44415, 14362, 19456, 106025,
	96308, 2882, 49509, 21149, 87173, 131409, 75844, 23676, 121838, 30291, 17492, 2953, 7564, 110620, 129477, 127283,
	53113, 72417, 165166, 109690, 21200, 102125, 24049, 71504, 90342, 25307, 72039, 26812, 26715, 32264, 133800, 71161,
	88956, 171987, 51779, 24425, 16671, 30251, 186294, 247761, 14202, 2121, 8465, 35024, 4876, 85917, 169730, 3638,
	256836, 96184, 943, 18678, 6583, 52907, 35807, 112254, 214097, 18796, 11595, 9243, 23554, 887, 268203, 382004,
	24590, 111335, 11625, 16619, 29039, 102425, 69006, 97976, 92362, 32552, 63717, 41433, 128974, 137630, 59943, 10019,
	13986, 35430, 33665, 108037, 43799, 43280, 38195, 29078, 58629, 18265, 14425, 46832, 235538, 40830, 77881, 110717,
	58937, 3463, 325358, 51300, 47623, 117252, 19007, 10170, 20540, 91237, 294813, 4951, 79841, 56232, 36270, 128547,
	69209, 66275, 100156, 32063, 73531, 34439, 80937, 28892, 44466, 88595, 216307, 32583, 49620, 16605, 82127, 45807,
	21630, 78726, 20235, 40163, 111007, 96926, 5567, 72083, 21665, 58844, 39419, 179767, 48328, 42662, 51550, 5251,
	37811, 49608, 81056, 50854, 55513, 20922, 18891, 197409, 164656, 32593, 71449, 220474, 58919, 85682, 67854, 13758,
	35066, 3565, 61905, 214793, 119572, 141419, 21504, 10302, 27354, 67003, 46131, 32668, 15165, 64871, 34450, 17821,
	2757, 11452, 34189, 5160, 12257, 85523, 560, 53385, 65887, 119549, 135620, 312353, 115979, 122356, 10867, 193231,
	124537, 54783, 90675, 120791, 4715, 142253, 50943, 17271, 43358, 25331, 4917, 120566, 34580, 12878, 33786, 160528,
	32523, 4869, 301307, 104817, 81491, 23276, 8832, 97911, 31265, 52065, 7998, 49622, 9715, 43998, 34091, 84587,
	20664, 69041, 29419, 53205, 10838, 58288, 116145, 6185, 5154, 141795, 35924, 21307, 144738, 43730, 12085, 8279,
	10002, 119, 133779, 199668, 72938, 31768, 39176, 67875, 38453, 9700, 44144, 4121, 116048, 41733, 12868, 82669,
	92308, 128, 34262, 11332, 7712, 90764, 36141, 13553, 71312, 77470, 117314, 96549, 49135, 23602, 54468, 28605,
	6327, 62308, 17171, 67531, 21319, 14105, 894, 107722, 46157, 8503, 51069, 100472, 45138, 15246, 14577, 35609,
	191464, 1757, 13364, 161349, 32067, 91705, 81144, 52339, 5408, 91066, 21983, 14157, 100545, 4372, 26630, 129112,
	1423, 29676, 213626, 4397, 88436, 99190, 6877, 49958, 26122, 114348, 60661, 29818, 293118, 50042, 179738, 16400,
	163423, 89627, 31040, 43973, 36638, 45952, 5153, 1894, 109322, 1898, 134021, 12402, 112077, 68309, 190269, 69866,
	31938, 107383, 11522, 105232, 11248, 14868, 39852, 71707, 186525, 16530, 38162, 106212, 11700, 5130, 16608, 26998,
	59586, 108399, 230033, 43683, 48135, 82179, 2073, 5015, 196684, 189293, 16378, 23452, 8301, 35640, 11632, 214551,
	29240, 57644, 33137, 91949, 55157, 52384, 117313, 5090, 17717, 89668, 49363, 82238, 241035, 66216, 29066, 184088,
	97206, 62820, 26595, 4241, 135635, 173672, 8202, 459, 71355, 146294, 29587, 3008, 135385, 141203, 14803, 6634,
	45094, 69362, 50925, 546, 51884, 62011, 83296, 234584, 44515, 56050, 89476, 87751, 19373, 12691, 149923, 19794,
	13833, 35846, 87557, 58339, 2884, 19145, 25647, 12224, 11024, 77338, 64608, 122297, 53025, 7205, 36189, 36294,
	170779, 21750, 7739, 173883, 75192, 35664, 224240, 113121, 30181, 26267, 27036, 117827, 92015, 106516, 55628, 203549,
	67949, 60462, 60844, 35911, 20457, 1820, 920, 19773, 8738, 73173, 181993, 38521, 98254, 76257, 46008, 92796,
	5384, 26868, 151566, 22124, 2411, 15919, 186872, 180021, 28099, 152961, 78811, 80237, 62352, 102653, 74259, 184890,
	16792, 123702, 224945, 29940, 19512, 75283, 14059, 112691, 92811, 233329, 20411, 138569, 53341, 109802, 50600, 134528,
	66747, 5529, 166531, 31578, 64732, 67189, 1596, 126357, 967, 167999, 206598, 109752, 119431, 207825, 78791, 91938,
	10301, 27311, 24233, 252343, 28831, 32812, 66002, 112267, 90895, 8786, 8095, 16824, 22866, 21813, 60507, 174833,
	19549, 130985, 117051, 52110, 6938, 81923, 123864, 38061, 919, 18680, 53534, 46739, 112893, 161529, 85429, 26761,
	11900, 81121, 91968, 15390, 217947, 56524, 1713, 6654, 37089, 85630, 138866, 61850, 16491, 75577, 16884, 98296,
	73523, 6140, 44645, 6062, 36366, 29844, 57946, 37932, 42472, 5266, 20834, 19309, 33753, 127182, 134259, 35810,
	41805, 45878, 312001, 14881, 47757, 49251, 120050, 44252, 3708, 25856, 107864, 120347, 1228, 36550, 41682, 34496,
	47025, 8393, 173365, 246526, 12894, 161607, 35670, 90785, 126572, 2095, 124731, 157033, 58694, 554, 12786, 9642,
	4817, 16136, 47864, 174698, 66992, 4639, 69284, 10625, 40710, 27763, 51738, 30404, 264105, 137904, 109882, 52487,
	42824, 57514, 2740, 10479, 146799, 107390, 16586, 88038, 174951, 9410, 16185, 44158, 5568, 40658, 46108, 12763,
	97385, 26175, 108859, 664, 230732, 67470, 46663, 14395, 50750, 141320, 93140, 15361, 47997, 55784, 6791, 307840,
	118569, 107326, 18056, 58281, 260415, 54691, 8790, 73332, 45633, 7511, 45674, 143373, 14031, 11799, 94491, 35646,
	96544, 14560, 26049, 32983, 25791, 83814, 42094, 231370, 63955, 139212, 2359, 169908, 3108, 183486, 105867, 28197,
	32941, 124968, 26402, 88267, 149768, 23053, 3078, 19091, 52924, 25383, 19209, 111548, 97361, 3959, 24880, 235061,
	9099, 24921, 161254, 151405, 20508, 7159, 34381, 20133, 11434, 74036, 19974, 34769, 36585, 1076, 22454, 17354,
	38727, 235160, 111547, 96454, 117448, 156940, 91330, 37299, 7310, 26915, 117060, 51369, 22620, 61861, 322264, 106850,
	111694, 15091, 2624, 40345, 300446, 177064, 1707, 27389, 54792, 327783, 132669, 183543, 59003, 17744, 20603, 151134,
	106923, 53084, 71803, 279424, 319816, 11579, 21946, 16728, 38274, 72711, 5085, 83391, 88646, 40159, 25027, 34680,
	10752, 12988, 54126, 30365, 18338, 100445, 230674, 44874, 84974, 143877, 123253, 139372, 28082, 91477, 144002, 13096,
	219729, 46016, 50029, 42377, 14601, 6660, 58244, 58978, 23918, 88206, 113611, 64452, 17541, 41032, 10942, 12021,
	49189, 10978, 40175, 37156, 10947, 71709, 106894, 112538, 57007, 137486, 150608, 152719, 40615, 7746, 279716, 13101,
	19524, 28708, 40578, 72320, 1096, 182051, 94527, 51275, 22833, 45164, 81917, 77519, 48508, 5421, 140302, 37845,
	149830, 5587, 27579, 5357, 428725, 248187, 6326, 206760, 39814, 32585, 89923, 44341, 288753, 284443, 96368, 31201,
	94189, 119504, 20359, 52073, 103216, 179, 27934, 32801, 96035, 34111, 34309, 101326, 18198, 20704, 210266, 37643,
	27880, 141873, 106000, 19414, 56614, 167714, 66483, 107885, 86602, 4379, 20796, 75467, 4987, 5017, 118857, 26003,
	34308, 114428, 29198, 6686, 29697, 73632, 3739, 69795, 16798, 41504, 7207, 30722, 21436, 36735, 28067, 28545,
	3239, 11221, 36031, 41889, 100010, 19247, 317673, 29495, 174554, 6424, 129725, 53845, 94986, 7955, 59676, 2604,
	191497, 19735, 102214, 62954, 23844, 11872, 179525, 261436, 34492, 428, 78404, 142035, 16747, 17246, 27578, 37021,
	33672, 57944, 26056, 135760, 2369, 61674, 122066, 31327, 19374, 157065, 40553, 130982, 69619, 71290, 38855, 72100,
	92903, 95940, 51422, 165999, 65713, 57873, 50726, 7288, 20272, 2081, 42326, 22624, 81120, 57914, 79352, 19447,
	1684, 72302, 11774, 302559, 161481, 96396, 13692, 414988, 3721, 79066, 56627, 46883, 21150, 11747, 12184, 5856,
	113458, 176117, 84416, 52079, 27933, 3354, 59765, 141359, 2212, 216309, 2555, 23458, 196722, 142463, 45701, 44548,
	28798, 19418, 215, 29916, 9396, 10574, 114226, 84475, 13520, 18694, 34056, 4524, 90302, 62930, 13539, 19407,
	77209, 7728, 38088, 9535, 2263, 23875, 183945, 17750, 26274, 67172, 10585, 28042, 22199, 7478, 51331, 66030,
	26774, 192929, 31434, 25850, 50197, 52926, 178158, 4679, 181256, 70184, 229600, 9959, 105594, 72158, 73974, 2726,
	35085, 78087, 23284, 35568, 51713, 155676, 5401, 27254, 11966, 17569, 223253, 71993, 103357, 111477, 55722, 30504,
	26034, 46774, 35392, 36285, 214814, 41143, 163465, 1051, 16094, 81044, 6636, 76489, 179102, 20712, 39178, 35683,
	125177, 54219, 30617, 52994, 25324, 50123, 2543, 87529, 58995, 10688, 125199, 12388, 60158, 125481, 131646, 7642,
	133350, 65874, 3438, 97277, 101450, 10075, 56344, 116821, 50778, 60547, 98016, 106135, 13859, 14255, 16300, 77373,
	173521, 8285, 45932, 37426, 4054, 114295, 55947, 7703, 39114, 52, 51119, 128135, 19714, 60715, 9554, 50492,
	88180, 2823, 118271, 52993, 122625, 97919, 23859, 37895, 25040, 33614, 32102, 20431, 3577, 9275, 15686, 43031,
	157741, 110358, 1884, 40291, 125391, 13736, 5008, 64881, 87336, 77381, 70711, 43032, 49155, 118587, 70494, 4318,
	10168, 30126, 12580, 10524, 280104, 104001, 145413, 2862, 84140, 6603, 106005, 13566, 12780, 11251, 42830, 571,
	179910, 82443, 13146, 469, 42714, 32591, 265217, 424024, 92553, 54721, 134100, 6007, 15242, 114681, 59030, 16718,
	85465, 200214, 85982, 55174, 165013, 23493, 56964, 82529, 109150, 32706, 27568, 82442, 5350, 14976, 13165, 44890,
	60021, 21343, 33978, 17264, 4655, 22328, 27819, 75730, 16567, 55483, 14510, 17926, 45827, 150609, 3704, 7385,
	272531, 161543, 76904, 122163, 52405, 2039, 19165, 41623, 14423, 228354, 3369, 176360, 85491, 7122, 35789, 303724,
	4465, 13628, 2233, 55311, 118771, 20713, 10006, 221519, 45115, 71021, 35650, 29775, 7337, 10864, 20665, 21142,
	1746, 15080, 1624, 32449, 10905, 105743, 229797, 7701, 3940, 22997, 178467, 57208, 389057, 39683, 59403, 63344,
	63125, 54847, 69691, 18336, 56448, 3362, 37202, 18282, 29648, 138224, 35867, 10495, 5911, 28814, 26653, 31514,
	176702, 26550, 45621, 11734, 4525, 40543, 73944, 121080, 27858, 155561, 14887, 44670, 30742, 8796, 107455, 113472,
	56369, 75581, 183777, 240095, 133699, 153299, 8768, 160464, 26058, 49078, 103971, 21875, 71486, 44888, 17156, 9678,
	89541, 123019, 102337, 3972, 83930, 21245, 87852, 109660, 287918, 183019, 686, 10100, 39177, 283941, 11274, 24736,
	26793, 26214, 25995, 77011, 141580, 4070, 23742, 46285, 46632, 30700, 26669, 19056, 35951, 115575, 174034, 56097,
	35463, 87425, 24575, 44245, 38701, 82317, 85922, 281616, 100333, 147697, 61503, 7730, 84330, 8530, 59917, 61597,
	17173, 9092, 32658, 90288, 193136, 39023, 20381, 56654, 31132, 7779, 1919, 1375, 117128, 30819, 11169, 40938,
	23935, 115201, 101155, 151034, 4835, 11231, 74550, 89388, 59951, 91704, 107312, 167882, 115062, 12732, 72738, 88703,
	464019, 158267, 57995, 60496, 737, 14371, 123867, 4174, 243339, 159946, 7568, 16025, 134556, 110916, 38103, 191,
	80226, 88794, 29688, 27230, 10454, 76308, 57647, 77409, 113483, 66864, 14745, 19808, 12023, 46583, 84805, 16015,
	17102, 2231, 20611, 3547, 95740, 250131, 34559, 108894, 8498, 15853, 159169, 148920, 20942, 2813, 93160, 45188,
	210613, 45531, 52587, 149062, 39782, 28194, 57849, 60965, 84954, 89766, 84453, 100927, 16501, 27658, 165311, 103841,
	54192, 207341, 19558, 20084, 319622, 5672, 205467, 98462, 61849, 36279, 13609, 147177, 24726, 165015, 209489, 59591,
	31157, 6551, 117580, 75060, 141146, 277310, 21072, 22023, 106474, 63041, 137443, 122965, 68371, 5383, 42146, 98961,
	113467, 30863, 23794, 4843, 99630, 30392, 82679, 13699, 241612, 33601, 93146, 24319, 18643, 32155, 95669, 40440,
	15333, 34089, 67799, 142144, 58245, 38633, 114531, 117400, 77861, 188726, 5507, 2568, 8853, 10987, 107222, 2663,
	2421, 11530, 13345, 30075, 41785, 118661, 104786, 17459, 12490, 16281, 71936, 193555, 17431, 5944, 71758, 26485,
	77317, 20803, 367167, 158, 7362, 93430, 11735, 172445, 46002, 11532, 54482, 930, 62911, 2235, 23004, 179236,
	4764, 101859, 208113, 22477, 55163, 95579, 14098, 67320, 162556, 90709, 156949, 3826, 57492, 4025, 34092, 87442,
	104565, 6718, 186015, 28214, 14209, 10039, 107186, 233912, 58877, 81637, 55265, 39828, 6194, 145813, 50831, 105849,
	4974, 88319, 122296, 10272, 197216, 95714, 51540, 72418, 23324, 91555, 8743, 140452, 250249, 51666, 34124, 7229,
	38592, 129641, 78169, 174242, 22464, 149964, 51450, 14034, 10026, 95376, 26190, 120062, 14401, 8700, 265, 31386,
	143573, 7203, 229889, 61567, 4227, 140981, 2466, 72052, 10787, 10062, 30958, 6099, 38471, 30103, 23202, 208101,
	70847, 467, 58934, 32271, 32984, 36637, 24107, 30771, 17109, 73353, 13650, 2098, 157040, 67366, 66904, 106018,
	265380, 107238, 18535, 44025, 32681, 144983, 62505, 91295, 56120, 3082, 77508, 10322, 63023, 36700, 81885, 224127,
	16721, 45023, 239261, 111272, 13852, 7866, 149243, 204199, 32309, 22084, 42029, 38316, 126644, 104973, 14406, 43454,
	67322, 61310, 15789, 40285, 24026, 181047, 6301, 70927, 23319, 115823, 27248, 66693, 115875, 278566, 63007, 146844,
	56841, 59007, 87368, 180001, 22370, 42114, 80605, 12022, 10374, 308, 25079, 14689, 12618, 63368, 7936, 264973,
	212291, 136713, 95999, 105801, 18965, 32075, 48700, 52230, 35119, 96912, 32992, 8586, 16606, 101333, 101812, 14969,
	39930, 759, 193090, 27387, 42914, 12937, 5058, 62646, 64528, 38624, 25743, 37502, 3716, 4435, 30352, 178687,
	26461, 132611, 42002, 138442, 35833, 59582, 16345, 8048, 60319, 49349, 309, 47800, 49739, 90482, 26405, 34470,
	63786, 32479, 85028, 39866, 47846, 11649, 23934, 29466, 2816, 42864, 31828, 7410, 74885, 49632, 47629, 111801,
	90749, 19536, 18767, 105764, 59606, 21223, 10746, 76298, 22220, 39408, 7190, 79654, 64856, 11602, 82156, 272765,
	17079, 70089, 245473, 51813, 184407, 384678, 1576, 122249, 5064, 27481, 6188, 25790, 74361, 27541, 318284, 45430,
	31488, 620, 93579, 45723, 192118, 22670, 51913, 4162, 70244, 35966, 26397, 16199, 50899, 209613, 121702, 287507,
	2993, 36101, 132229, 67345, 33062, 76295, 118628, 78705, 52316, 34375, 107083, 107454, 44863, 127561, 33964, 3073,
	154010, 190914, 55967, 39074, 6272, 31047, 5550, 41123, 26154, 98638, 47110, 19998, 148091, 50229, 31329, 59900,
	195442, 19106, 61347, 73497, 70015, 682, 45850, 25776, 38022, 148951, 6288, 37411, 232526, 109277, 27286, 32342,
	9262, 5220, 16651, 23175, 46740, 129438, 78614, 121925, 66914, 88710, 127952, 5563, 21500, 34521, 10739, 14863,
	191006, 62956, 17359, 16749, 67027, 56284, 69134, 43301, 35039, 58883, 54466, 60823, 404451, 75743, 59856, 86979,
	7923, 34273, 83785, 32142, 7693, 268986, 197428, 282681, 17049, 22346, 22990, 92245, 107180, 3357, 37104, 96724,
	49153, 7683, 31197, 43267, 82231, 164276, 23696, 20848, 188364, 22309, 24821, 158707, 1018, 22514, 70922, 27792,
	45589, 59709, 10765, 736, 35218, 63479, 51987, 24275, 63588, 55361, 92929, 81964, 4658, 20122, 12330, 44058,
	13065, 311456, 72224, 8337, 211229, 38979, 22590, 138478, 52757, 32595, 133600, 8838, 31549, 94412, 43391, 90056,
	1585, 94802, 127271, 6223, 31889, 137038, 132910, 2165, 57616, 230152, 6080, 10748, 36737, 74579, 134062, 50525,
	180532, 119270, 34556, 76155, 82394, 52595, 29258, 31435, 87820, 67996, 26943, 183878, 38007, 2410, 13526, 180297,
	69856, 3503, 187396, 167700, 7838, 16701, 9199, 56267, 3661, 37407, 65994, 23767, 5708, 62508, 221700, 67088,
	86978, 46776, 84434, 32088, 5612, 9149, 88244, 21685, 95151, 46750, 189612, 2979, 506311, 2594, 3628, 40074,
	105039, 78243, 28523, 6651, 38058, 71999, 30992, 12764, 68261, 108991, 6165, 26450, 61961, 13400, 22426, 7490,
	60890, 109623, 2070, 12958, 50355, 67979, 257096, 7213, 42578, 52121, 35716, 65461, 7516, 124758, 39268, 302,
	64712, 14977, 1467, 219452, 2840, 34229, 11121, 21602, 19270, 63574, 8024, 1532, 17331, 79839, 78885, 52029,
	180767, 57957, 6069, 91265, 61380, 55767, 8927, 32881, 287603, 22149, 35029, 68876, 6428, 199567, 46926, 13412,
	104132, 21434, 366616, 45060, 110046, 81924, 128910, 45886, 52821, 130416, 29416, 77342, 21762, 67329, 121432, 79924,
	11724, 38625, 81006, 102033, 28338, 13326, 3250, 82056, 82526, 38212, 21112, 12382, 111495, 3263, 7414, 86274,
	93490, 40844, 30224, 45212, 24019, 48411, 71367, 24941, 76729, 57776, 3769, 38114, 202019, 197745, 31953, 237533,
	33270, 201580, 255648, 100798, 44741, 32241, 98468, 106931, 10085, 15090, 170358, 33154, 66787, 18819, 69760, 25061,
	234005, 82660, 6295, 131975, 16874, 9076, 4094, 25005, 17740, 40908, 19533, 220019, 44330, 99792, 50040, 19619,
	13950, 55228, 24423, 31253, 95308, 103177, 184795, 28590, 82285, 5059, 3210, 75525, 49894, 70007, 56178, 10580,
	36051, 139681, 21617, 98736, 3555, 106306, 164189, 37352, 63915, 47824, 24883, 145530, 61904, 28444, 11483, 19837,
	145446, 30420, 112972, 85939, 11835, 191233, 2262, 20705, 58630, 1753, 148334, 1197, 144714, 6887, 11223, 107667,
	60879, 77914, 4151, 57417, 81594, 96681, 169430, 1784, 20444, 95138, 254041, 27038, 596, 7117, 72808, 13759,
	3353, 126776, 21074, 55322, 27081, 36942, 39547, 139830, 179275, 4453, 713, 8722, 71399, 19204, 25785, 22794,
	23923, 104114, 11291, 25458, 102309, 88396, 75288, 230440, 206396, 104551, 58447, 130857, 37247, 94734, 31548, 176529,
	226077, 65159, 20104, 10096, 66881, 94191, 237909, 27109, 37404, 1520, 27421, 25220, 113003, 23423, 24884, 50585,
	6286, 231877, 150800, 11789, 3226, 90004, 60642, 5053, 202400, 61442, 132531, 175329, 57138, 30116, 103847, 9973,
	75367, 16452, 32360, 59119, 21246, 10191, 164804, 23305, 61051, 37348, 154530, 13214, 5468, 50403, 66754, 130976,
	50559, 80515, 14436, 155492, 84017, 5472, 43107, 41240, 2890, 90431, 70188, 382, 76234, 48040, 50211, 281038,
	237007, 32115, 142178, 1536, 22761, 96429, 1811, 31243, 1679, 49143, 55209, 17402, 235054, 61494, 7462, 77030,
	34925, 87609, 78002, 9499, 9027, 73289, 201078, 101379, 63544, 27666, 5469, 10642, 30029, 49816, 132979, 95620,
	58086, 351930, 116300, 2110, 2043, 30845, 6154, 11279, 16727, 4122, 2277, 27281, 4971, 3650, 39060, 61970,
	65951, 39674, 75686, 38151, 11370, 130809, 177895, 32665, 63725, 122267, 7857, 39618, 118483, 44792, 157755, 178624,
	136994, 24260, 41308, 22471, 12404, 21707, 12486, 30473, 52781, 50246, 20247, 39065, 909, 56825, 103158, 128603,
	31542, 1089, 41935, 32744, 12428, 37963, 84420, 33134, 72921, 208449, 42622, 168151, 127335, 147107, 46699, 38216,
	12591, 94342, 85814, 31423, 24944, 2605, 87542, 67473, 192551, 4496, 56321, 91819, 17630, 6300, 256183, 114569,
	202090, 33209, 35289, 34897, 24967, 40520, 43470, 5344, 10199, 34810, 14283, 10381, 10017, 62923, 49924, 23233,
	64539, 13051, 35686, 19698, 11570, 135555, 120868, 44924, 87065, 52318, 52335, 47586, 140906, 245885, 109834, 78668,
	9065, 46990, 25258, 72022, 61243, 40838, 4545, 146387, 10537, 11557, 17470, 36930, 68104, 46711, 24264, 79401,
	81043, 18225, 120488, 24746, 84338, 81652, 28266, 13776, 21878, 46973, 1047, 230465, 73357, 95777, 24973, 210160,
	62210, 58404, 110633, 169651, 6937, 41870, 9909, 26822, 191062, 76553, 27519, 96256, 239070, 2478, 205678, 67955,
	58532, 20601, 50120, 19148, 78501, 195724, 110740, 8249, 109665, 27446, 30568, 57631, 31425, 49752, 32820, 65504,
	50079, 3663, 102256, 219898, 23849, 211315, 14645, 4359, 91767, 9528, 12449, 49366, 7941, 49763, 107848, 8930,
	27086, 50686, 9744, 10447, 81935, 39513, 46514, 1670, 29229, 6172, 22312, 137280, 97759, 9806, 14445, 22976,
	56458, 73391, 34983, 93760, 174219, 52573, 33149, 59747, 2429, 136277, 75123, 165263, 91040, 7446, 57632, 48633,
	97140, 246081, 84766, 151684, 79918, 93268, 120346, 54059, 54875, 77858, 32996, 103590, 45276, 11968, 19600, 25849,
	17159, 132907, 42828, 16817, 4913, 99462, 103303, 27395, 5737, 74184, 20749, 21160, 14377, 77062, 131403, 158735,
	10999, 27799, 77785, 9320, 34366, 51593, 61070, 33746, 47048, 29268, 36675, 30262, 53297, 9832, 82000, 20188,
	122292, 39917, 7331, 18160, 68301, 185935, 134830, 15031, 4935, 10004, 165845, 185534, 46923, 30109, 44134, 122631,
	18874, 22903, 112790, 26561, 18549, 348902, 82871, 140345, 255565, 135390, 63556, 103747, 145055, 179600, 145662, 296111,
	61661, 211987, 23952, 52342, 126343, 48450, 32919, 44277, 82185, 9591, 62139, 205363, 376969, 394874, 108461, 18040,
	120885, 14798, 39863, 16571, 16794, 58271, 81025, 55206, 14640, 118656, 6361, 44092, 85970, 6262, 153863, 108244,
	180200, 72264, 79947, 38044, 10050, 5735, 61221, 80712, 5471, 115689, 11391, 11661, 184257, 20010, 60116, 30320,
	19327, 134598, 45455, 27542, 18004, 125092, 452272, 1549, 91523, 46567, 180063, 156026, 2608, 11174, 58848, 37788,
	65907, 80194, 30490, 5786, 40775, 119519, 106241, 11323, 156297, 8425, 61495, 2617, 29675, 2425, 59886, 112582,
	49142, 59618, 4863, 50597, 86710, 50650, 168632, 27693, 85641, 83643, 18993, 25768, 84284, 28090, 93592, 36627,
	312804, 43381, 9887, 9402, 100931, 97165, 3311, 173330, 66805, 28935, 4963, 184460, 3201, 78102, 19126, 21607,
	37496, 24938, 22615, 16153, 32862, 134792, 153318, 61120, 6067, 2812, 12826, 12792, 23825, 37559, 64662, 202250,
	102694, 155488, 85881, 149193, 46233, 65383, 15521, 106982, 11358, 176786, 25752, 39717, 34208, 24510, 32464, 77742,
	39371, 72028, 138229, 60688, 71386, 102834, 132477, 2208, 11548, 63670, 271279, 28351, 30338, 38620, 32491, 99845,
	143885, 152266, 13252, 2825, 178663, 108097, 1775, 78201, 14897, 113573, 163346, 62292, 171129, 22183, 96598, 38733,
	64971, 166776, 117445, 9968, 146393, 44677, 74867, 20908, 97328, 12761, 25656, 26785, 9148, 112344, 26115, 99176,
	110121, 22437, 49547, 6180, 79320, 5835, 31392, 43328, 33377, 75870, 119860, 69497, 80273, 7325, 155219, 43167,
	111173, 28347, 20222, 3763, 71752, 55041, 47252, 14618, 28088, 15012, 97805, 194698, 54636, 2036, 41349, 6173,
	96604, 61530, 51859, 43782, 13361, 24334, 22668, 24792, 7070, 23441, 16789, 3209, 36211, 208475, 26242, 32880,
	122181, 182407, 21444, 31060, 88459, 29929, 77907, 12716, 10934, 97005, 20599, 31690, 8403, 58445, 30303, 22700,
	10336, 86731, 103115, 337709, 72556, 46788, 112566, 47684, 67089, 53548, 36874, 56487, 41387, 125985, 26893, 40071,
	106683, 73712, 18787, 40105, 72992, 67246, 137276, 50802, 36790, 70328, 138827, 22466, 39263, 183295, 29858, 50975,
	9322, 57397, 10654, 24364, 30383, 55799, 41600, 23584, 127295, 296610, 129078, 143558, 244131, 86397, 36049, 1085,
	80677, 3820, 108139, 5476, 34767, 24683, 7758, 13060, 7239, 131671, 250593, 59556, 103392, 29810, 4188, 252323,
	39404, 116877, 7651, 43600, 40338, 13554, 157253, 39196, 25978, 144387, 61211, 234, 50104, 6129, 10449, 93777,
	9240, 356378, 274148, 4439, 72970, 3724, 147770, 78680, 62570, 115877, 40027, 40547, 36817, 224392, 64609, 34795,
	165027, 67440, 2477, 37206, 23431, 50754, 164797, 46018, 94995, 170982, 27051, 7957, 22767, 3674, 27900, 56419,
	18930, 60701, 41302, 2692, 84749, 339721, 61996, 111094, 80221, 50129, 1045, 8153, 62945, 19202, 8250, 37208,
	37418, 32560, 79477, 41106, 88569, 33963, 36693, 5892, 30570, 1581, 66471, 49647, 11922, 160717, 29442, 5643,
	114865, 82962, 95982, 132098, 22633, 22838, 94726, 54556, 28566, 205039, 162340, 33216, 16849, 35847, 221339, 94851,
	26533, 71469, 1805, 3804, 12935, 45483, 71020, 36310, 65381, 192960, 34240, 35165, 59773, 1248, 46954, 155332,
	96864, 4246, 388800, 16129, 57133, 74592, 44807, 442014, 38203, 42574, 80818, 91592, 26377, 36424, 65760, 977,
	77387, 22628, 147610, 28018, 30561, 98454, 6969, 119628, 63648, 18170, 36854, 26601, 64018, 22027, 37279, 51395,
	152934, 21153, 9430, 58760, 194742, 5330, 55115, 34158, 28917, 174111, 13171, 122326, 1526, 43896, 66094, 25325,
	4234, 148354, 11450, 275, 18999, 112191, 44365, 22723, 68409, 8733, 57746, 96565, 75007, 14196, 108844, 29475,
	88599, 177563, 100792, 106156, 86323, 93726, 14248, 135341, 194131, 40126, 47099, 14779, 8272, 39597, 95983, 171398,
	65882, 28052, 10393, 47213, 40689, 22120, 72212, 106829, 34964, 109146, 753, 648, 21660, 30047, 17527, 181025,
	5619, 145357, 4085, 216883, 9359, 186951, 24779, 53931, 24545, 36197, 223296, 62628, 168101, 4243, 107313, 30321,
	26642, 13049, 51059, 31027, 107912, 807, 73550, 26551, 84369, 122422, 165872, 49754, 74213, 234264, 33151, 52014,
	33100, 87183, 22365, 52500, 40013, 23302, 5652, 72723, 21404, 26107, 48434, 587, 94049, 168493, 96418, 32871,
	70860, 31709, 25128, 443, 71597, 166253, 15670, 70994, 26341, 133675, 28280, 75491, 54756, 47955, 56028, 26182,
	11952, 113272, 472197, 64640, 110753, 17919, 337, 50642, 22576, 142, 87371, 53391, 93210, 126694, 15285, 19642,
	85667, 14148, 1506, 42092, 52962, 33243, 11970, 20734, 135843, 57044, 58880, 13002, 219134, 22876, 64754, 232519,
	4257, 43120, 321573, 24799, 64526, 124728, 52579, 81472, 70831, 276848, 17403, 74359, 23021, 182101, 74597, 23744,
	148267, 12055, 7976, 5349, 11772, 67540, 167347, 65318, 18720, 127832, 108238, 22828, 90233, 9987, 259080, 118185,
	73209, 79270, 13775, 90100, 137742, 90799, 70569, 15699, 19961, 9087, 67475, 57872, 39731, 8810, 134897, 131868,
	146849, 19898, 3334, 2281, 167061, 91073, 60356, 467742, 74712, 188, 53179, 137679, 92769, 29241, 9537, 132595,
	80119, 1041, 88962, 5976, 40171, 44911, 102859, 139059, 104558, 98987, 47761, 19272, 71472, 113864, 175377, 73338,
	10857, 23402, 23758, 1591, 139864, 5644, 4076, 118760, 16427, 134198, 18853, 20291, 100849, 37423, 22038, 36677,
	19071, 195521, 57445, 11069, 31869, 55718, 66882, 148490, 44, 41296, 75242, 49704, 166810, 9906, 20943, 122258,
	49112, 105667, 15969, 10344, 6408, 187694, 21399, 72742, 58970, 14867, 14376, 81889, 41856, 23225, 15042, 56993,
	16074, 131389, 74276, 72407, 53875, 383108, 53597, 37363, 68993, 44854, 122548, 430927, 198279, 38430, 80409, 12245,
	2981, 628, 2818, 17760, 37437, 238229, 7968, 46892, 2200, 3730, 34190, 65983, 37959, 112291, 87850, 70827,
	6522, 20750, 73913, 111621, 41652, 19587, 2780, 58668, 25916, 85259, 18200, 168962, 95781, 42445, 102050, 7776,
	57662, 103313, 47742, 96358, 41964, 66174, 100396, 29069, 204735, 19679, 27978, 7479, 40264, 22534, 61183, 36081,
	107436, 58223, 14680, 23002, 101311, 24716, 124108, 12908, 5646, 31750, 40380, 14215, 232799, 102772, 14122, 96775,
	61398, 50917, 12096, 149880, 67833, 598749, 124194, 155871, 49216, 790, 14677, 65319, 56917, 7440, 145744, 95701,
	12206, 49405, 129269, 76199, 45732, 9767, 11058, 9047, 210885, 11051, 7392, 26307, 2130, 8132, 147526, 20802,
	232698, 115660, 50060, 59789, 57344, 107623, 80343, 112676, 23291, 9866, 160971, 34032, 118291, 15719, 59730, 164911,
	28975, 2659, 58046, 78480, 21854, 66209, 53863, 109085, 116045, 29021, 46481, 107552, 22130, 18764, 70254, 31272,
	11300, 52460, 43933, 84738, 20721, 53869, 190840, 79673, 105300, 7561, 321817, 66924, 13940, 33281, 101046, 183181,
	32176, 71878, 5678, 62924, 79535, 56646, 40303, 19559, 27703, 93042, 73368, 42187, 3670, 37376, 46440, 7023,
	36816, 109628, 20680, 5940, 276440, 275233, 170848, 112093, 136996, 14984, 20226, 111441, 77693, 112960, 48577, 39370,
	55707, 50314, 123404, 26570, 54281, 61372, 123391, 4857, 35928, 246740, 132507, 106646, 44241, 7196, 92258, 9825,
	37688, 51197, 303141, 5590, 15476, 132986, 10955, 85782, 34486, 26696, 7991, 28813, 18858, 39546, 11703, 11365,
	38185, 5716, 93555, 11925, 40121, 60002, 6985, 10976, 171384, 3887, 43394, 13337, 56346, 6381, 252336, 39573,
	75042, 53711, 1028, 31781, 44295, 95925, 131713, 7214, 68125, 43571, 70954, 213234, 1628, 8760, 13391, 65485,
	17320, 56038, 1710, 25248, 60803, 57399, 19839, 3870, 326, 281556, 50945, 72400, 21460, 316244, 75619, 56246,
	98775, 481, 13513, 55765, 50427, 7388, 123519, 32929, 57908, 27124, 61316, 101097, 57467, 30228, 48792, 10788,
	20402, 37318, 50526, 155730, 34456, 158065, 145305, 17832, 43733, 64052, 4506, 35072, 205355, 177028, 184004, 187081,
	68616, 35938, 83703, 10367, 36892, 93186, 260137, 51934, 89970, 4985, 23445, 26755, 21558, 7948, 78741, 23376,
	124405, 85594, 68596, 57536, 49351, 12619, 56593, 132668, 99924, 109728, 71844, 71935, 196018, 65464, 17617, 14987,
	89701, 143773, 33997, 8687, 22701, 33258, 2914, 4436, 72108, 85610, 9671, 49067, 2327, 82988, 1361, 1672,
	44033, 35777, 30269, 24057, 10605, 82236, 616, 15793, 13919, 47249, 112086, 116698, 9484, 80207, 90574, 33304,
	68624, 93127, 56101, 42210, 160929, 4827, 38995, 38095, 4701, 125119, 5027, 33680, 9236, 231236, 14135, 87837,
	23318, 70261, 78893, 30151, 81482, 14332, 1084, 74256, 27532, 46644, 79185, 3148, 62615, 6981, 55672, 31668,
	36825, 1849, 14536, 37446, 14738, 23779, 43058, 162749, 72199, 1168, 21346, 5592, 85932, 85302, 9668, 18351,
	57135, 150360, 2080, 228015, 77953, 34670, 119302, 151751, 31009, 106725, 84265, 45214, 59289, 74178, 113071, 263206,
	111009, 4021, 44449, 188119, 192629, 123592, 392506, 292847, 114487, 12831, 205858, 9852, 20780, 79648, 75767, 357014,
	97721, 18166, 21005, 67950, 33226, 204009, 16536, 2987, 11335, 66717, 144910, 47950, 17262, 55060, 15063, 2934,
	51038, 26775, 178497, 66008, 3427, 49433, 128592, 20036, 157553, 63861, 3089, 23015, 51210, 28696, 35933, 49942,
	71135, 231518, 99620, 17248, 21835, 176536, 20676, 16944, 38700, 165831, 233253, 295625, 36723, 13023, 52745, 10907,
	19423, 67972, 125868, 95473, 82875, 1183, 108455, 52685, 33417, 64095, 21433, 52438, 33191, 127809, 44505, 211823,
	7810, 2752, 95548, 162031, 7185, 91196, 47563, 61721, 33359, 17897, 23682, 42806, 178101, 22874, 49707, 199897,
	75419, 82456, 8618, 11171, 79712, 116847, 18783, 44190, 46564, 5346, 59046, 95032, 7893, 14916, 3214, 26800,
	24172, 121453, 34362, 10250, 17408, 18888, 4840, 68696, 22831, 13162, 36005, 32512, 14800, 62357, 41723, 45046,
	27247, 37486, 5372, 2564, 34261, 298500, 66509, 133920, 89138, 31305, 117697, 19097, 108304, 81386, 84106, 23802,
	46411, 63304, 946, 51417, 41777, 41041, 19501, 115864, 60743, 294354, 37955, 94165, 18116, 1156, 17937, 20645,
	57114, 90804, 58042, 48643, 92288, 9861, 2557, 88546, 61333, 101008, 12853, 5148, 87856, 4152, 144503, 73841,
	18718, 9789, 147565, 10846, 42085, 12789, 30223, 8993, 56352, 67203, 2448, 28215, 6052, 23540, 126319, 75933,
	36689, 80235, 23231, 23561, 21383, 38800, 77548, 102798, 21234, 31468, 158608, 46188, 63960, 191679, 8051, 67014,
	11185, 170078, 42186, 28827, 34777, 41930, 212079, 12421, 34750, 24111, 110344, 73918, 45171, 70826, 141949, 40063,
	23979, 24254, 37309, 26724, 27179, 24718, 83648, 54938, 14591, 17425, 29525, 102675, 48975, 48654, 12316, 8929,
	60640, 41709, 50168, 63264, 89812, 50716, 48632, 38755, 138583, 160123, 55579, 71829, 24230, 233277, 46322, 39650,
	166388, 34718, 24108, 98252, 7031, 106695, 62498, 18258, 35062, 217827, 78731, 34824, 33354, 19520, 60852, 2432,
	60224, 8587, 2836, 62955, 702, 20227, 42285, 40560, 95592, 62486, 11094, 53035, 143291, 18842, 46177, 77994,
	1770, 9657, 107422, 172915, 32655, 128716, 25886, 25164, 156740, 119928, 165875, 85817, 11007, 89110, 33956, 12652,
	65156, 180266, 8494, 36889, 19958, 20955, 96, 1264, 118288, 135769, 44754, 86671, 5632, 19026, 168220, 289120,
	33569, 93821, 66144, 70635, 7687, 5642, 2714, 55445, 56636, 71545, 184182, 93133, 7332, 37389, 12643, 52315,
	22729, 11014, 158742, 17050, 152889, 50178, 34601, 41945, 52136, 9948, 26914, 63548, 95721, 115951, 40759, 8960,
	158258, 38938, 49232, 48325, 42234, 81523, 253019, 66128, 40978, 20048, 238048, 38760, 62928, 122560, 118532, 43687,
	137472, 163689, 26680, 9878, 17448, 51035, 16211, 60834, 36749, 29178, 14241, 59868, 150086, 2305, 26477, 42422,
	34342, 165341, 83279, 33894, 14257, 29928, 12743, 13957, 125571, 89134, 66712, 10952, 16507, 147839, 30146, 7249,
	16565, 45399, 39874, 114565, 215780, 31990, 230881, 171477, 102, 196546, 44538, 10880, 84948, 281705, 86651, 10617,
	31395, 2342, 453658, 43569, 60561, 132901, 21845, 17727, 58556, 258242, 22262, 58728, 4008, 77997, 11806, 37431,
	30599, 81375, 109137, 185787, 114085, 217292, 97453, 169085, 30593, 60212, 11544, 102056, 65580, 2384, 91655, 4855,
	95725, 7295, 157994, 16228, 20669, 53276, 141590, 105246, 17334, 25440, 76067, 17967, 39321, 38911, 11362, 28559,
	63807, 21627, 26468, 85816, 40120, 1025, 15234, 58319, 69516, 66512, 124548, 75845, 78873, 22137, 46681, 51242,
	85683, 32909, 76747, 35555, 43396, 101465, 1765, 73094, 1077, 2962, 39028, 66777, 57831, 42048, 15828, 13962,
	36041, 63657, 52412, 5242, 58846, 2141, 5506, 219012, 134451, 3936, 182230, 17558, 17153, 152237, 22621, 49377,
	170216, 35257, 68233, 65374, 6510, 11126, 212151, 7184, 2480, 22517, 3437, 33073, 30156, 16557, 3768, 55067,
	86829, 91000, 12350, 148650, 66017, 79424, 70885, 49066, 28250, 21369, 51213, 34533, 11510, 3258, 18176, 18465,
	84413, 6315, 36411, 163765, 4346, 356, 107618, 598, 13727, 285026, 162695, 8749, 14583, 7132, 63521, 184253,
	32378, 25991, 5604, 30961, 53675, 4874, 84693, 5086, 34811, 26978, 56564, 7904, 33519, 51221, 113942, 69253,
	6664, 125563, 22055, 220680, 102008, 742, 51930, 19494, 176108, 44424, 35123, 13025, 75685, 11759, 74335, 22250,
	181453, 131147, 16984, 132115, 154311, 11991, 76452, 52609, 85351, 196, 30969, 9198, 74919, 2529, 56838, 71779,
	29187, 116304, 3504, 62330, 41190, 86153, 28393, 254926, 104228, 105189, 13264, 84359, 3574, 12415, 8534, 57147,
	10175, 188174, 59504, 60932, 66318, 16407, 107921, 17638, 99103, 49278, 28403, 39786, 145865, 8462, 3558, 43406,
	142271, 29139, 21989, 36552, 93955, 72365, 7176, 13556, 106185, 37957, 321774, 17782, 129017, 51154, 27938, 24952,
	1935, 39366, 2791, 33489, 41582, 56078, 24558, 9311, 5449, 218786, 27808, 190429, 68013, 36020, 86003, 29735,
	3404, 87348, 119357, 115714, 2324, 86796, 81973, 40992, 43376, 93621, 28784, 16808, 36367, 2517, 2909, 191926,
	24978, 55303, 53308, 205724, 60068, 3098, 21375, 64784, 23949, 26579, 63121, 12319, 80145, 39967, 97861, 6757,
	70143, 67642, 37082, 34698, 69140, 122883, 46151, 62187, 80934, 429, 19437, 135071, 137885, 222647, 13331, 154065,
	327, 61778, 74257, 40116, 37493, 14855, 85079, 237641, 42342, 102164, 199965, 71204, 4662, 29368, 5042, 113914,
	122214, 8955, 13149, 102503, 43173, 5659, 163787, 69003, 307084, 63392, 171080, 21390, 81918, 86666, 36622, 24126,
	28887, 5736, 28054, 207170, 163428, 79891, 346467, 95363, 38980, 111806, 80828, 9200, 19288, 294896, 114468, 87405,
	111715, 141705, 7015, 72754, 68463, 48738, 243147, 33397, 101210, 37051, 98801, 82847, 20397, 4940, 185559, 18716,
	54718, 83491, 11725, 40803, 1128, 12128, 23060, 5174, 7745, 67007, 46701, 1571, 27807, 180186, 256996, 18975,
	16837, 7877, 212758, 250379, 15440, 87954, 57755, 24719, 124057, 83461, 258, 50864, 8874, 29038, 71289, 31627,
	15429, 9005, 4061, 113851, 107716, 82819, 13651, 79656, 117851, 17539, 111446, 12938, 39724, 190787, 4352, 15402,
	21070, 62708, 8539, 23777, 73853, 13552, 38810, 86117, 16285, 56400, 1718, 75342, 142863, 29033, 378, 110113,
	180321, 32586, 23606, 26393, 160984, 207987, 23783, 8406, 16904, 24596, 47274, 11693, 46539, 60524, 78595, 48423,
	31718, 20170, 9009, 146268, 15183, 191060, 172765, 1349, 138436, 37365, 10970, 40509, 225817, 20021, 70394, 152138,
	21541, 66559, 66544, 89352, 2725, 17258, 91345, 7313, 3815, 115868, 8660, 40362, 4071, 103524, 39388, 118275,
	21950, 6549, 38226, 32754, 209574, 29201, 43495, 18028, 20296, 40597, 18370, 47520, 202450, 24134, 2219, 8195,
	69545, 38041, 136934, 46374, 19041, 159811, 84865, 58620, 846, 98749, 13569, 30714, 97246, 32186, 4479, 27355,
	92973, 35214, 151491, 75963, 37631, 1561, 27200, 238083, 23182, 60756, 12291, 25766, 39355, 102333, 87362, 65741,
	59906, 19538, 201575, 48772, 102938, 24438, 292580, 39964, 66366, 9004, 61379, 50548, 37622, 38732, 28379, 68180,
	76622, 17488, 69849, 5963, 7219, 48143, 43413, 55358, 540, 58691, 29506, 19245, 52193, 48621, 5518, 13048,
	118625, 44755, 191081, 42061, 89197, 2259, 60665, 66994, 71210, 51232, 3585, 142096, 55024, 7892, 8345, 58653,
	463307, 65658, 64319, 137941, 136323, 53499, 12746, 43492, 6978, 95163, 29925, 60175, 5128, 7352, 41463, 184756,
	121146, 20473, 18426, 4598, 5309, 54580, 14277, 121151, 10691, 56711, 43880, 63409, 76682, 11830, 172218, 264898,
	32632, 66536, 81062, 31649, 25788, 92774, 60222, 11100, 63159, 9432, 224657, 25240, 53613, 152, 138620, 163829,
	2397, 85345, 12501, 37507, 64932, 38575, 43522, 65789, 80198, 78796, 35226, 3851, 108891, 73311, 3060, 28391,
	93671, 39663, 46142, 30982, 66041, 37281, 68157, 26553, 71872, 81142, 211527, 39747, 118119, 22695, 2859, 11066,
	20232, 168911, 7933, 197005, 17066, 111071, 44434, 133994, 120798, 12766, 227798, 45756, 132852, 29917, 36076, 55352,
	65281, 129800, 41958, 18944, 84678, 18580, 168093, 132621, 39997, 54092, 27740, 32354, 3770, 114118, 103242, 43918,
	15899, 18574, 145944, 3190, 123469, 219903, 24169, 100571, 62403, 16776, 92779, 14535, 17168, 16475, 14304, 37231,
	1712, 28218, 242754, 61688, 28980, 1318, 51359, 222657, 99200, 67989, 31772, 23932, 35351, 201251, 49041, 27306,
	19128, 40135, 3986, 77333, 19649, 120683, 151927, 21081, 7076, 78375, 77501, 101599, 8011, 89585, 96715, 58179,
	5378, 102138, 106793, 26051, 217276, 4197, 16297, 27014, 46721, 13322, 22806, 5278, 29629, 70632, 9647, 71519,
	58818, 40603, 128530, 8903, 36770, 56900, 31483, 26935, 43845, 34265, 34920, 87658, 6114, 84767, 64250, 47318,
	50720, 19264, 162514, 33357, 13117, 6705, 46696, 75032, 71054, 87004, 42035, 69138, 11903, 99854, 102328, 19611,
	34525, 69312, 6431, 49842, 101600, 133178, 108751, 41829, 89939, 225664, 48916, 99556, 9195, 130387, 5960, 36857,
	116724, 53518, 94002, 39077, 53996, 6945, 22261, 64291, 8314, 152785, 57588, 16522, 9091, 5048, 87671, 35441,
	39509, 1945, 12423, 158923, 178413, 37549, 14095, 1475, 73188, 62878, 4819, 24012, 68534, 42606, 4010, 120809,
	57497, 59564, 101758, 103718, 32701, 80116, 12345, 95834, 46918, 21468, 53213, 15665, 31200, 3867, 5140, 96013,
	250744, 21016, 10069, 13968, 35449, 180829, 27683, 39704, 59956, 22893, 3115, 26293, 32785, 75934, 62445, 141162,
	62720, 2018, 83638, 19949, 114012, 95006, 3330, 99829, 130935, 309272, 9565, 55874, 121727, 37017, 23586, 319858,
	40970, 27602, 8625, 112329, 61060, 100088, 118525, 25922, 16232, 1907, 60671, 51583, 44553, 80993, 5262, 94679,
	8676, 940, 20736, 11823, 3020, 16476, 12340, 152600, 97416, 3703, 25744, 66826, 16245, 16876, 46446, 84798,
	74227, 176020, 45192, 61955, 75496, 23946, 23626, 40372, 26036, 6149, 11822, 30582, 16541, 41914, 82385, 232823,
	40921, 80773, 14930, 3631, 7517, 39619, 4348, 36180, 126106, 138939, 62611, 1477, 113512, 47321, 25052, 14546,
	118881, 29060, 23589, 128322, 36795, 18401, 137921, 104699, 267929, 36194, 172791, 18113, 4766, 188215, 30083, 332586,
	94089, 5805, 77909, 22194, 68234, 154976, 43220, 40660, 70001, 184893, 138095, 11128, 103010, 22663, 5108, 212615,
	8485, 5565, 49222, 54614, 26530, 42639, 16319, 55062, 152662, 105595, 21114, 22216, 10294, 68158, 10436, 86950,
	7206, 62115, 3977, 3657, 59874, 456, 118617, 18156, 106663, 112229, 80992, 17442, 8217, 55551, 5133, 34344,
	251927, 51153, 39364, 201321, 7816, 66803, 23057, 156724, 145664, 14276, 95705, 979, 2796, 6875, 13429, 212525,
	50602, 26276, 28284, 3424, 19465, 52397, 46963, 31420, 51399, 206476, 92317, 48851, 637, 100820, 83349, 10317,
	60227, 21972, 6908, 282439, 32857, 224767, 95629, 83882, 42106, 87338, 69757, 29840, 68709, 37665, 45244, 114577,
	49188, 175943, 54009, 186746, 106158, 70168, 3358, 234002, 50555, 9221, 129338, 9562, 20118, 32923, 78479, 118280,
	65752, 4977, 10474, 102174, 60947, 129006, 10570, 83451, 8598, 8078, 159367, 123785, 80438, 16742, 5905, 5281,
	181513, 42402, 6977, 163136, 93179, 42191, 14968, 50421, 112401, 105440, 33456, 57347, 121611, 4221, 94954, 36517,
	24046, 27796, 6255, 33394, 72990, 135408, 116627, 1233, 57874, 25654, 95419, 68156, 401399, 313338, 55208, 45573,
	93124, 119251, 47200, 38196, 11909, 130667, 45391, 73904, 64964, 167846, 4137, 115606, 52036, 62214, 7969, 160925,
	7187, 1132, 134835, 40309, 73195, 64494, 80472, 444841, 61111, 26500, 45323, 40743, 53625, 52797, 22659, 15631,
	29739, 36706, 28841, 39147, 102836, 26794, 10536, 14845, 87305, 45874, 12241, 127587, 83833, 57183, 79722, 30844,
	41304, 84655, 20825, 92500, 3722, 25655, 27811, 10157, 81634, 31362, 34088, 92487, 70123, 22190, 185100, 72658,
	139035, 192523, 88241, 2078, 230490, 44528, 85638, 100198, 22088, 29982, 291233, 241062, 13865, 4445, 137791, 37835,
	107218, 31726, 19718, 38234, 72528, 23046, 19177, 66695, 5109, 17251, 28077, 5617, 21554, 47839, 72425, 133825,
	1486, 73065, 181275, 141508, 21768, 62971, 63082, 2512, 34200, 9904, 120309, 6392, 91243, 68416, 268253, 41199,
	116757, 138551, 185526, 41246, 28986, 4093, 19057, 17295, 4148, 245766, 122360, 35356, 112075, 20301, 75441, 10998,
	7977, 19769, 62922, 937, 63547, 100196, 26427, 157820, 20983, 236696, 22935, 8140, 90315, 156004, 47204, 140973,
	7726, 45097, 52725, 22636, 23436, 257282, 105247, 522, 88389, 216031, 202204, 46812, 211666, 19693, 68828, 81691,
	45925, 11256, 30292, 372, 5236, 167826, 88328, 232776, 151611, 5360, 82104, 18841, 80393, 25465, 18285, 20320,
	72377, 31730, 33160, 45803, 38715, 27705, 37379, 24163, 18360, 103586, 4015, 32305, 269494, 91252, 20080, 36567,
	54650, 7797, 57073, 12650, 31164, 42209, 6375, 261663, 105528, 81661, 106002, 2800, 5375, 17247, 43151, 4442,
	15727, 194619, 100855, 144898, 62320, 78465, 39929, 16454, 1967, 28311, 61363, 17219, 9395, 8745, 121445, 76939,
	80385, 162380, 22009, 54191, 44248, 16299, 122830, 48151, 74429, 78291, 64755, 14238, 44966, 2511, 17712, 67954,
	93583, 829, 105899, 49935, 84750, 11591, 33185, 85447, 42717, 27409, 208542, 28965, 62052, 52525, 5597, 25694,
	65594, 16343, 63224, 276188, 12475, 9331, 127507, 38522, 57287, 24128, 133161, 79723, 105548, 133695, 48917, 27558,
	43278, 46520, 13778, 141954, 110785, 83366, 17715, 46317, 105763, 66298, 147013, 41086, 94180, 16478, 220447, 44611,
	730, 19722, 78975, 117889, 125643, 26254, 16574, 18480, 65006, 15806, 38549, 246418, 46052, 36056, 8440, 34984,
	30170, 3163, 59800, 4458, 115442, 4283, 41970, 33507, 104078, 1653, 22, 121158, 276486, 3655, 6338, 24048,
	133421, 23641, 2161, 24422, 36006, 8086, 10675, 181474, 12307, 29514, 59143, 14729, 52509, 87128, 122470, 19446,
	80852, 33314, 24573, 119864, 14237, 9652, 57779, 6612, 51851, 15284, 98871, 90581, 124466, 156831, 21190, 22015,
	71380, 161906, 87247, 69201, 18392, 17908, 108470, 72962, 40719, 14338, 17911, 95260, 43339, 20610, 78916, 20710,
	72451, 11315, 31448, 17263, 58853, 178878, 48111, 116002, 45497, 80506, 82605, 85880, 36300, 121755, 25215, 36118,
	301929, 88728, 405223, 276136, 553, 34704, 212438, 49970, 78329, 922, 20711, 25036, 257130, 38295, 145369, 18128,
	15385, 30829, 55656, 48345, 8012, 3561, 28004, 122041, 192900, 58338, 112508, 41085, 29976, 87040, 47117, 23905,
	4336, 92061, 138880, 97407, 42083, 172121, 6256, 25192, 172671, 5, 93568, 1420, 12677, 31605, 56743, 40620,
	6015, 78415, 231077, 31298, 80026, 13902, 19048, 24924, 170586, 32955, 176119, 87859, 36731, 6773, 27711, 24658,
	26475, 115216, 133207, 93250, 95820, 88522, 8317, 5714, 124047, 55219, 86860, 19677, 23961, 22928, 162209, 8904,
	225992, 359835, 56084, 96201, 29392, 96558, 86071, 93643, 55114, 13347, 8183, 95129, 82012, 2017, 123336, 34219,
	115554, 157159, 47747, 101684, 41008, 18735, 193781, 104151, 226906, 7552, 179874, 124113, 31159, 21162, 44010, 14771,
	51268, 166128, 31382, 73124, 77438, 92830, 205709, 12113, 1292, 38937, 13114, 1334, 2118, 15597, 69581, 14449,
	21934, 76618, 48728, 67038, 14967, 51495, 24243, 87736, 147249, 26720, 11119, 46063, 43749, 5843, 44147, 152629,
	133428, 65703, 14269, 45604, 57982, 28672, 55616, 45957, 8438, 95433, 37698, 220862, 132034, 39456, 61870, 4161,
	26501, 73560, 56418, 9845, 4654, 20916, 10456, 88920, 119358, 9015, 65931, 96507, 48029, 38534, 21676, 109081,
	43078, 34943, 25089, 6131, 28766, 23665, 5477, 10255, 16695, 67, 45778, 42443, 42770, 29534, 23733, 100513,
	62617, 42630, 48746, 14191, 43753, 50295, 26007, 8792, 57243, 43119, 54725, 164253, 58250, 112304, 131796, 25165,
	4651, 3188, 24831, 47748, 3705, 19540, 13211, 102095, 5593, 18699, 23666, 32005, 117571, 33541, 60584, 74573,
	86311, 99443, 25172, 27222, 168938, 7143, 11853, 53560, 18834, 19960, 86522, 28217, 53266, 117700, 72989, 34323,
	18721, 66450, 34346, 74056, 47217, 202002, 46269, 9429, 68582, 75458, 37823, 82843, 96652, 32549, 145144, 27958,
	19820, 158086, 31955, 201406, 135379, 31207, 192545, 12950, 51704, 9094, 248263, 76147, 64028, 110009, 79407, 89345,
	99284, 223492, 47966, 26848, 15359, 201137, 2861, 110507, 71231, 72297, 31851, 118777, 71039, 151051, 240855, 16333,
	50766, 14727, 7939, 4149, 80908, 418780, 88378, 59276, 1327, 7284, 38576, 79814, 65820, 42199, 84860, 49574,
	62596, 12396, 70598, 40117, 8648, 7994, 16836, 7630, 14047, 359699, 106878, 525, 29037, 28064, 13380, 11675,
	50669, 74216, 103539, 180314, 27449, 56299, 172344, 19274, 7301, 246099, 32043, 19422, 36506, 129317, 6806, 30140,
	4614, 46639, 66926, 932, 86600, 6322, 27847, 233103, 10541, 39025, 34887, 3517, 12972, 26220, 2031, 66561,
	115015, 48658, 47596, 12714, 33845, 3893, 16165, 35237, 89983, 14769, 11962, 147224, 47018, 29977, 27979, 5552,
	82338, 86023, 131368, 1218, 24853, 237840, 132193, 15455, 40873, 3668, 65351, 53388, 15229, 59889, 272245, 47934,
	11858, 34347, 18038, 90853, 86981, 300602, 19343, 114181, 29362, 84921, 6095, 106059, 79472, 38015, 1206, 48741,
	6208, 80000, 21916, 17423, 6002, 108083, 24479, 34931, 56661, 9511, 26995, 100694, 163853, 35997, 81254, 58321,
	18919, 171890, 86877, 91341, 74503, 70477, 53412, 7027, 59281, 39892, 131302, 5864, 15947, 61301, 67466, 162369,
	47956, 27874, 35624, 282324, 21270, 111847, 102548, 41482, 30955, 116737, 28264, 8592, 55458, 22301, 75090, 29821,
	30697, 51709, 3041, 19208, 8038, 24634, 30467, 87509, 126428, 19389, 18814, 152686, 20701, 83474, 45832, 80891,
	105808, 11378, 153223, 120770, 98186, 150633, 49838, 9141, 12755, 30962, 5260, 74490, 21256, 31678, 65062, 33326,
	289838, 187831, 20595, 89768, 2805, 58535, 10844, 70085, 12090, 2451, 138068, 98544, 24461, 4511, 6754, 41684,
	28203, 3383, 65355, 82833, 30161, 83924, 234361, 128424, 28921, 222594, 33975, 125491, 34069, 11508, 67464, 144226,
	41850, 98703, 34371, 7901, 21254, 38398, 65651, 23549, 53883, 213340, 123269, 12028, 71764, 177701, 28758, 2623,
	68395, 11549, 15232, 68603, 9660, 63116, 36079, 57093, 31198, 20475, 48467, 89984, 35619, 186847, 107469, 31389,
	43631, 73867, 41949, 68841, 114250, 1605, 30564, 63403, 17588, 27680, 99533, 12641, 70325, 50428, 73426, 78379,
	11855, 91651, 72081, 91720, 60198, 15743, 12065, 83398, 140046, 6761, 46598, 45900, 5068, 886, 62448, 148968,
	37347, 19405, 9680, 15819, 43496, 63370, 75667, 163700, 37639, 3633, 22774, 34341, 183131, 134335, 37200, 23915,
	7054, 14194, 12970, 26438, 13350, 285521, 25594, 8219, 104410, 91039, 168804, 138480, 149734, 15907, 33818, 61132,
	60082, 4622, 110187, 56736, 13551, 73571, 3945, 73463, 65498, 17758, 263266, 17593, 2710, 27585, 54469, 38200,
	45367, 63754, 28881, 3473, 12791, 98287, 31895, 65787, 4463, 94536, 24951, 36332, 59901, 28803, 52130, 86403,
	7668, 181822, 74831, 18977, 9850, 177206, 145485, 109798, 7292, 31421, 26280, 77211, 58511, 12507, 127004, 11113,
	147, 8729, 56208, 43066, 79926, 129937, 31345, 83947, 39915, 46146, 98763, 42566, 1337, 13192, 18323, 105163,
	80570, 117753, 16555, 72883, 11077, 159438, 40764, 70933, 83329, 26066, 12276, 72059, 21655, 173836, 126713, 69454,
	153482, 91585, 70644, 102558, 110483, 6764, 127864, 190133, 3961, 101798, 20945, 71138, 82402, 90884, 69669, 44753,
	923, 16939, 59700, 164258, 25969, 27082, 31399, 43846, 6306, 246093, 51342, 6153, 151581, 202801, 182731, 56475,
	162188, 89426, 141356, 14355, 121815, 27536, 28023, 65257, 77523, 106668, 127314, 24947, 12790, 38796, 169698, 23555,
	10725, 44573, 183083, 42088, 62716, 43265, 105958, 32050, 44067, 50118, 1668, 3874, 6243, 318411, 16599, 1691,
	94999, 52378, 28671, 216728, 123258, 2059, 34969, 69225, 5913, 136280, 171443, 141515, 91662, 22175, 135282, 80020,
	92270, 1663, 4808, 4482, 3495, 34691, 5226, 109830, 108512, 17342, 107488, 11606, 123190, 100247, 29666, 146527,
	113014, 15794, 30894, 13224, 39585, 243192, 22351, 9903, 7836, 47699, 11078, 25468, 122291, 48821, 26780, 122679,
	75521, 81450, 630, 4895, 92900, 55074, 74293, 17441, 3563, 111657, 103102, 51613, 12318, 52370, 36191, 68245,
	34269, 40445, 41354, 122901, 168604, 182500, 62012, 42557, 11259, 24428, 115113, 86345, 12362, 3909, 78430, 86852,
	134602, 20459, 47853, 93879, 22577, 7659, 3688, 38555, 13349, 17381, 56715, 91639, 12493, 10895, 92438, 3142,
	37057, 28928, 2004, 36427, 32268, 34222, 209974, 10432, 67436, 41989, 173518, 107930, 27079, 62729, 30908, 55558,
	5828, 45031, 14902, 53546, 8204, 144263, 60255, 14520, 88212, 86582, 109589, 69356, 8064, 47449, 8505, 66558,
	16886, 4844, 52817, 111260, 215129, 12941, 91118, 650, 20770, 6273, 73089, 40618, 62790, 2873, 35002, 14023,
	97208, 19386, 102646, 36993, 143736, 135457, 35385, 113601, 17893, 32627, 84439, 100619, 56016, 6581, 57264, 172160,
	45452, 111710, 203627, 70131, 24100, 322787, 1996, 35665, 70078, 22358, 90922, 83658, 4097, 63200, 58499, 14542,
	99153, 52159, 6615, 12414, 63415, 31986, 16823, 1579, 65405, 137809, 8841, 16898, 48082, 259, 33014, 42375,
	12260, 179850, 73667, 91389, 98882, 29532, 17311, 326251, 41092, 5928, 20742, 44964, 48019, 43505, 9317, 49265,
	6643, 192712, 48424, 163487, 19861, 20113, 70848, 31928, 105333, 23685, 78563, 14638, 54755, 7158, 24142, 44018,
	20774, 125255, 20331, 24280, 10163, 1285, 2336, 39851, 4299, 117269, 46714, 63816, 87779, 159624, 11731, 9971,
	990, 137317, 108831, 50994, 74554, 162680, 23640, 131597, 146962, 170620, 34829, 91205, 21184, 1913, 63616, 18427,
	93136, 156592, 17519, 67565, 115882, 138220, 78622, 88535, 18115, 2711, 33554, 109492, 54298, 971, 24914, 25863,
	36363, 45715, 27099, 194995, 14299, 178181, 111488, 72395, 322385, 157719, 130787, 11897, 81843, 83999, 11369, 49280,
	118604, 40922, 61332, 110343, 53407, 75639, 40582, 300440, 54722, 25637, 13694, 48248, 48278, 194521, 56203, 52779,
	48783, 72627, 10953, 376, 16733, 280238, 26351, 230789, 15132, 25168, 137270, 3588, 63704, 73376, 94031, 74284,
	19443, 159557, 9697, 39901, 13351, 119050, 15406, 146455, 3460, 29556, 75195, 37673, 102524, 92329, 47289, 98413,
	15311, 100684, 56345, 7116, 95480, 11590, 7200, 167, 23610, 58426, 17730, 136656, 27944, 53151, 2701, 8824,
	103124, 3017, 90744, 113588, 53216, 79736, 65940, 26931, 498, 29568, 80540, 143543, 21292, 1740, 59268, 16561,
	180816, 42323, 50174, 40890, 52866, 10703, 57169, 4700, 17191, 4424, 93511, 49698, 166650, 26972, 48631, 165169,
	82879, 69326, 202970, 4007, 2376, 231325, 139592, 22119, 62851, 37504, 68816, 58345, 67398, 186643, 43331, 277416,
	53749, 15746, 23102, 17432, 4793, 151138, 48822, 54265, 48203, 198688, 14305, 54287, 2291, 18018, 113378, 123260,
	7180, 97549, 87027, 120085, 2920, 76080, 8190, 102005, 5641, 64580, 14955, 59802, 54028, 58884, 19367, 81779,
	412567, 85957, 97053, 103637, 78871, 29364, 27637, 141728, 4767, 30686, 112738, 130146, 42745, 12730, 105040, 14844,
	232, 210944, 36581, 152317, 135543, 29744, 3129, 55647, 58149, 46319, 27265, 17499, 28005, 59948, 7170, 34138,
	5702, 293047, 110892, 408, 91760, 218674, 18469, 46095, 81403, 14389, 4610, 35672, 73060, 11006, 74848, 104820,
	118143, 190357, 20043, 105358, 141735, 5115, 27093, 45924, 123073, 52599, 29433, 9616, 238350, 78610, 24851, 58858,
	26769, 31969, 24613, 18294, 4982, 32735, 39639, 143563, 112073, 202205, 12567, 4873, 88601, 44897, 81503, 101648,
	81362, 34662, 85277, 17574, 48173, 21435, 221188, 40215, 39576, 80786, 26544, 64668, 81841, 10731, 37733, 247986,
	149188, 127703, 495, 18382, 54388, 72446, 43071, 30974, 198723, 89608, 41360, 190, 33045, 8386, 31658, 19992,
	237838, 119015, 137622, 50890, 100913, 6460, 116233, 267230, 26621, 104129, 65114, 14190, 41542, 14888, 85962, 23342,
	23041, 26453, 43725, 71809, 45186, 4770, 46452, 53894, 56616, 221286, 18973, 9038, 109299, 55365, 19366, 26863,
	18808, 60909, 69353, 41738, 83463, 12100, 68561, 72860, 3980, 13796, 49340, 12332, 31311, 27418, 4255, 53430,
	18976, 45523, 510, 14224, 30477, 26581, 4530, 3651, 101663, 139840, 22709, 150861, 31996, 63923, 120623, 262522,
	3076, 10528, 2929, 14672, 130238, 18087, 9816, 121894, 100308, 25085, 55111, 14565, 18952, 53293, 2042, 369988,
	23674, 61789, 133529, 28783, 108293, 35477, 47119, 36448, 71049, 40015, 33055, 78598, 198442, 1833, 159937, 40654,
	77444, 189245, 113153, 8621, 18599, 38553, 35223, 166072, 2375, 11659, 21786, 89523, 6032, 12116, 63046, 159398,
	18454, 3678, 32521, 47626, 11411, 103527, 38896, 42946, 15696, 26370, 10185, 8413, 37080, 165583, 4331, 63555,
	14907, 72220, 50056, 6623, 62236, 36565, 49783, 10049, 17503, 100581, 55951, 146244, 24724, 9626, 17969, 25524,
	109300, 173965, 99994, 101056, 46459, 43647, 53737, 277968, 8347, 123521, 74858, 33829, 44762, 77574, 877, 81377,
	222525, 123532, 30602, 43881, 53145, 2973, 16284, 81940, 61281, 127044, 63620, 9875, 14756, 114829, 19032, 9202,
	52759, 119141, 23928, 120551, 19607, 3599, 33401, 76821, 73233, 117430, 39968, 36539, 7071, 5446, 121735, 194059,
	15206, 45283, 6706, 15603, 65615, 1207, 165723, 92275, 34773, 104447, 8396, 32353, 205240, 164323, 13600, 60555,
	79205, 25532, 22907, 33410, 57480, 107111, 69630, 32137, 47832, 70913, 33161, 20321, 2371, 117348, 10714, 86246,
	1625, 11763, 17900, 268, 78457, 99175, 97940, 101092, 86660, 32221, 14041, 128504, 125080, 53744, 124263, 31017,
	13897, 403, 31859, 21964, 5633, 111630, 5547, 77329, 17961, 18241, 84995, 25984, 12983, 67491, 62168, 47262,
	5241, 297, 51191, 7351, 8967, 147212, 82060, 16821, 782, 11033, 82431, 62957, 5026, 43459, 77963, 203477,
	53528, 6247, 191852, 87774, 74164, 215654, 13467, 1522, 219964, 28589, 244104, 16242, 117821, 67725, 72570, 156792,
	17186, 15979, 26990, 44128, 193014, 35276, 57125, 16212, 166451, 68017, 6905, 77608, 16364, 53777, 75921, 76426,
	37975, 26203, 269296, 64099, 84122, 12077, 38533, 830, 4407, 20139, 963, 43028, 38902, 42911, 37503, 83343,
	85045, 16979, 1165, 60835, 137387, 58380, 86990, 110066, 134540, 56331, 193845, 81238, 17922, 163093, 38744, 110641,
	12502, 56404, 34862, 26865, 125964, 12965, 111648, 25547, 7771, 27196, 136980, 9555, 29551, 107158, 57885, 18831,
	37705, 35505, 101742, 13970, 102109, 62548, 124657, 23328, 11124, 89592, 146376, 248050, 6241, 22033, 18337, 80685,
	29898, 11908, 216623, 67721, 106162, 146610, 21377, 15085, 91552, 42041, 62560, 122532, 125336, 102365, 121537, 142559,
	29693, 223919, 11515, 110495, 18776, 22494, 5895, 185059, 103592, 229351, 51220, 100102, 37027, 257855, 29359, 54123,
	36066, 106493, 12244, 79258, 32002, 432, 56205, 94836, 90182, 6726, 14762, 29391, 48938, 26864, 38083, 60364,
	3310, 60192, 14766, 205567, 57504, 110760, 22649, 24666, 46333, 21517, 3430, 13135, 28873, 27052, 158809, 11597,
	20529, 6695, 23138, 22960, 37137, 45574, 6545, 305877, 43423, 26153, 24769, 59844, 14501, 10430, 134352, 56169,
	13213, 103432, 49523, 35181, 13435, 12408, 129475, 64620, 230854, 77390, 51990, 15653, 83248, 33466, 44571, 117828,
	51481, 2187, 10559, 68019, 18021, 54895, 48247, 18354, 33737, 4554, 108595, 37288, 39767, 116707, 9175, 3726,
	108877, 21616, 83684, 49862, 1938, 8543, 276466, 20134, 108498, 48770, 102254, 31914, 131520, 185291, 100559, 51890,
	209, 19526, 76471, 50544, 71814, 99351, 8172, 198526, 28816, 20419, 9109, 98389, 136777, 76479, 75596, 30635,
	165417, 48216, 120220, 25955, 211071, 39314, 24308, 32164, 2559, 146280, 43403, 9233, 17947, 90585, 1786, 86920,
	125662, 2457, 64741, 32152, 32918, 122882, 78538, 44001, 31723, 56426, 23375, 103172, 88177, 145697, 52506, 49319,
	68016, 31664, 41488, 18486, 110400, 7030, 28241, 986, 109199, 19900, 42147, 56864, 65287, 49183, 7858, 24000,
	30453, 840, 16673, 25907, 68916, 89927, 6309, 158335, 36407, 199737, 130464, 13137, 59603, 201778, 195292, 21015,
	42466, 179062, 172561, 89492, 11075, 180407, 31868, 72493, 20998, 60217, 9865, 19530, 39274, 130266, 54539, 21623,
	12535, 13505, 40641, 73375, 4087, 85633, 2153, 3117, 70680, 55788, 92096, 47509, 98493, 37490, 271936, 151475,
	3032, 16171, 96642, 34106, 78425, 125761, 19591, 3366, 19316, 54508, 24183, 50786, 194248, 91528, 33253, 34622,
	108355, 41741, 705, 3814, 3883, 108929, 13203, 67831, 10142, 59754, 68208, 29128, 84820, 56880, 38794, 24972,
	48571, 40821, 40476, 18137, 164254, 24064, 236309, 79181, 11282, 395, 39169, 2013, 51587, 28551, 9645, 701,
	109513, 115899, 113566, 12762, 62045, 58322, 103726, 41343, 40866, 244102, 143816, 2490, 70346, 40973, 52618, 15412,
	30720, 104315, 38917, 42027, 93676, 17513, 107418, 20706, 123890, 13399, 97727, 24044, 87962, 65606, 44250, 98044,
	65276, 74790, 101473, 19350, 91570, 1326, 87790, 172042, 7577, 100813, 86896, 85891, 41512, 108130, 27794, 14875,
	71431, 12835, 156250, 58135, 3759, 22476, 42176, 115873, 34686, 56523, 73643, 108505, 51491, 20838, 12721, 32863,
	45700, 29496, 13700, 34294, 55360, 29206, 155942, 123812, 7706, 163234, 203, 132720, 49358, 144431, 8130, 175788,
	35818, 3270, 76832, 25710, 54095, 97274, 28779, 94621, 74396, 19092, 128242, 58067, 20885, 14670, 93255, 15107,
	63291, 23654, 126900, 129421, 59294, 262659, 9798, 3251, 67344, 28600, 44629, 50672, 29072, 26999, 31526, 23183,
	49175, 165843, 175455, 17282, 175411, 32022, 45989, 30298, 90690, 78118, 83156, 23749, 35636, 31317, 7069, 80381,
	94561, 133756, 14960, 97404, 6138, 41065, 78041, 32843, 16601, 34123, 9559, 146529, 123377, 96395, 54441, 42012,
	84257, 123541, 10745, 22139, 106459, 11720, 150883, 172651, 154996, 110538, 4728, 53447, 25704, 2009, 71152, 119354,
	21166, 66604, 1429, 216162, 8637, 122250, 63520, 27180, 29172, 36124, 276428, 107787, 77184, 4680, 14952, 104903,
	24418, 14793, 51561, 52931, 8371, 26342, 48526, 7118, 92066, 67280, 40653, 8847, 34597, 105438, 14198, 50163,
	61188, 146286, 50315, 41205, 170829, 161496, 585, 197359, 95056, 1687, 365794, 91349, 48507, 5804, 49263, 5146,
	104902, 96365, 117343, 132222, 46084, 96919, 16875, 8073, 262381, 79982, 52663, 13928, 16056, 153908, 15145, 109256,
	132308, 18763, 24904, 167644, 13618, 40750, 18686, 147124, 114709, 150038, 52849, 2938, 12568, 48617, 8778, 5459,
	44202, 44591, 74914, 17183, 248689, 13878, 7822, 80060, 23116, 194037, 18487, 2067, 7798, 43077, 33678, 244028,
	31320, 74273, 2794, 19466, 8218, 36280, 183997, 48124, 19416, 29656, 19280, 98734, 7715, 18311, 30701, 133602,
	150307, 126956, 7378, 2933, 79903, 13178, 12593, 86571, 26604, 92446, 13574, 44205, 65699, 427599, 21118, 8245,
	14407, 27877, 47936, 33542, 7916, 26460, 117762, 21596, 37818, 2249, 127359, 209394, 60044, 47677, 308089, 36791,
	154971, 31417, 6998, 150042, 174360, 12255, 43009, 29335, 48739, 3912, 101398, 53340, 2580, 146939, 151295, 45360,
	125275, 15273, 45383, 27456, 48761, 23314, 8750, 60801, 85823, 104759, 27894, 123685, 66968, 39480, 26917, 55290,
	83305, 2696, 98390, 57569, 145853, 340733, 4919, 20024, 52268, 30884, 7413, 203685, 70989, 112855, 4129, 50536,
	349518, 68205, 332641, 159581, 135361, 236026, 37563, 176404, 64899, 6578, 122033, 63871, 1850, 85234, 82089, 66124,
	74145, 121098, 107351, 12687, 36881, 117334, 13136, 14698, 85933, 93866, 18047, 32620, 310, 15094, 46000, 88451,
	23632, 36645, 27940, 87618, 80520, 58892, 20976, 27702, 140090, 96075, 67841, 103292, 238964, 87778, 107338, 17019,
	83427, 67522, 7302, 8261, 47570, 116787, 8730, 80484, 61772, 174422, 56005, 131193, 52875, 14588, 28471, 59817,
	9586, 15720, 158155, 51307, 109734, 15196, 11025, 59331, 3884, 52626, 102602, 84797, 25158, 27314, 4437, 20488,
	76214, 189248, 35023, 114952, 157376, 2827, 62439, 102878, 129749, 36405, 10329, 109339, 108633, 36662, 1254, 13267,
	5470, 87105, 58004, 15397, 10434, 159667, 21864, 52022, 179464, 3013, 32147, 31496, 116832, 18494, 105502, 129227,
	107267, 50033, 13481, 9954, 24267, 22141, 16257, 116154, 36185, 950, 115685, 11305, 176708, 2048, 178671, 112573,
	287867, 162328, 497663, 95170, 50979, 193861, 50987, 30368, 136257, 31830, 46549, 15119, 169876, 23788, 17462, 249887,
	57377, 1949, 35448, 14791, 43769, 210091, 3783, 34612, 282103, 88380, 245190, 5457, 20491, 98908, 11402, 86899,
	117916, 16028, 162584, 60644, 320177, 156096, 31065, 55876, 22000, 77655, 9992, 23397, 13757, 317623, 63978, 215255,
	2443, 17648, 93231, 27388, 104529, 93807, 55505, 140477, 12046, 112040, 70887, 40152, 94365, 112353, 25063, 114679,
	266061, 71248, 119555, 15589, 2244, 617, 14129, 211431, 70110, 100652, 7777, 4383, 85911, 89221, 21010, 120615,
	58357, 86405, 37554, 41647, 18, 15143, 69662, 60491, 14714, 186134, 148344, 42347, 5410, 168175, 44535, 42449,
	343894, 129417, 99682, 20659, 27272, 140483, 63455, 222159, 17536, 13722, 42637, 62324, 11976, 114691, 148109, 2283,
	32057, 182393, 4295, 147364, 33705, 2075, 44303, 30274, 28331, 63740, 69740, 29148, 10346, 44862, 33716, 73937,
	153333, 12930, 38784, 247159, 2515, 41053, 20256, 83368, 256189, 54639, 115240, 5096, 24661, 175419, 153552, 26516,
	141, 138176, 63885, 34115, 47222, 55709, 2765, 28479, 38875, 236608, 12229, 22921, 77291, 54426, 45388, 2860,
	57787, 114579, 295139, 105782, 17826, 71066, 19119, 54364, 69385, 16568, 12323, 28057, 33346, 34919, 124763, 155533,
	101386, 31644, 8627, 49001, 303600, 29868, 63213, 9103, 77280, 71333, 9696, 138789, 37059, 24823, 5057, 21352,
	32368, 114208, 56803, 19424, 10445, 58514, 8661, 209508, 26187, 171838, 10460, 63454, 14016, 122504, 41328, 21329,
	46618, 32493, 38225, 7855, 31763, 7945, 29876, 8734, 6438, 24205, 97490, 139977, 130740, 47323, 33195, 85390,
	57194, 13813, 60600, 21313, 96251, 7699, 27584, 170521, 139271, 1363, 4402, 336738, 129223, 84983, 69150, 13147,
	3590, 163929, 207225, 155260, 55916, 20288, 4503, 8398, 98490, 11773, 27512, 37113, 84976, 86558, 28365, 11756,
	116005, 182148, 13733, 115313, 47644, 67208, 85069, 9347, 14995, 226141, 14704, 101835, 41159, 35314, 13113, 63526,
	214039, 29978, 50446, 83339, 17440, 129441, 72522, 118641, 97816, 24907, 73844, 15717, 118884, 167255, 96509, 162793,
	30847, 36849, 51297, 78974, 77793, 10427, 1873, 2972, 9999, 35074, 28190, 64297, 146836, 46298, 60038, 163007,
	108919, 61219, 2403, 75022, 127339, 4233, 110389, 69022, 9833, 128097, 88016, 79390, 222936, 22570, 94657, 28462,
	56956, 38803, 81536, 30474, 152794, 19566, 16481, 147408, 74574, 81895, 20731, 1918, 1366, 76367, 187321, 54494,
	24366, 21690, 61696, 33283, 107477, 77499, 31112, 414383, 74362, 18463, 218441, 120929, 59848, 258629, 201924, 69269,
	454, 19989, 13054, 59894, 3623, 58908, 20681, 35723, 78523, 102680, 38988, 184112, 108087, 50944, 132704, 52966,
	21699, 18860, 96349, 201411, 82697, 85395, 95658, 5093, 6427, 177894, 44191, 32755, 26961, 155739, 6249, 31310,
	81030, 26574, 84311, 120155, 86730, 113535, 7424, 48888, 13516, 45747, 98098, 20077, 183995, 81945, 43210, 26704,
	40420, 75831, 45648, 11180, 6855, 57927, 65528, 124096, 34851, 2598, 156633, 107572, 127352, 38169, 123845, 60142,
	62722, 105584, 232364, 23211, 68120, 1601, 22169, 89299, 747, 258039, 80572, 7258, 152249, 11862, 101204, 8834,
	121434, 33761, 19175, 133142, 46343, 40178, 48723, 3589, 41977, 30210, 38868, 62257, 10087, 82658, 87827, 90646,
	16415, 47552, 351723, 28298, 72225, 91146, 272760, 1701, 11295, 1652, 109651, 300747, 51863, 198800, 29446, 11794,
	32345, 37538, 22356, 33102, 37590, 113544, 37970, 11478, 179743, 25454, 103417, 59905, 221970, 105196, 145604, 7817,
	164809, 102360, 16974, 75840, 255333, 56902, 6659, 1954, 645, 59400, 67769, 7689, 18675, 5215, 13793, 20536,
	27852, 3387, 29523, 259718, 16860, 94625, 43143, 29245, 15848, 233581, 22685, 63631, 78557, 22836, 133302, 84513,
	1348, 51826, 47129, 98836, 58284, 1830, 1749, 94642, 10933, 6145, 12506, 10975, 13879, 103781, 144434, 10268,
	28409, 32346, 52968, 121567, 107374, 77268, 23686, 35097, 10501, 155275, 15303, 47136, 21102, 168741, 55332, 90385,
	15996, 84817, 681, 137803, 25054, 142275, 6163, 38175, 8056, 124296, 240642, 65621, 4934, 178205, 16101, 62803,
	60964, 18230, 100622, 76465, 44689, 14545, 9543, 47514, 16852, 93380, 28048, 12047, 107106, 37575, 101485, 77047,
	57326, 34819, 96137, 76916, 6469, 46264, 115983, 75768, 87668, 69942, 13027, 165, 8373, 114231, 26434, 52844,
	42799, 182044, 23580, 146254, 38081, 43236, 33883, 146220, 382894, 14606, 46035, 36481, 166621, 35417, 95382, 2957,
	59384, 60428, 36358, 66343, 75378, 22267, 22950, 83528, 17577, 56474, 25285, 4619, 179691, 75355, 95836, 53295,
	34588, 171410, 4487, 14679, 84208, 44015, 18562, 109133, 54101, 11531, 86052, 174479, 303157, 28095, 9953, 35642,
	14564, 39802, 16145, 77606, 117406, 53038, 121117, 53624, 22062, 1212, 7632, 127157, 237292, 189087, 10478, 127345,
	102515, 181997, 86752, 87623, 10966, 121602, 68783, 68681, 83042, 114380, 138349, 191305, 67176, 50085, 39016, 1427,
	42384, 1412, 67118, 122616, 72389, 25260, 2237, 13576, 137346, 19938, 20304, 2191, 68759, 5373, 61364, 238507,
	75814, 23931, 69565, 38993, 131741, 38364, 12528, 87762, 5679, 129853, 5310, 186831, 32653, 90338, 260176, 389531,
	108118, 26843, 43985, 50175, 30563, 25106, 56965, 18130, 140428, 4542, 165503, 117991, 24219, 229605, 1819, 129663,
	1240, 3797, 76093, 18398, 71339, 51919, 93043, 27175, 47060, 216257, 6483, 35051, 1217, 16512, 80798, 129064,
	13225, 69339, 8548, 237079, 72298, 2575, 34280, 51379, 117910, 55671, 53345, 247552, 29486, 39328, 140821, 34681,
	57045, 60177, 5004, 90269, 78522, 2479, 322607, 48474, 61296, 13057, 31558, 4678, 59271, 6699, 27044, 31988,
	35944, 12503, 83480, 4389, 136508, 3781, 114121, 70279, 4488, 155829, 42214, 2898, 68191, 75695, 305850, 45041,
	74344, 106509, 30087, 17429, 93292, 12477, 290, 23080, 114802, 35714, 18751, 26554, 105424, 17775, 2144, 2412,
	100610, 65192, 113975, 52975, 180272, 135050, 129815, 76238, 106483, 21440, 63186, 4260, 46189, 9711, 28249, 4169,
	23429, 23390, 8324, 141585, 63809, 67668, 38457, 38063, 39226, 59972, 1189, 203916, 62368, 14403, 16949, 61767,
	85801, 1739, 40147, 35049, 76757, 33124, 62102, 15780, 103593, 103009, 53484, 22952, 67973, 114645, 6566, 5245,
	50462, 7601, 8288, 3513, 194571, 80276, 1908, 54592, 5124, 58571, 2513, 6800, 273997, 193904, 1119, 17991,
	117245, 2508, 129156, 82366, 26278, 71465, 63341, 56943, 39662, 106116, 94966, 156875, 9736, 2204, 122308, 94418,
	27134, 1280, 24539, 49022, 45314, 3764, 50904, 46424, 30699, 28087, 293839, 9400, 33646, 40165, 822, 147499,
	50263, 116179, 29085, 11863, 31314, 5578, 17797, 5104, 12454, 1604, 15342, 219206, 10232, 67800, 94261, 25872,
	13565, 90339, 78971, 75377, 26649, 41184, 47695, 11514, 35369, 20767, 14227, 41953, 309396, 148270, 147938, 33074,
	14453, 27499, 109019, 39018, 25738, 240196, 158931, 52820, 8612, 95853, 21524, 137010, 84901, 70869, 70021, 116794,
	48404, 38771, 6732, 1070, 70990, 187297, 49140, 5238, 576, 3564, 253975, 16027, 16483, 2811, 37775, 19034,
	25259, 4053, 2000, 70083, 95774, 19713, 33431, 92703, 91314, 42381, 288770, 48194, 95985, 3991, 77418, 13406,
	241328, 245086, 56533, 35275, 62725, 9246, 51924, 70181, 95331, 16163, 31410, 79016, 39312, 120878, 119371, 275987,
	80124, 27712, 9186, 220, 23598, 146167, 85209, 68238, 282190, 57048, 31273, 30555, 80913, 17594, 75779, 59160,
	135002, 101219, 189377, 29225, 96735, 60126, 62522, 104000, 27620, 86814, 17240, 147533, 11001, 5425, 43682, 410,
	49460, 87270, 69480, 46315, 59448, 1816, 76201, 9431, 11788, 87960, 29063, 65539, 47347, 11678, 33846, 7008,
	196704, 9895, 6753, 8633, 120892, 59970, 572824, 115934, 6646, 202559, 892, 48351, 37611, 251282, 57823, 67263,
	57750, 26527, 34485, 90747, 7685, 88370, 6144, 64182, 1709, 41969, 21458, 62327, 181657, 49247, 225330, 122600,
	114574, 107124, 85361, 111833, 63243, 71420, 15655, 191178, 72430, 18063, 51425, 54002, 12364, 53225, 86557, 18193,
	97580, 41232, 138398, 67821, 128724, 8944, 233212, 101353, 52099, 42127, 14006, 120107, 32789, 32132, 3498, 18123,
	33758, 56058, 5779, 128760, 59888, 98869, 18445, 84702, 51911, 13234, 218379, 20093, 39031, 8074, 70195, 20708,
	23462, 24355, 131384, 60189, 26390, 10403, 41060, 7140, 10781, 49410, 42261, 87202, 82566, 41663, 43105, 60276,
	2768, 5733, 74176, 28329, 2297, 145430, 131632, 83615, 122915, 105441, 655, 224102, 5284, 136426, 67763, 16294,
	188511, 32538, 61049, 27893, 3394, 13951, 159099, 28542, 17930, 145360, 9492, 190122, 32285, 78855, 26440, 13570,
	58648, 73908, 4239, 124561, 2444, 74172, 53131, 11468, 10794, 73566, 11623, 35343, 64710, 30481, 4163, 10328,
	38309, 29901, 10538, 154377, 76132, 92405, 24839, 11679, 3465, 13449, 11637, 7824, 2337, 57754, 1260, 14458,
	41118, 19878, 38661, 13416, 159180, 37074, 163164, 54137, 28627, 52134, 184900, 8520, 40385, 29546, 30502, 22386,
	66527, 107458, 6850, 24022, 47983, 30603, 35083, 8934, 304066, 39500, 9, 28261, 33026, 77251, 9374, 44833,
	116312, 34990, 29236, 63563, 125639, 135405, 165398, 159055, 55690, 88141, 69643, 236964, 31983, 25572, 20436, 36746,
	60896, 31850, 16179, 11828, 5888, 3043, 66368, 9750, 31167, 7915, 53111, 36430, 1333, 64344, 93659, 20061,
	60596, 180191, 51630, 6792, 30244, 43509, 101058, 22409, 420, 44210, 109783, 43223, 27030, 72477, 72831, 32679,
	29235, 7675, 47556, 12258, 39907, 149412, 84926, 118247, 24692, 71717, 105038, 86009, 45941, 41189, 89453, 29856,
	52543, 30627, 226798, 67303, 59230, 67415, 34408, 1367, 99685, 16867, 128419, 52147, 4111, 125381, 117881, 16173,
	44093, 102224, 31575, 23234, 24870, 83790, 127407, 239098, 3200, 994, 1255, 100903, 242275, 117266, 55116, 38205,
	16140, 29662, 11307, 40414, 208793, 123355, 56470, 4862, 75600, 30119, 58218, 70828, 24075, 26974, 7802, 192353,
	4851, 5475, 78720, 66596, 3409, 28573, 64396, 30381, 30690, 59859, 88256, 5406, 99945, 103064, 34463, 37727,
	24238, 86643, 60088, 4057, 23741, 5967, 162904, 38240, 28356, 93858, 25510, 122879, 6897, 3278, 7057, 11971,
	4400, 35461, 211413, 21395, 59615, 39471, 87233, 55795, 128426, 3051, 22470, 41950, 14705, 3974, 180108, 80476,
	78442, 204996, 91987, 15634, 67610, 139015, 142373, 35611, 51134, 10387, 4353, 153456, 57749, 181039, 14183, 68447,
	151532, 21107, 36452, 20551, 3186, 46247, 46383, 129666, 88736, 140662, 146243, 2066, 8360, 7978, 64818, 106963,
	17896, 47801, 10723, 114821, 223295, 74192, 3293, 3393, 16987, 74064, 11277, 91622, 4270, 29828, 27951, 387869,
	103235, 1374, 61988, 120083, 477, 145892, 128378, 11779, 211263, 61354, 18221, 17869, 46530, 83061, 108538, 157981,
	90608, 67199, 95080, 49064, 195814, 12302, 66307, 10348, 231346, 160732, 112859, 63633, 146558, 21271, 31037, 198802,
	47622, 12862, 95710, 3910, 77850, 73961, 85585, 34752, 61000, 4082, 24595, 103679, 71107, 8208, 79568, 150019,
	16615, 24961, 139857, 32664, 197366, 4559, 54735, 32696, 4126, 162019, 75698, 13916, 70108, 159638, 19834, 9349,
	24675, 175560, 49643, 18206, 52459, 27992, 10809, 88865, 401975, 133172, 29000, 34558, 30915, 3658, 25834, 42430,
	36562, 125265, 18182, 10155, 40149, 97082, 208980, 19575, 60853, 90529, 66545, 9600, 789, 46420, 2317, 88593,
	55595, 98980, 115302, 5742, 169155, 1073, 177901, 3472, 11189, 63711, 78643, 65472, 50459, 127979, 93, 42202,
	67053, 21720, 157650, 11145, 141378, 42033, 22824, 85705, 79114, 35584, 15974, 1510, 54172, 28562, 12451, 104226,
	19190, 97151, 73024, 20948, 5151, 81741, 21499, 29006, 84183, 198074, 54003, 45120, 170125, 26240, 35177, 28389,
	64863, 79974, 60778, 176915, 232183, 45342, 2038, 80253, 41564, 40703, 32689, 5430, 100689, 5366, 23007, 134279,
	14266, 26712, 73993, 24934, 64242, 52113, 102887, 61801, 46415, 201049, 54251, 62133, 122757, 164883, 30815, 139966,
	2319, 30842, 766, 13362, 10287, 134518, 86111, 81665, 82440, 28333, 43019, 18963, 8804, 161944, 23439, 102144,
	101145, 80029, 39052, 248708, 30350, 117340, 11878, 128467, 974, 138625, 63961, 5237, 74778, 61834, 67040, 43814,
	13690, 65947, 33809, 232476, 115258, 181745, 28824, 94013, 9510, 10246, 93722, 81976, 7217, 114383, 3493, 16014,
	69045, 72692, 12145, 80981, 9507, 6692, 1620, 60820, 330444, 35474, 33962, 4797, 7053, 295463, 46445, 27026,
	12491, 77988, 49524, 35675, 90947, 29114, 166705, 101385, 133782, 32704, 6186, 84595, 176031, 185623, 45966, 151302,
	63069, 1699, 107491, 947, 15458, 74452, 196212, 6046, 10498, 12163, 10239, 35191, 243951, 9277, 9090, 29539,
	54460, 22820, 26514, 112549, 60372, 51753, 48756, 21812, 70861, 260326, 41, 44222, 10441, 16961, 48148, 138771,
	216194, 5914, 52153, 53400, 212036, 56519, 26245, 10117, 45888, 15294, 138019, 90913, 26368, 43842, 42111, 23348,
	6082, 194845, 161089, 156206, 51546, 11647, 30759, 302912, 262094, 8635, 78876, 26535, 35283, 54183, 31183, 85484,
	147873, 12989, 5197, 6356, 72894, 65347, 20150, 27370, 73787, 1493, 45918, 12366, 190217, 20724, 13858, 10981,
	67449, 81213, 7553, 14115, 72242, 271517, 11842, 48310, 88743, 143726, 22177, 3290, 243231, 58452, 62937, 12592,
	1654, 40066, 33477, 13751, 9921, 128442, 15868, 7106, 75236, 83773, 10775, 36938, 10482, 170465, 17368, 17469,
	161508, 32752, 98340, 800, 19824, 264456, 3901, 87319, 2867, 26782, 9630, 113102, 185815, 24197, 44584, 86366,
	40224, 3636, 140916, 31731, 267731, 9567, 53678, 72984, 29389, 27963, 17106, 50282, 284911, 60170, 8322, 12608,
	23374, 89652, 5268, 39044, 229766, 8869, 151350, 31436, 177342, 12269, 183212, 120418, 116270, 2843, 78888, 69192,
	7865, 184099, 1086, 129897, 18383, 70508, 20242, 18508, 229924, 124569, 35749, 50589, 55626, 9884, 83115, 40971,
	30671, 18135, 14452, 38861, 17844, 201826, 5549, 26413, 17189, 13561, 38539, 10679, 143331, 3314, 36785, 171194,
	49685, 187713, 67506, 4618, 104039, 17060, 195080, 50648, 33159, 19238, 67559, 134840, 28599, 157523, 17130, 38064,
	117398, 94355, 31918, 13575, 34538, 40326, 13997, 3494, 348283, 62481, 26862, 3603, 104426, 244363, 153709, 112487,
	304612, 199674, 41239, 35545, 54869, 293005, 28223, 26277, 26899, 4533, 18518, 15492, 38587, 80488, 70485, 160395,
	263, 60162, 11382, 222152, 4696, 250751, 51921, 182609, 10707, 48463, 46243, 1227, 49111, 111564, 46502, 33342,
	56846, 68541, 63559, 858, 139927, 16654, 229375, 76759, 26478, 33205, 95828, 23399, 92945, 2637, 35630, 28470,
	143992, 50214, 14174, 21456, 166191, 65665, 1711, 21594, 78019, 97599, 111701, 36, 147151, 110246, 189022, 43021,
	30397, 40757, 131935, 42065, 73335, 48039, 26596, 28984, 15102, 2361, 7421, 202167, 69744, 43766, 52826, 3642,
	83304, 33873, 75140, 63169, 192389, 36551, 92748, 13039, 123959, 233220, 21738, 84447, 77230, 20228, 187852, 19095,
	25799, 92136, 108774, 29237, 53947, 2299, 118106, 2687, 8830, 42331, 202924, 33667, 2023, 73763, 30704, 19363,
	19779, 16737, 35629, 48081, 24068, 101013, 162338, 291912, 13749, 24745, 328289, 167679, 70086, 48299, 23306, 16732,
	17801, 43322, 54589, 3586, 63653, 43624, 53474, 925, 109177, 251316, 43805, 13082, 19511, 86565, 142182, 92461,
	17117, 101033, 103319, 64589, 4022, 4351, 235897, 5352, 82705, 107142, 46391, 156084, 5860, 61365, 10558, 13045,
	7717, 18357, 33922, 12590, 33065, 6928, 46993, 783, 46937, 67846, 8952, 26295, 6107, 119656, 18799, 17458,
	50747, 4229, 179559, 112727, 118080, 20683, 41464, 125468, 51560, 49749, 44231, 7359, 35339, 62988, 136487, 67015,
	5208, 29150, 24956, 105186, 48858, 6143, 18097, 6972, 16404, 73489, 58742, 97196, 36357, 164616, 5834, 32267,
	13746, 147733, 15113, 132091, 34127, 106298, 39729, 106426, 22294, 9780, 15602, 36213, 71502, 42808, 66802, 599,
	60755, 5851, 39120, 67363, 108623, 126368, 72770, 91263, 32486, 30596, 151717, 7951, 52002, 43103, 11768, 68942,
	40901, 39344, 24037, 127500, 116890, 48403, 16926, 86750, 17745, 48648, 159545, 34460, 58419, 5634, 114317, 67865,
	31462, 23352, 24010, 98185, 125708, 69686, 68337, 13610, 26271, 70691, 2980, 4768, 27225, 102402, 75453, 28106,
	8104, 6931, 1176, 6274, 6475, 112635, 22498, 6176, 238686, 26832, 28893, 90319, 14441, 15682, 15087, 39517,
	45270, 109134, 104440, 45965, 47645, 81772, 7876, 52683, 87720, 12898, 4505, 185665, 2769, 113401, 15664, 57592,
	105229, 137381, 97059, 119268, 6876, 43309, 33886, 128363, 35476, 144249, 67013, 143587, 83367, 25703, 91436, 59347,
	53236, 2289, 16519, 19844, 46309, 58558, 99834, 23313, 218816, 231303, 36388, 51333, 183535, 109792, 139277, 54306,
	90139, 18235, 8275, 32710, 37677, 82464, 86025, 92204, 88842, 117723, 37570, 128723, 234242, 76350, 73795, 34896,
	148247, 58424, 11105, 11744, 45746, 63372, 17118, 49772, 199520, 81902, 38004, 22911, 33752, 3125, 1995, 53792,
	4689, 26909, 108150, 146062, 69674, 41811, 161444, 84855, 8999, 28561, 16731, 93937, 3189, 21967, 24890, 22943,
	1356, 145300, 51569, 28802, 517, 118679, 31703, 40607, 48098, 108854, 25003, 10233, 73969, 177495, 5248, 24516,
	215347, 146192, 48712, 60626, 69188, 40735, 5866, 586, 101541, 6509, 47590, 52129, 5969, 222045, 110933, 25733,
	24223, 65339, 62812, 2414, 155418, 35819, 16022, 78423, 43138, 20995, 128255, 240673, 46745, 236093, 72176, 57085,
	97841, 61248, 107, 36068, 193177, 105427, 55726, 215229, 20446, 47228, 100420, 87091, 14429, 121708, 23605, 21157,
	187721, 21880, 2997, 203976, 99166, 95068, 25877, 7724, 98925, 83401, 4829, 13182, 18229, 13718, 239662, 38653,
	116505, 153497, 30589, 89029, 38962, 181302, 43853, 78872, 180301, 4786, 248240, 7401, 106136, 112590, 77745, 19731,
	60880, 77789, 125748, 135487, 5975, 48627, 34084, 12419, 215770, 47557, 254582, 10364, 106495, 21856, 67539, 88981,
	38805, 21428, 48732, 42316, 12149, 16078, 52808, 25327, 51322, 33850, 51147, 12253, 122354, 46077, 56483, 254553,
	115417, 81834, 150991, 94662, 86668, 7381, 12841, 100650, 18218, 15741, 22372, 68294, 50705, 15535, 84660, 61887,
	22553, 72299, 31361, 24824, 17743, 46820, 64288, 31582, 77006, 111674, 116384, 30760, 80920, 86149, 77192, 51979,
	79691, 60342, 122805, 103800, 240873, 160744, 233114, 78962, 54920, 8608, 3484, 316104, 72548, 24337, 5088, 230040,
	21926, 10172, 36838, 26, 86221, 83458, 102176, 12062, 17571, 41929, 41170, 28428, 68239, 41750, 103930, 2634,
	18313, 53019, 34825, 97837, 63115, 24606, 73157, 152474, 14715, 91439, 37033, 109806, 140259, 30668, 174760, 380,
	135597, 95673, 136073, 65073, 134249, 13829, 17279, 122305, 4420, 46444, 10237, 64848, 203623, 70728, 10349, 182885,
	65075, 24519, 25783, 40318, 34139, 22222, 63394, 55266, 102764, 41422, 20126, 65100, 90408, 53640, 35128, 48932,
	11192, 38935, 96839, 34782, 39492, 19396, 41332, 6250, 5511, 19492, 51304, 25936, 104466, 54099, 73771, 86115,
	5080, 7669, 30891, 111700, 13931, 25276, 72289, 135447, 14820, 258641, 25265, 31005, 281179, 75286, 393, 95359,
	14623, 13584, 6680, 101227, 80173, 44933, 76666, 54542, 13244, 39348, 458, 25379, 109451, 134348, 81143, 6959,
	65554, 12027, 51311, 8716, 57589, 140731, 28467, 23316, 17272, 30458, 25980, 55229, 77197, 83798, 28302, 114784,
	7428, 34548, 26241, 14712, 39336, 103304, 18928, 54080, 12870, 334, 87722, 15208, 16895, 142098, 114262, 39820,
	83913, 57817, 28682, 7721, 14900, 108672, 11250, 62246, 42849, 415188, 1724, 26555, 24549, 25505, 26443, 107450,
	145899, 61035, 43528, 6901, 60726, 65906, 267741, 21338, 147590, 42079, 18924, 73017, 135236, 15393, 5206, 4026,
	84185, 1531, 5988, 113890, 82647, 303391, 7386, 69844, 71611, 189865, 76523, 31877, 13315, 19314, 198575, 32821,
	1928, 67641, 25913, 104475, 103489, 3297, 70391, 18406, 15446, 113347, 19295, 93790, 27856, 1792, 167471, 116449,
	8541, 4408, 41757, 63233, 25765, 86680, 64501, 27034, 24816, 34975, 6079, 4486, 49693, 36229, 16917, 21581,
	62426, 27862, 11612, 54284, 35702, 194034, 355, 24277, 48262, 87411, 70504, 310164, 118018, 12516, 47559, 43502,
	57433, 107139, 9290, 66533, 80863, 14634, 34312, 91725, 28606, 21342, 67241, 72355, 43244, 375789, 37402, 174015,
	105070, 8342, 44167, 67494, 1890, 16365, 11723, 271002, 1865, 47918, 8350, 45564, 27742, 25110, 125803, 8553,
	49504, 81925, 62211, 4534, 15491, 19011, 80373, 206920, 667, 102405, 128623, 245524, 5553, 113309, 192739, 65766,
	19567, 22832, 261958, 29679, 21293, 71134, 20962, 105123, 24721, 860, 21752, 33448, 18372, 157167, 94822, 35770,
	173224, 232737, 75729, 28937, 46828, 28062, 25453, 5207, 140366, 36665, 30652, 6169, 67920, 150458, 92040, 23186,
	184604, 92330, 20891, 176492, 49427, 27828, 38305, 42495, 143982, 49560, 25503, 90043, 29747, 65328, 47830, 12932,
	11068, 77721, 9003, 25213, 94205, 140426, 46090, 89945, 138173, 192691, 33329, 112232, 129905, 35709, 27514, 1841,
	19957, 31411, 127476, 53572, 17497, 173549, 55063, 175135, 19841, 69314, 5192, 237921, 117660, 150697, 4060, 273045,
	50414, 98940, 65348, 153665, 164423, 58804, 156695, 48994, 213928, 86036, 28608, 8355, 39574, 34540, 16927, 135680,
	18374, 151587, 10830, 53805, 16878, 16623, 4282, 48030, 8537, 14986, 46102, 13062, 72897, 72, 33050, 108227,
	39451, 45935, 651, 113320, 40535, 95176, 57450, 48843, 5003, 19019, 10407, 211163, 3848, 1068, 4988, 32091,
	30095, 41692, 15099, 43602, 107434, 50744, 7627, 171349, 16313, 150832, 352665, 207750, 33937, 38256, 51091, 156000,
	87889, 90663, 84175, 24908, 114900, 50365, 31494, 83829, 5398, 169342, 47521, 54818, 18935, 8356, 43094, 41212,
	174536, 10082, 92550, 6678, 60614, 23355, 69721, 14796, 34149, 128830, 58187, 3179, 208, 40325, 28399, 225029,
	401412, 51150, 31580, 207268, 6657, 10993, 69818, 64282, 289845, 23308, 12961, 38447, 6681, 52944, 31855, 2572,
	47646, 120728, 179148, 37240, 45196, 218274, 4816, 3695, 21961, 50084, 35209, 18073, 51452, 27004, 6100, 33941,
	1377, 84831, 171214, 85, 141510, 9078, 99227, 32610, 6417, 11718, 49868, 65579, 87902, 73018, 49062, 46280,
	61742, 21512, 40862, 107733, 15941, 29168, 157765, 144919, 14487, 5767, 158014, 140070, 7241, 573, 71584, 16921,
	223566, 40331, 179473, 35081, 47926, 140885, 41508, 52104, 59180, 42310, 32811, 29048, 123517, 102413, 80208, 10104,
	14746, 12649, 153641, 126022, 37965, 113017, 4171, 83, 142592, 2809, 6362, 50416, 71323, 116894, 260776, 16204,
	1524, 5760, 30351, 12658, 20703, 54403, 36083, 45408, 74772, 4946, 14485, 50759, 111222, 10890, 2195, 167147,
	92962, 130534, 16283, 177256, 35016, 15472, 210156, 151187, 73922, 117691, 43250, 52051, 37392, 24811, 24358, 30830,
	5775, 818, 21969, 1476, 127322, 151783, 58392, 31021, 106913, 65215, 89407, 90802, 28531, 11690, 20234, 95249,
	44602, 37256, 18707, 11928, 5161, 4410, 26571, 51903, 49768, 22008, 25252, 65780, 209499, 68769, 203726, 13249,
	137363, 48845, 86823, 6658, 5674, 31881, 1083, 1823, 108676, 34518, 166752, 13791, 14287, 91576, 91429, 8665,
	11529, 26401, 16191, 91972, 30964, 5254, 28486, 54697, 79613, 66520, 18447, 22870, 45203, 194466, 22822, 51703,
	12278, 76716, 44595, 73455, 33546, 12235, 144843, 36154, 51247, 11116, 33040, 3180, 225753, 60864, 1972, 28469,
	12891, 28879, 10338, 144157, 56294, 353058, 38302, 41447, 87532, 110616, 27065, 168438, 6557, 1213, 50804, 144643,
	24817, 2390, 136531, 38174, 247513, 16190, 4059, 122791, 131994, 137430, 39506, 57650, 16305, 5188, 54309, 106128,
	20628, 88071, 67394, 395446, 250285, 66176, 91254, 1399, 114196, 43915, 60230, 44853, 27206, 106353, 43013, 18733,
	345105, 226453, 51202, 16607, 57106, 117175, 35492, 10476, 89598, 127439, 15187, 39624, 13688, 61570, 10615, 31111,
	59370, 6238, 175252, 32143, 224492, 41388, 95408, 34384, 148238, 78307, 38959, 9340, 160091, 61443, 15737, 11216,
	41244, 170, 38299, 102443, 113097, 26382, 14027, 33707, 3957, 76300, 66160, 19431, 18900, 6952, 1717, 108656,
	82206, 188021, 257335, 27295, 43999, 41210, 31777, 46956, 57457, 12657, 11489, 15697, 48060, 204748, 53583, 82422,
	284790, 30503, 137341, 8120, 19615, 220311, 15991, 10217, 63424, 9808, 67431, 70976, 98221, 4491, 15177, 28535,
	144789, 751, 13230, 2394, 1504, 33977, 132104, 30316, 22230, 931, 97193, 185240, 24826, 22687, 174322, 15307,
	22988, 1390, 188745, 180325, 29580, 59068, 74903, 18994, 29195, 79, 15436, 7622, 38462, 11566, 138710, 44828,
	45774, 37768, 99236, 68137, 84083, 19282, 22698, 17134, 74807, 126662, 173497, 46248, 16938, 119735, 3212, 28292,
	213652, 49013, 9975, 32180, 45660, 86250, 4801, 68788, 95490, 77482, 113751, 11994, 44624, 94452, 46839, 128497,
	100316, 5798, 58588, 73184, 202987, 65417, 37790, 88524, 1606, 43156, 97964, 105717, 34947, 11203, 100060, 37742,
	130074, 93653, 107799, 94311, 196106, 41347, 8035, 10780, 16390, 27883, 118236, 167395, 1979, 25006, 19375, 31628,
	18916, 144723, 78502, 114047, 103107, 86492, 107686, 5844, 20934, 206963, 23556, 22591, 16562, 146333, 20167, 10471,
	117434, 33085, 2863, 9740, 36669, 41849, 37271, 22790, 18209, 28979, 8231, 12952, 54408, 21731, 25130, 45208,
	55748, 138120, 75826, 414, 29593, 9925, 292865, 25999, 683, 123149, 7036, 92159, 86055, 61827, 103680, 23176,
	54918, 58466, 57578, 13305, 5709, 86479, 16697, 31064, 17660, 200919, 10770, 49793, 33423, 32370, 52047, 16488,
	62555, 6459, 8426, 83493, 7763, 59725, 82812, 18628, 67760, 79405, 68557, 9612, 7673, 28102, 56517, 69620,
	171797, 32458, 29541, 15870, 81109, 32080, 207644, 71495, 21202, 11039, 91036, 61230, 2810, 130800, 32260, 4613,
	60590, 37112, 75214, 33979, 126402, 155062, 30642, 63875, 12810, 194463, 82799, 47664, 16725, 36685, 43367, 61099,
	449, 172150, 102867, 21691, 301838, 36745, 7130, 18671, 57316, 34852, 38034, 54182, 35578, 65900, 99486, 19771,
	3456, 2658, 16914, 99866, 28390, 28109, 8262, 21147, 34353, 20006, 4228, 137085, 1675, 203023, 283196, 198286,
	214375, 163329, 290603, 152574, 40471, 83506, 30068, 14730, 23177, 131539, 34759, 27668, 32178, 71896, 104799, 116305,
	85430, 119262, 42860, 25160, 8911, 23428, 49437, 105322, 6519, 16203, 6349, 74711, 1230, 38045, 8540, 75165,
	44736, 25909, 51026, 317034, 4984, 32281, 91312, 27060, 44431, 17817, 45363, 155937, 239085, 35697, 59784, 91993,
	29531, 126740, 213757, 76560, 167776, 285273, 24262, 8237, 65030, 41160, 74437, 48804, 118916, 13159, 37842, 1031,
	75349, 1478, 11655, 108777, 23435, 277425, 101734, 67469, 70231, 124711, 43532, 28514, 65526, 54956, 1000, 21882,
	17728, 25302, 40952, 52214, 149632, 1999, 2111, 3259, 63362, 89961, 220561, 39777, 26335, 9063, 10572, 12416,
	34551, 34623, 38604, 24723, 5947, 15588, 69927, 66252, 119177, 69173, 46629, 28714, 70715, 212408, 20521, 406913,
	74380, 11716, 50659, 50862, 37009, 88460, 130101, 7210, 53853, 538, 65120, 151950, 55806, 163748, 52837, 13153,
	21100, 16674, 64536, 6091, 138201, 44837, 58547, 3723, 163, 2177, 32288, 85454, 34033, 8497, 14282, 25742,
	10535, 10741, 79559, 117493, 243787, 49337, 100718, 79495, 40139, 42956, 7551, 55433, 15421, 31509, 23034, 45081,
	547, 61176, 53434, 328001, 8470, 36263, 30145, 4519, 74173, 53935, 11845, 73774, 60211, 78025, 3, 4102,
	73782, 109293, 315332, 48412, 26683, 13714, 6865, 20128, 18490, 104141, 325, 39470, 171970, 115860, 15707, 7268,
	73301, 74336, 31370, 2368, 111827, 107757, 136231, 142844, 97138, 96638, 84053, 38691, 23801, 1588, 10573, 122098,
	77039, 240, 186135, 146101, 11996, 18143, 112963, 46171, 155836, 348769, 47795, 121213, 116266, 132515, 3344, 144804,
	31286, 99187, 255838, 129694, 35894, 48779, 55235, 148582, 71967, 65282, 15174, 13920, 47080, 6147, 108242, 157593,
	125025, 7136, 1286, 28957, 127956, 28402, 98813, 20805, 7532, 109417, 40610, 5041, 32958, 15142, 18408, 108596,
	33543, 50517, 27748, 80114, 233434, 91447, 487, 37094, 100048, 30541, 43477, 10639, 89862, 155868, 37667, 8726,
	60684, 237903, 73408, 99589, 12190, 38739, 97348, 3914, 13594, 2680, 149016, 13907, 30171, 28343, 23530, 115225,
	61104, 35821, 147679, 14337, 4297, 244282, 24085, 326976, 56428, 7851, 21303, 131620, 71446, 83253, 68692, 111870,
	5224, 15813, 38197, 49026, 45057, 13660, 3306, 76345, 40671, 27905, 91072, 996, 68527, 62085, 91351, 122634,
	55109, 168209, 2024, 27560, 112707, 17352, 8306, 167115, 169921, 166958, 5031, 46020, 11844, 67284, 19130, 76185,
	6920, 32849, 5450, 14610, 22451, 21002, 17392, 31872, 66682, 84796, 13709, 40210, 59898, 12029, 8719, 53564,
	21462, 91884, 21647, 88379, 194428, 12754, 37797, 132826, 160016, 22567, 54383, 53186, 77611, 31107, 8339, 4694,
	19185, 90355, 23597, 17222, 140675, 28442, 23668, 55977, 9128, 61555, 28774, 155229, 17658, 9390, 24379, 69357,
	15752, 127381, 239631, 62460, 93181, 55913, 45133, 140155, 18676, 25249, 33164, 29581, 82837, 67223, 22362, 29975,
	7317, 52813, 1943, 29613, 20012, 207130, 49617, 49651, 5636, 15334, 36313, 29226, 28084, 95247, 72072, 19000,
	224932, 15811, 114, 32127, 38097, 37508, 88507, 37225, 27359, 91626, 12193, 69279, 20608, 11055, 88156, 92808,
	2152, 57259, 55275, 72789, 24475, 104414, 1708, 9882, 3818, 48661, 66897, 1631, 34806, 227930, 85815, 87753,
	18321, 250664, 72733, 25107, 206797, 50891, 8082, 196411, 92596, 96764, 152823, 65514, 22819, 387277, 62176, 51225,
	40329, 15563, 189, 3659, 73670, 64357, 51793, 275136, 33482, 86653, 74615, 67058, 11318, 125720, 15388, 22388,
	8267, 1730, 102663, 170910, 40784, 7144, 85373, 13040, 7088, 94309, 583, 44224, 140424, 77439, 18496, 164026,
	36578, 4722, 9151, 5824, 63365, 26510, 35199, 40500, 79277, 32495, 44614, 35233, 9566, 203293, 152144, 7097,
	2330, 183480, 98629, 13423, 330887, 44130, 68600, 30939, 97829, 31012, 345465, 56747, 94879, 4939, 160027, 149761,
	99423, 46099, 32251, 15332, 8761, 96094, 128555, 5763, 235318, 222223, 55729, 30241, 55420, 201746, 3987, 81382,
	8259, 49325, 23287, 7719, 24633, 251100, 92311, 18591, 110533, 64759, 170260, 393860, 7175, 21144, 132887, 3593,
	75346, 101277, 91109, 16387, 259187, 11627, 57459, 173829, 44694, 55780, 49797, 89192, 120443, 62622, 3904, 14814,
	23887, 1027, 112258, 64955, 99800, 11132, 66353, 36202, 48624, 18158, 88481, 96882, 43059, 11040, 2455, 7077,
	21651, 181159, 99126, 100434, 61388, 68186, 19161, 110468, 120052, 8819, 55324, 41494, 7014, 37689, 3618, 87729,
	92615, 207943, 9823, 128657, 12587, 15857, 6379, 67628, 51216, 71775, 157617, 63244, 1503, 3864, 218754, 110864,
	5769, 21492, 7243, 1192, 87921, 85529, 31512, 18537, 42698, 35350, 73510, 84474, 34301, 8991, 21013, 35034,
	566, 38832, 19838, 35586, 37216, 39413, 55006, 12178, 59742, 856, 84563, 6900, 25632, 17437, 49786, 30723,
	13847, 70845, 4044, 7843, 23944, 235976, 55530, 48942, 6518, 20939, 73769, 192653, 52936, 95207, 23895, 132542,
	142982, 22632, 87452, 48042, 54018, 178468, 10728, 26230, 23559, 363, 81269, 142012, 5718, 346258, 31456, 84333,
	246476, 51018, 66692, 101804, 120570, 39962, 30373, 70593, 2864, 60541, 19425, 54209, 104092, 7201, 31545, 48018,
	25865, 15442, 46257, 40443, 8328, 6451, 111782, 47527, 97754, 33046, 470, 245116, 31095, 39, 91934, 87208,
	73470, 36708, 36521, 12801, 70624, 36272, 8892, 79768, 12427, 55454, 103756, 5908, 52390, 62962, 22720, 141138,
	94634, 41689, 128402, 126390, 6628, 106394, 35527, 134394, 82727, 254651, 194502, 148064, 89549, 3202, 28359, 957,
	21954, 27906, 49840, 142747, 8307, 24206, 48978, 1186, 71728, 133038, 71474, 91306, 6333, 110959, 74600, 70387,
	18983, 62609, 56057, 22970, 1147, 135850, 1321, 28834, 3578, 59715, 102227, 32827, 81415, 99952, 55636, 257598,
	390, 22702, 35701, 85872, 402916, 39216, 189795, 14929, 19467, 10112, 144422, 61514, 5279, 63421, 134686, 41436,
	8424, 51925, 10598, 132295, 124416, 4604, 194739, 210929, 57866, 31829, 51626, 50007, 9976, 91878, 61906, 56168,
	81906, 60918, 61859, 40017, 23059, 16887, 40927, 62064, 12785, 32893, 32913, 21782, 93965, 20169, 44387, 79084,
	38463, 11457, 93950, 27127, 157050, 2697, 337088, 5116, 54128, 48255, 33279, 8821, 27352, 25515, 124022, 65710,
	28906, 38557, 33390, 1722, 104435, 72215, 38551, 12094, 30978, 25113, 6671, 37355, 175109, 42862, 98024, 65406,
	221276, 59624, 118012, 64637, 78760, 86697, 21426, 1639, 40350, 12584, 67193, 84144, 31396, 7863, 143011, 69629,
	63112, 9454, 28666, 65798, 46372, 134721, 6314, 51402, 30837, 151922, 2847, 38676, 38008, 92823, 136245, 17540,
	5504, 109295, 205242, 37606, 5211, 214892, 1586, 20670, 208711, 137743, 19328, 40652, 16995, 20023, 14657, 154919,
	34422, 12996, 13918, 38221, 47690, 16398, 2959, 37680, 89122, 6721, 198469, 91876, 172043, 83898, 101992, 26084,
	94570, 3635, 76958, 22853, 76497, 38266, 176590, 168403, 44464, 142840, 79180, 184594, 1984, 41806, 83147, 11985,
	6546, 366068, 59732, 24533, 271505, 8736, 39084, 222992, 93429, 28962, 58985, 86665, 8432, 30028, 14548, 32439,
	54424, 165029, 55175, 27458, 69046, 121277, 46168, 33732, 20661, 24581, 135574, 123110, 37556, 79260, 72611, 16957,
	12939, 46162, 58238, 44907, 72936, 253758, 41324, 32518, 96480, 11949, 124438, 65280, 43256, 34107, 53533, 43531,
	37037, 28366, 45970, 32741, 173438, 6121, 194202, 62969, 26355, 30314, 58370, 28455, 1848, 50519, 82830, 90393,
	21761, 295490, 10936, 256940, 133568, 44050, 20269, 4089, 27457, 21610, 219460, 36743, 14821, 101388, 52005, 13124,
	30979, 140816, 167362, 26054, 18458, 60789, 34917, 40447, 26606, 33422, 9066, 3452, 83614, 5761, 20263, 137238,
	25038, 91310, 101, 52322, 74548, 42572, 38084, 214054, 186568, 31802, 17665, 30620, 141936, 37730, 14420, 4265,
	187218, 49640, 188208, 51441, 55388, 96452, 66659, 40869, 42039, 60967, 221027, 19234, 178581, 29105, 96050, 9165,
	196118, 157335, 3738, 40354, 117436, 2965, 34136, 59659, 15570, 50843, 230035, 31444, 71260, 43886, 18316, 5387,
	38500, 168508, 17406, 32174, 8828, 103373, 143806, 90367, 3560, 18719, 122310, 16508, 26719, 2541, 105429, 6645,
	37998, 73190, 10591, 235916, 49737, 87112, 233941, 53188, 32193, 79154, 4544, 52905, 126477, 7580, 63501, 57314,
	3216, 31337, 6541, 103083, 60846, 49, 9756, 15481, 1355, 43840, 14319, 13743, 27486, 10222, 73114, 230718,
	418644, 16706, 6674, 279748, 23058, 45273, 295831, 86306, 2743, 5535, 88773, 21829, 35253, 120938, 31153, 3169,
	16839, 42847, 8751, 80974, 33942, 36867, 35514, 16485, 26474, 77775, 56877, 5391, 48346, 3882, 108713, 31403,
	27804, 55248, 26235, 43821, 136104, 40118, 175507, 28034, 203908, 18732, 1788, 34030, 106427, 36958, 54359, 7251,
	44936, 15356, 69139, 455, 157915, 22173, 140291, 50348, 43275, 82066, 49621, 54952, 15216, 36226, 96695, 66855,
	6936, 1987, 8227, 196087, 4631, 68827, 99004, 47541, 110265, 17953, 147605, 110242, 58520, 31312, 38724, 329975,
	642, 3155, 34497, 75937, 6207, 73843, 6120, 17249, 51429, 117746, 3218, 910, 68961, 319671, 14938, 29555,
	34700, 1649, 66673, 72268, 9655, 76800, 153087, 6941, 210168, 27130, 35398, 1780, 73242, 3135, 56689, 19556,
	165307, 8765, 35967, 121458, 13333, 70453, 17350, 117253, 22265, 13340, 44265, 39869, 441, 3742, 135025, 23581,
	33309, 16543, 17731, 13291, 157637, 283005, 21408, 101360, 63887, 52312, 83873, 5338, 233779, 23759, 186949, 34531,
	177320, 38069, 156465, 91004, 19353, 59852, 68160, 14891, 1338, 1072, 29823, 1950, 28901, 81407, 313445, 73038,
	84807, 162348, 240257, 37162, 138934, 16111, 58013, 41253, 102951, 16457, 96056, 19541, 56402, 67217, 41638, 94381,
	89674, 29481, 37456, 80815, 151579, 13937, 13683, 132537, 19699, 134545, 67020, 29816, 222341, 141235, 427578, 48868,
	129557, 233342, 23077, 87871, 16213, 18728, 16184, 9469, 37913, 19680, 2798, 171356, 178328, 13216, 50049, 72690,
	71904, 124644, 55455, 7504, 29052, 41036, 266546, 19899, 30391, 188755, 8659, 59469, 16, 104298, 112943, 53865,
	76203, 138226, 68857, 139953, 14125, 107625, 119795, 173133, 4398, 50273, 48808, 54390, 16466, 122086, 31835, 67035,
	50971, 48859, 7508, 46427, 66477, 73021, 84615, 39985, 83076, 46779, 201569, 53336, 36443, 60865, 168164, 143810,
	51393, 25548, 169307, 32896, 24485, 38424, 21837, 29087, 275813, 51674, 6714, 64883, 46169, 187369, 55186, 76192,
	12852, 12018, 62134, 31067, 118303, 16542, 12125, 10579, 4928, 26291, 43854, 7091, 10946, 253716, 109062, 39283,
	17261, 113012, 258512, 47764, 125126, 32646, 55892, 80279, 201623, 149872, 3192, 385, 1208, 48750, 5376, 58738,
	22335, 5427, 82416, 47811, 32435, 143086, 38930, 94128, 59975, 156037, 37977, 38224, 62485, 7698, 50405, 71027,
	16462, 21559, 136153, 34131, 107506, 162069, 63703, 3101, 215029, 40407, 4178, 3774, 9187, 80019, 17880, 97926,
	67579, 2600, 18405, 8351, 47924, 86638, 70820, 92206, 86453, 29610, 42241, 119200, 3198, 15466, 67813, 57863,
	35454, 4779, 99518, 4649, 104641, 144269, 33730, 38073, 65864, 6838, 109456, 193298, 154007, 5623, 45741, 30846,
	182578, 25573, 157224, 1543, 58575, 138703, 146140, 44971, 49356, 18275, 59064, 20300, 13122, 11848, 24453, 11973,
	9797, 86843, 2919, 25530, 49210, 1130, 161220, 76788, 75373, 85604, 34926, 36014, 17777, 17255, 51533, 11676,
	92226, 51845, 119859, 21525, 5936, 18507, 28050, 1140, 31418, 14857, 34207, 47859, 10750, 36382, 32079, 106909,
	59426, 87757, 38393, 110042, 15965, 97104, 33757, 35344, 97993, 53979, 33651, 45407, 41884, 82515, 173089, 7177,
	58371, 35365, 47543, 51927, 35587, 10670, 23544, 29306, 84233, 39976, 76076, 62097, 9007, 8668, 28119, 78281,
	120790, 19835, 143020, 54968, 18670, 64959, 20649, 34469, 42570, 33001, 136570, 87796, 120044, 1106, 58700, 63951,
	127623, 12805, 83057, 40212, 31773, 49850, 7361, 54336, 347524, 101314, 23751, 19569, 48791, 29174, 49369, 20467,
	7465, 75842, 38281, 623, 112457, 60210, 28849, 51003, 94720, 6426, 90047, 85560, 43761, 3579, 85105, 34607,
	90410, 118528, 7224, 42907, 111163, 18168, 6960, 161135, 191298, 5247, 100584, 127552, 171568, 20121, 91173, 12636,
	54615, 20199, 63730, 98105, 2396, 40387, 14438, 125012, 4765, 33235, 12865, 45299, 37728, 82098, 77872, 114037,
	59253, 19675, 24838, 398016, 102561, 11446, 17069, 57508, 178277, 65836, 99941, 26114, 2585, 271882, 136866, 50126,
	11027, 155648, 118367, 14585, 8910, 123015, 335383, 40434, 41016, 53021, 14439, 87098, 176860, 201543, 121888, 2358,
	9286, 5739, 22666, 54270, 37884, 169381, 33984, 93859, 16124, 89364, 72207, 51639, 76366, 99029, 65812, 2198,
	12147, 174891, 194289, 6986, 30252, 88822, 21284, 11445, 288337, 160821, 33034, 100869, 43852, 25761, 52882, 1144,
	103809, 1924, 84458, 86079, 43411, 13542, 139276, 18141, 34978, 41298, 7276, 26481, 173800, 33210, 17951, 142652,
	33616, 33677, 2210, 19941, 98568, 2486, 192414, 80136, 12058, 235883, 50963, 249638, 29572, 27221, 47034, 6124,
	72107, 63346, 97620, 158513, 299699, 40388, 23235, 37176, 224244, 198386, 121323, 67992, 23827, 63170, 17838, 106622,
	158590, 26807, 5345, 23489, 91891, 55474, 74834, 37981, 13058, 5977, 72552, 34706, 26828, 145172, 19904, 21367,
	34043, 960, 77092, 91381, 4733, 47446, 7680, 41697, 5170, 16960, 14741, 46101, 13656, 473, 51842, 37433,
	11103, 11551, 121951, 13191, 97536, 165932, 50397, 51628, 129028, 9069, 44885, 6590, 59195, 47045, 32940, 225472,
	90345, 21833, 13303, 29407, 96615, 141951, 5198, 6028, 18395, 7181, 3861, 14966, 156358, 167182, 36529, 55253,
	25942, 173153, 30959, 27261, 50691, 150176, 162201, 38467, 48462, 80602, 42163, 118482, 168, 108756, 26011, 17166,
	54149, 456538, 22512, 91374, 13816, 90358, 131615, 18132, 226707, 1824, 28139, 26860, 42253, 93877, 77351, 65575,
	8980, 80574, 22020, 27948, 40422, 91324, 76376, 13528, 39281, 91685, 82215, 122541, 144066, 1983, 193851, 17283,
	26320, 2739, 194978, 4790, 26845, 42627, 61300, 65815, 174612, 55133, 4200, 191130, 79771, 158321, 52280, 166796,
	221620, 62461, 11278, 4067, 88152, 83409, 31717, 121367, 13522, 47325, 37945, 10406, 174348, 249321, 154101, 64912,
	29938, 51775, 17220, 15776, 166138, 78890, 84425, 54121, 42861, 16368, 24572, 291647, 10197, 32073, 22651, 11677,
	97509, 26952, 35787, 18424, 41910, 71614, 94977, 72318, 41594, 70024, 275419, 37702, 60199, 7335, 39107, 61315,
	18271, 18394, 33768, 87884, 104277, 123724, 7277, 56288, 71981, 189803, 49320, 3352, 6798, 14240, 8954, 69220,
	94433, 57372, 28620, 68863, 193727, 85575, 42309, 41667, 67689, 42081, 22543, 44824, 12719, 28540, 114236, 101553,
	27638, 27296, 4300, 5353, 4663, 19379, 94098, 3758, 95888, 95144, 80344, 87320, 28447, 259518, 12718, 71391,
	152731, 37063, 24132, 31911, 104896, 15672, 103782, 1521, 4945, 72541, 23717, 122632, 15619, 87175, 206120, 29428,
	189780, 61416, 28350, 44457, 972, 1175, 47233, 198738, 95789, 41907, 21953, 97034, 59341, 22864, 53713, 16873,
	32971, 20693, 20954, 31336, 21477, 16169, 38370, 16412, 9019, 3841, 24599, 21938, 17085, 6484, 81198, 76413,
	5849, 72514, 12320, 65247, 276175, 37234, 59796, 52642, 16312, 57349, 198507, 94148, 46134, 18958, 125552, 1747,
	18725, 151873, 14901, 5490, 68287, 29470, 3689, 64794, 40814, 26018, 25692, 54450, 2703, 88278, 124886, 173087,
	174000, 24159, 179477, 24276, 46004, 201876, 209202, 445, 52876, 31948, 30206, 157610, 39180, 18439, 44124, 50469,
	5774, 96278, 222758, 200216, 50290, 45486, 20435, 46986, 46276, 140133, 142326, 15569, 13363, 47522, 92583, 2182,
	7135, 16853, 22998, 30272, 4952, 63263, 35623, 39096, 53789, 44864, 20053, 110392, 124213, 4630, 16087, 28221,
	127787, 25839, 77481, 44693, 13464, 113146, 6983, 27069, 55717, 50102, 4760, 7107, 26186, 66507, 59145, 36032,
	104182, 71328, 29425, 64317, 50781, 47465, 94298, 69706, 74899, 22754, 120756, 25108, 93077, 56834, 73286, 39928,
	16218, 41699, 176763, 7555, 70819, 50083, 26895, 23315, 26014, 16773, 123079, 41712, 5719, 31516, 90427, 158540,
	85051, 183128, 40864, 27505, 55392, 9058, 45224, 96857, 30901, 136622, 96557, 56304, 120061, 11501, 151448, 5773,
	89743, 7769, 86069, 2935, 18471, 41628, 10114, 33660, 110170, 49479, 26745, 92846, 33221, 26731, 18795, 87076,
	8550, 2100, 29972, 120289, 3077, 72490, 33784, 2630, 208722, 50861, 63483, 79029, 6419, 39467, 14302, 45286,
	64207, 9686, 67513, 44170, 1050, 77246, 59266, 17055, 53801, 7150, 11111, 42432, 4278, 94579, 362117, 36175,
	42902, 41933, 39002, 98489, 22913, 74161, 84773, 57036, 17556, 162288, 74485, 178760, 93867, 73635, 128860, 50362,
	261, 67455, 80001, 46080, 35662, 4368, 25247, 19230, 74393, 22588, 1822, 27682, 235324, 13798, 85998, 13194,
	235067, 23514, 71669, 147632, 23191, 134748, 214683, 105101, 1518, 25489, 247114, 7380, 54842, 26922, 3971, 26361,
	20844, 68642, 170517, 77339, 123255, 8963, 77818, 150998, 48466, 36806, 2732, 23261, 11741, 236162, 18243, 126216,
	28690, 50546, 16385, 92760, 197383, 246558, 201295, 88255, 67588, 71687, 176076, 172653, 169058, 33906, 63747, 24835,
	157621, 43338, 30050, 46152, 132741, 2770, 51371, 94835, 6614, 15112, 11749, 56936, 1250, 19027, 399017, 58036,
	100215, 23388, 55815, 308768, 124152, 94803, 9521, 64186, 8971, 28, 30427, 62163, 7616, 103838, 35079, 29203,
	131235, 7743, 17389, 10882, 37420, 61460, 228512, 85363, 41581, 131077, 62822, 119647, 10130, 54445, 26925, 19968,
	29016, 24446, 74028, 24176, 61448, 67185, 9254, 8563, 119129, 9771, 99184, 37716, 39514, 10532, 221512, 258753,
	218630, 55980, 23394, 32141, 61924, 66749, 32411, 3741, 36475, 26678, 77010, 44946, 91203, 128749, 116953, 20476,
	49625, 53116, 13735, 102335, 29376, 51946, 83407, 67892, 59212, 34685, 21083, 1546, 112982, 32972, 74397, 1078,
	190545, 16082, 86140, 58591, 89611, 101531, 10061, 105104, 76319, 20035, 17551, 52611, 169061, 190842, 100780, 23907,
	90413, 115619, 9675, 34710, 193435, 49443, 129734, 11183, 258877, 16318, 136182, 126808, 44635, 27304, 192375, 2599,
	125648, 47051, 12091, 23814, 721, 58800, 40137, 66726, 97930, 60877, 74487, 7942, 54326, 9841, 41428, 13762,
	8211, 85383, 6950, 99177, 79806, 201786, 296464, 124087, 13144, 29741, 41721, 47634, 55088, 254286, 106408, 17041,
	99064, 12942, 64086, 45233, 14005, 2612, 55827, 255, 7984, 13980, 38574, 12776, 46654, 73499, 249951, 2101,
	26676, 25996, 132326, 116415, 119062, 50449, 31033, 23038, 11589, 179252, 20007, 14860, 129270, 21143, 17796, 144715,
	60106, 70758, 69842, 34674, 282133, 44014, 16774, 57268, 38528, 24053, 46373, 201667, 28327, 471023, 51889, 102667,
	21193, 114909, 84132, 69317, 96723, 67969, 16134, 68145, 15058, 28765, 32035, 2524, 101089, 98664, 25045, 76571,
	14957, 86040, 118506, 262428, 154764, 81573, 39681, 283900, 73287, 127825, 544, 80448, 52347, 38512, 175971, 15180,
	45467, 33086, 46552, 48894, 81107, 43213, 36672, 54025, 76703, 8053, 7608, 13299, 56619, 20752, 238099, 54164,
	105133, 1444, 32942, 953, 37564, 8000, 66316, 119463, 106817, 404, 13667, 149108, 128597, 31267, 10269, 49836,
	106150, 1484, 52330, 76965, 160486, 171648, 38456, 31263, 22424, 37738, 66245, 67467, 143369, 60471, 75610, 20895,
	115528, 86070, 60854, 40796, 49347, 18989, 15030, 11371, 37578, 15779, 79867, 10187, 86462, 46402, 155626, 93200,
	40229, 7090, 57547, 108053, 99598, 11088, 47505, 41218, 206017, 2173, 20988, 30219, 22919, 80563, 57566, 42369,
	93141, 41675, 2407, 182519, 120495, 27154, 16702, 29456, 14349, 7958, 16688, 117177, 140375, 42467, 261919, 74916,
	153569, 10836, 34742, 49526, 7621, 105997, 12212, 2270, 392377, 7755, 17959, 25086, 232152, 138791, 33847, 13860,
	35316, 5811, 1344, 71259, 50452, 207539, 92635, 50359, 5821, 33674, 30255, 2086, 2587, 96264, 17543, 42,
	6029, 9580, 43007, 139248, 82831, 12917, 29607, 25786, 51467, 42137, 85161, 100698, 31561, 88989, 121990, 278500,
	3602, 109344, 37982, 15279, 116442, 28936, 30880, 87894, 58079, 128661, 126731, 67392, 28051, 146885, 4861, 16216,
	97344, 42827, 147561, 153948, 22684, 21335, 47685, 1853, 43349, 15185, 59642, 10229, 25520, 187921, 108972, 5579,
	98037, 24945, 6697, 19193, 63734, 137934, 75056, 89740, 19767, 224268, 56138, 63643, 151661, 39313, 70618, 84031,
	89723, 84074, 13703, 85626, 35460, 8867, 64845, 3439, 57906, 99776, 63968, 49270, 81130, 34356, 16210, 23547,
	36446, 34090, 140028, 72439, 2221, 22163, 57058, 363492, 113754, 18913, 95451, 48663, 54464, 54037, 176097, 68425,
	3023, 34906, 29482, 117389, 341780, 80431, 58330, 16753, 92616, 60907, 94846, 147486, 4498, 48646, 7773, 46801,
	7778, 18946, 464978, 47558, 33223, 177444, 7328, 15626, 63337, 94700, 11743, 9351, 255024, 39098, 16447, 42647,
	96230, 39769, 58840, 10068, 63439, 35800, 65843, 58823, 413844, 9156, 51258, 7434, 61791, 85018, 6872, 3692,
	28096, 7121, 33024, 6009, 75532, 31997, 192535, 9661, 3304, 9547, 14753, 31987, 25314, 55689, 15896, 20430,
	39472, 31340, 99744, 25398, 115569, 54883, 28719, 205423, 23071, 57855, 64638, 149867, 25671, 82403, 37616, 20668,
	39989, 77996, 74948, 140555, 175248, 64810, 36515, 46595, 4958, 248773, 24045, 28728, 136673, 168704, 20804, 114833,
	100325, 27135, 21205, 96151, 153134, 45992, 7093, 13992, 76047, 1980, 19432, 145001, 75159, 87462, 17710, 1013,
	45556, 34297, 144882, 20648, 26061, 11319, 129567, 108555, 18872, 464580, 33386, 22717, 65948, 167189, 5603, 135042,
	79542, 8801, 202632, 18114, 91882, 5973, 5239, 67315, 4431, 60916, 47819, 71693, 32597, 32606, 18183, 45072,
	80329, 76385, 24749, 51305, 40314, 156514, 14693, 130345, 13168, 66214, 18029, 12858, 34801, 27628, 14544, 10823,
	40522, 40185, 33739, 148694, 23548, 9923, 61012, 28859, 17933, 19442, 34364, 99849, 164107, 141167, 30629, 21054,
	6744, 36491, 8096, 42474, 41706, 155060, 30650, 10600, 163442, 1143, 96655, 61390, 52359, 7559, 51568, 64256,
	203854, 4467, 22453, 14504, 436398, 7878, 6980, 8293, 63610, 293747, 16167, 35763, 19627, 147603, 15419, 18032,
	110744, 51346, 33681, 54571, 40472, 48615, 39073, 21604, 13754, 173027, 92560, 11083, 47299, 63062, 11813, 52007,
	29883, 9734, 139722, 15953, 1550, 20651, 13616, 49306, 16113, 90089, 92326, 7584, 30712, 72424, 164858, 6831,
	152871, 55746, 197721, 34167, 196442, 6022, 112107, 55215, 7538, 123381, 4920, 43539, 77165, 8939, 50392, 34192,
	20225, 79762, 22505, 58667, 40770, 29788, 97180, 82835, 4568, 8579, 13273, 363569, 35898, 49983, 436, 36598,
	3237, 131691, 62418, 35591, 8101, 4073, 379438, 65218, 76072, 33887, 2968, 27573, 212619, 288680, 68278, 72851,
	150504, 217896, 6913, 121339, 22017, 35340, 51072, 43616, 75043, 31437, 10833, 81487, 4364, 22968, 41454, 106687,
	85446, 19863, 109625, 149241, 524, 141850, 214404, 54376, 657, 237023, 9401, 108137, 53800, 32474, 49712, 53334,
	126876, 27337, 45552, 177696, 8269, 15036, 12097, 42240, 2328, 125374, 119295, 99715, 2500, 19624, 39441, 27220,
	102691, 60957, 94543, 39101, 18566, 67362, 13975, 78230, 25017, 34017, 239007, 90027, 39351, 41681, 35354, 43822,
	1043, 916, 58587, 141983, 94818, 38799, 75459, 41114, 67432, 16195, 36606, 59568, 22272, 126769, 31424, 68659,
	12287, 134302, 257977, 5756, 207285, 95637, 47248, 117689, 19583, 77451, 22373, 12200, 54993, 117118, 34244, 29386,
	34562, 53819, 71267, 64172, 77665, 49368, 7716, 59301, 25749, 45426, 194789, 17297, 2650, 1766, 32501, 45198,
	20403, 20984, 6600, 14171, 94604, 19037, 5402, 29896, 9938, 59935, 109708, 88081, 145182, 44844, 39167, 352626,
	164173, 35374, 45982, 6122, 154, 73419, 220487, 53834, 53601, 17992, 8609, 229321, 5610, 68098, 66815, 71012,
	95069, 140968, 27396, 8957, 134489, 24656, 86659, 56598, 134852, 17316, 123838, 255436, 6613, 41610, 138033, 81452,
	32023, 32396, 123687, 63398, 8693, 29712, 30407, 19296, 121188, 3551, 36099, 20032, 111948, 56624, 16547, 27453,
	35916, 15378, 52039, 56849, 13489, 22214, 73177, 53097, 277349, 2157, 14029, 187886, 10260, 141743, 246460, 91880,
	50869, 3788, 49486, 133566, 54950, 33120, 129337, 53768, 18333, 9525, 26902, 312251, 10297, 9020, 70759, 16647,
	112432, 59260, 84609, 9818, 82766, 73569, 468, 46001, 75780, 55028, 52106, 11498, 43645, 108069, 17150, 17753,
	29417, 16705, 31799, 9606, 289, 122254, 115975, 8620, 6133, 255357, 56908, 14456, 133464, 43554, 79224, 11247,
	29630, 160, 12756, 25464, 65960, 350428, 62521, 321796, 100359, 67358, 35169, 46172, 113128, 48988, 88868, 31094,
	33266, 6847, 60887, 98188, 49659, 69117, 92977, 220228, 13947, 80181, 35103, 62170, 97351, 13475, 2440, 199768,
	19498, 36597, 46971, 25234, 67806, 62881, 84717, 73648, 181966, 10488, 94149, 21550, 26655, 63436, 48375, 14405,
	165650, 9621, 24439, 28043, 42735, 4490, 29963, 56674, 45373, 1934, 262446, 50855, 67098, 26898, 5261, 52696,
	40644, 33900, 9440, 180286, 87162, 22940, 19704, 26936, 69769, 10254, 101759, 27406, 12243, 48000, 73926, 113215,
	54935, 5726, 192787, 4312, 106216, 9366, 11550, 52949, 23457, 212271, 277152, 133895, 108374, 6191, 96477, 29980,
	218916, 58024, 54696, 40853, 91124, 65894, 91170, 65908, 252552, 6793, 29212, 15389, 44516, 122515, 52617, 35058,
	9017, 103536, 39510, 49136, 19242, 130652, 662077, 74699, 47024, 31422, 8517, 73351, 24399, 13867, 128360, 4810,
	4434, 61779, 111983, 61036, 17798, 110240, 59722, 102960, 39688, 10001, 23803, 23039, 176498, 56659, 44814, 134295,
	17188, 77577, 74466, 226175, 102472, 154333, 63900, 111747, 18062, 41171, 79669, 32773, 408933, 42562, 28931, 30907,
	107388, 43487, 2946, 240310, 23938, 24354, 319, 184983, 7927, 6488, 1422, 10790, 68809, 68209, 64775, 4361,
	202, 17123, 59634, 51200, 44391, 18188, 17843, 2619, 74278, 3230, 9540, 47187, 21702, 36274, 56894, 43907,
	16310, 34790, 16866, 6150, 5561, 13587, 107545, 108873, 126867, 86986, 28640, 33427, 19017, 5762, 80637, 17430,
	46903, 2047, 131055, 25958, 13558, 5444, 47152, 13900, 44563, 122857, 45348, 70863, 39593, 54332, 38068, 33637,
	318, 40310, 143467, 18502, 24520, 11377, 62013, 28942, 27246, 28269, 83545, 17999, 59015, 90707, 30065, 15161,
	34720, 1263, 37008, 2012, 6060, 98575, 92933, 5721, 299, 199555, 24578, 29223, 2985, 743, 115825, 109523,
	136657, 47454, 26378, 53586, 3733, 174945, 93340, 244456, 5693, 37386, 28782, 89767, 27545, 23573, 18798, 136425,
	34320, 84778, 20041, 48453, 38215, 7477, 71958, 40621, 8773, 5874, 187927, 105965, 51100, 43533, 18083, 8443,
	10180, 43597, 2003, 183999, 69689, 12216, 129696, 146188, 62389, 34044, 68410, 12765, 43273, 26949, 266807, 3345,
	34477, 79197, 5688, 47539, 213110, 21634, 22257, 50092, 32222, 42346, 39530, 63668, 98, 134978, 74022, 5152,
	59088, 174145, 37220, 9934, 9545, 118937, 5724, 87240, 19875, 15784, 40143, 23263, 87513, 181654, 285152, 37881,
	263241, 4966, 43934, 10433, 186657, 6470, 74416, 225854, 25908, 142677, 246262, 32280, 6192, 75890, 45546, 143264,
	135305, 29742, 47013, 77787, 11732, 126658, 8763, 37950, 21806, 57557, 113464, 89465, 108995, 164574, 23894, 22996,
	23169, 15369, 23117, 17642, 130607, 40503, 36239, 280990, 44666, 9981, 40427, 147487, 26869, 168452, 32886, 32991,
	46798, 240839, 15111, 70502, 65697, 88548, 44145, 28701, 48767, 31139, 206777, 35659, 181164, 166262, 14554, 171445,
	31786, 66523, 76607, 17956, 6507, 31279, 90476, 116611, 167918, 6560, 1243, 115324, 80128, 41867, 55897, 187323,
	37069, 32596, 189444, 145931, 13390, 105530, 65709, 26805, 6999, 55714, 41300, 22915, 68951, 22138, 21120, 22264,
	10058, 19945, 33635, 56123, 99085, 10032, 5818, 6016, 46649, 57476, 35264, 94413, 112522, 262288, 93686, 83038,
	14341, 23204, 28807, 66084, 77987, 6101, 126673, 7133, 38126, 5923, 122091, 170240, 97772, 46874, 215746, 43948,
	41622, 3272, 55596, 8332, 146411, 251315, 13533, 8561, 81521, 115449, 48616, 175175, 2063, 186556, 3036, 134537,
	75772, 29728, 82360, 22973, 186559, 86348, 89100, 38388, 82297, 45610, 2613, 87082, 9986, 177812, 57884, 23591,
	47485, 42543, 33582, 44713, 74439, 257444, 252451, 31825, 35631, 38540, 33066, 5147, 13973, 4343, 51830, 70378,
	22827, 26448, 95560, 36896, 241741, 48067, 203953, 298860, 61620, 20450, 3220, 67272, 6586, 107662, 100160, 108684,
	6929, 57226, 4762, 7457, 1320, 40404, 77204, 99309, 62750, 208653, 59977, 44000, 74315, 34332, 5819, 172217,
	64904, 114077, 18147, 84012, 1791, 98456, 90930, 21446, 116669, 103938, 7422, 85140, 59713, 5768, 326211, 16239,
	75411, 13229, 29398, 10758, 236107, 1539, 112472, 95979, 152154, 151294, 306, 21196, 38146, 10700, 6891, 84282,
	109646, 56492, 40539, 6589, 119491, 51354, 30685, 140209, 136906, 29622, 73617, 49553, 70525, 51671, 166869, 139616,
	74395, 37439, 49595, 45678, 11959, 33211, 86560, 52434, 9282, 62690, 112155, 130810, 5243, 108261, 99970, 265613,
	72551, 80049, 6391, 33365, 90721, 66737, 69872, 87011, 1860, 9032, 112544, 60905, 37371, 89015, 140351, 19076,
	850, 373531, 2802, 36725, 218795, 72062, 28990, 16550, 24614, 7815, 6187, 26336, 33373, 32162, 42791, 73555,
	32062, 23386, 10244, 56392, 49442, 27076, 136262, 12412, 14883, 1134, 33675, 97153, 199281, 15608, 100152, 74072,
	47942, 254301, 36451, 16026, 10687, 65067, 56708, 254030, 30290, 50490, 13864, 57941, 259331, 35588, 23485, 43486,
	24869, 21620, 92971, 22072, 88645, 1048, 182050, 13343, 32452, 14825, 19509, 3325, 216938, 45740, 99716, 189082,
	53740, 78245, 25609, 24311, 176777, 47340, 308354, 40669, 66085, 14102, 125339, 9225, 128709, 97207, 1271, 200933,
	78439, 113451, 88975, 18324, 46521, 11819, 18570, 141756, 72512, 170020, 52754, 63550, 118515, 103073, 93330, 32736,
	50499, 14722, 31600, 68452, 398867, 29316, 172786, 18417, 104924, 2606, 5670, 84818, 16288, 67106, 59580, 82929,
	607401, 291, 85829, 359, 15897, 35830, 50696, 65630, 52672, 22115, 356968, 29895, 40837, 231192, 34024, 38957,
	26722, 406, 23335, 124952, 72068, 68804, 13268, 147101, 164740, 276569, 162596, 66943, 11569, 26654, 66358, 4777,
	23229, 102127, 5848, 978, 2921, 59666, 5371, 28212, 90108, 42938, 39320, 2499, 4271, 108792, 33510, 125072,
	71653, 65239, 38250, 66357, 38577, 13964, 86251, 35708, 50755, 36010, 29448, 12209, 3844, 38222, 206337, 100876,
	67827, 137088, 14167, 252225, 84163, 195270, 1306, 5703, 54198, 779, 46802, 22028, 51124, 86759, 70560, 113164,
	35685, 162145, 45471, 34561, 422, 2611, 6464, 47486, 19223, 38246, 9191, 18331, 89942, 243642, 212364, 15893,
	17518, 22617, 6409, 30046, 126182, 59716, 36560, 104428, 18846, 26592, 19458, 50793, 147333, 30826, 1388, 27647,
	10922, 14495, 33545, 19269, 135828, 39727, 41601, 46931, 233379, 49169, 131130, 182112, 16276, 82381, 118209, 142445,
	128310, 19672, 28740, 82907, 33436, 3118, 102206, 28723, 24819, 41937, 38854, 5157, 3881, 111491, 1142, 9776,
	421673, 152241, 29309, 14961, 87854, 6054, 15424, 3796, 82656, 54996, 2108, 55367, 239450, 154525, 9643, 118103,
	106041, 64601, 68549, 48707, 30266, 25772, 18740, 9462, 229669, 91798, 112152, 191327, 14493, 72828, 8175, 66636,
	236474, 25817, 87351, 129027, 76653, 20422, 22983, 71240, 27846, 44661, 12399, 46158, 77704, 53101, 35032, 11072,
	17300, 109294, 33638, 24408, 1895, 11241, 760, 17584, 82479, 125877, 63150, 141075, 34259, 23274, 81698, 15732,
	43577, 48340, 91584, 14688, 16379, 24481, 150280, 96420, 262050, 48635, 43727, 61819, 56268, 72003, 88178, 17281,
	79912, 13218, 122519, 125295, 166396, 11811, 2171, 118930, 67746, 17636, 178278, 174656, 95661, 173039, 83845, 79689,
	17473, 98555, 127696, 203415, 54730, 22925, 232239, 9309, 12136, 175026, 20740, 180188, 10747, 39816, 314017, 266131,
	10040, 175732, 112550, 220651, 31974, 37393, 888, 23008, 86799, 4303, 64905, 148467, 75337, 251, 3284, 370102,
	50264, 9835, 5438, 23655, 4481, 29851, 329, 12855, 7162, 64931, 78141, 12804, 42372, 296771, 83547, 18624,
	34874, 86271, 3360, 48665, 77735, 88767, 11463, 63527, 28889, 22258, 29140, 194315, 113924, 25499, 6406, 31334,
	1845, 4802, 49184, 43455, 35469, 127594, 92970, 61038, 115005, 38840, 87761, 106838, 8811, 20572, 55637, 11162,
	96721, 132425, 108925, 2948, 125457, 36356, 3502, 75270, 27622, 127192, 2561, 123095, 49394, 61155, 16897, 110064,
	9699, 89448, 53356, 19628, 220310, 21622, 83036, 9885, 112214, 6087, 26713, 17901, 161912, 91492, 3440, 68594,
	9266, 92238, 8087, 6866, 150194, 72175, 80701, 13459, 31836, 43243, 239700, 95846, 44749, 50647, 21945, 230538,
	120612, 132371, 244604, 5193, 105637, 34661, 41341, 68775, 85393, 1874, 8771, 33718, 49672, 77403, 595452, 99507,
	6490, 58895, 128742, 7704, 39239, 73217, 43816, 62824, 37804, 199976, 22361, 80005, 87514, 94832, 14089, 4574,
	139975, 59142, 75523, 100268, 43906, 53442, 15152, 2547, 186002, 17011, 19513, 204282, 3343, 60568, 128318, 119250,
	4298, 51871, 41336, 71759, 21921, 45074, 98169, 145889, 99427, 11350, 1237, 5520, 28799, 7803, 53702, 21026,
	136352, 38293, 128690, 12158, 90132, 44600, 10184, 26957, 39459, 126025, 78904, 82999, 59373, 39301, 150198, 120529,
	153042, 20177, 50089, 14764, 271571, 30530, 123161, 38975, 101562, 22941, 5648, 124654, 109243, 69817, 71675, 49162,
	106884, 21241, 107795, 30258, 16572, 188262, 141456, 7688, 60718, 8271, 11044, 32440, 104608, 103419, 236109, 93156,
	43293, 128929, 42107, 67180, 25201, 115254, 185488, 130954, 72813, 167547, 20537, 39969, 38432, 22582, 184022, 1139,
	27199, 5655, 17767, 97412, 122606, 209377, 27070, 35871, 326617, 188954, 42680, 73512, 80911, 22629, 3011, 95021,
	315242, 157737, 383, 41821, 41808, 19335, 27950, 15674, 25677, 110950, 35375, 76835, 59108, 57370, 35262, 16569,
	160415, 37706, 78086, 32041, 49691, 137143, 9782, 172080, 50148, 77917, 6323, 10110, 69172, 17711, 21795, 59511,
	76184, 135114, 31046, 132319, 59105, 157578, 20549, 80778, 57649, 158421, 65143, 4575, 72235, 21899, 10797, 92745,
	34035, 106079, 80159, 4508, 78304, 25350, 75457, 46458, 32937, 25623, 47, 8531, 104751, 84953, 8138, 36508,
	187199, 66310, 115274, 13253, 32461, 38536, 1916, 42007, 187160, 35055, 26325, 84394, 35963, 94216, 45590, 97782
]
