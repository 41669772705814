'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('./lib/object-to-camel.js');
var configure = require('./lib/configure.js');
var toUrlSearchParams = require('./lib/to-url-search-params.js');

const createVersion = configure.configure(api => {
  async function version(options = {}) {
    const res = await api.post('version', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    });
    return {
      ...objectToCamel.objectToCamel(await res.json()),
      'ipfs-http-client': '1.0.0'
    };
  }
  return version;
});

exports.createVersion = createVersion;
