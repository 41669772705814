'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const ipfsCore = '0.11.1';
const commit = '5ddd0c555cc6577ee15667f4ef4e7f3a3b17d04c';
const interfaceIpfsCore = '^0.151.1';

exports.commit = commit;
exports.interfaceIpfsCore = interfaceIpfsCore;
exports.ipfsCore = ipfsCore;
