'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function createIsOnline({network}) {
  return () => {
    const net = network.try();
    return net != null && Boolean(net.libp2p.isStarted());
  };
}

exports.createIsOnline = createIsOnline;
