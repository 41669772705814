'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createLocalAddrs({network}) {
  async function localAddrs(options = {}) {
    const {libp2p} = await network.use(options);
    return libp2p.multiaddrs;
  }
  return withTimeoutOption.withTimeoutOption(localAddrs);
}

exports.createLocalAddrs = createLocalAddrs;
