'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const ERR_IPNS_EXPIRED_RECORD = 'ERR_IPNS_EXPIRED_RECORD';
const ERR_UNRECOGNIZED_VALIDITY = 'ERR_UNRECOGNIZED_VALIDITY';
const ERR_SIGNATURE_CREATION = 'ERR_SIGNATURE_CREATION';
const ERR_SIGNATURE_VERIFICATION = 'ERR_SIGNATURE_VERIFICATION';
const ERR_UNRECOGNIZED_FORMAT = 'ERR_UNRECOGNIZED_FORMAT';
const ERR_PEER_ID_FROM_PUBLIC_KEY = 'ERR_PEER_ID_FROM_PUBLIC_KEY';
const ERR_PUBLIC_KEY_FROM_ID = 'ERR_PUBLIC_KEY_FROM_ID';
const ERR_UNDEFINED_PARAMETER = 'ERR_UNDEFINED_PARAMETER';
const ERR_INVALID_RECORD_DATA = 'ERR_INVALID_RECORD_DATA';
const ERR_INVALID_EMBEDDED_KEY = 'ERR_INVALID_EMBEDDED_KEY';

exports.ERR_INVALID_EMBEDDED_KEY = ERR_INVALID_EMBEDDED_KEY;
exports.ERR_INVALID_RECORD_DATA = ERR_INVALID_RECORD_DATA;
exports.ERR_IPNS_EXPIRED_RECORD = ERR_IPNS_EXPIRED_RECORD;
exports.ERR_PEER_ID_FROM_PUBLIC_KEY = ERR_PEER_ID_FROM_PUBLIC_KEY;
exports.ERR_PUBLIC_KEY_FROM_ID = ERR_PUBLIC_KEY_FROM_ID;
exports.ERR_SIGNATURE_CREATION = ERR_SIGNATURE_CREATION;
exports.ERR_SIGNATURE_VERIFICATION = ERR_SIGNATURE_VERIFICATION;
exports.ERR_UNDEFINED_PARAMETER = ERR_UNDEFINED_PARAMETER;
exports.ERR_UNRECOGNIZED_FORMAT = ERR_UNRECOGNIZED_FORMAT;
exports.ERR_UNRECOGNIZED_VALIDITY = ERR_UNRECOGNIZED_VALIDITY;
