'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var get = require('./get.js');
var put = require('./put.js');
var rm = require('./rm.js');
var stat = require('./stat.js');

class BlockAPI {
  constructor({codecs, hashers, preload, repo}) {
    this.get = get.createGet({
      preload,
      repo
    });
    this.put = put.createPut({
      codecs,
      hashers,
      preload,
      repo
    });
    this.rm = rm.createRm({ repo });
    this.stat = stat.createStat({
      preload,
      repo
    });
  }
}

exports.BlockAPI = BlockAPI;
