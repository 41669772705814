'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createPut({codecs, hashers, repo, preload}) {
  async function put(block, options = {}) {
    const release = options.pin ? await repo.gcLock.readLock() : null;
    try {
      const cidVersion = options.version != null ? options.version : 0;
      const codecName = options.format || (cidVersion === 0 ? 'dag-pb' : 'raw');
      const hasher = await hashers.getHasher(options.mhtype || 'sha2-256');
      const hash = await hasher.digest(block);
      const codec = await codecs.getCodec(codecName);
      const cid$1 = cid.CID.create(cidVersion, codec.code, hash);
      await repo.blocks.put(cid$1, block, { signal: options.signal });
      if (options.preload !== false) {
        preload(cid$1);
      }
      if (options.pin === true) {
        await repo.pins.pinRecursively(cid$1, { signal: options.signal });
      }
      return cid$1;
    } finally {
      if (release) {
        release();
      }
    }
  }
  return withTimeoutOption.withTimeoutOption(put);
}

exports.createPut = createPut;
