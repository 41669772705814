'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createLevel = configure.configure(api => {
  async function level(subsystem, level, options = {}) {
    const res = await api.post('log/level', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: [
          subsystem,
          level
        ],
        ...options
      }),
      headers: options.headers
    });
    return objectToCamel.objectToCamel(await res.json());
  }
  return level;
});

exports.createLevel = createLevel;
