'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var ipfsRepo = require('ipfs-repo');
var datastoreLevel = require('datastore-level');
var blockstoreDatastoreAdapter = require('blockstore-datastore-adapter');
var memory = require('ipfs-repo/locks/memory');

function createRepo(print, codecs, options) {
  const repoPath = options.path || 'ipfs';
  return ipfsRepo.createRepo(repoPath, codeOrName => codecs.getCodec(codeOrName), {
    root: new datastoreLevel.LevelDatastore(repoPath, {
      prefix: '',
      version: 2
    }),
    blocks: new blockstoreDatastoreAdapter.BlockstoreDatastoreAdapter(new datastoreLevel.LevelDatastore(`${ repoPath }/blocks`, {
      prefix: '',
      version: 2
    })),
    datastore: new datastoreLevel.LevelDatastore(`${ repoPath }/datastore`, {
      prefix: '',
      version: 2
    }),
    keys: new datastoreLevel.LevelDatastore(`${ repoPath }/keys`, {
      prefix: '',
      version: 2
    }),
    pins: new datastoreLevel.LevelDatastore(`${ repoPath }/pins`, {
      prefix: '',
      version: 2
    })
  }, {
    autoMigrate: options.autoMigrate,
    onMigrationProgress: options.onMigrationProgress || print,
    repoLock: memory.MemoryLock
  });
}

exports.createRepo = createRepo;
