'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var key = require('interface-datastore/key');
var base32 = require('multiformats/bases/base32');
var cid = require('multiformats/cid');

const PIN_DS_KEY = new key.Key('/local/pins');
const DEFAULT_FANOUT = 256;
const MAX_ITEMS = 8192;
const EMPTY_KEY = cid.CID.parse('QmdfTbBqBPQ7VNxZEYEj14VmRuZBkqFbiwReogJgS1zR1n');
const PinTypes = {
  direct: 'direct',
  recursive: 'recursive'
};
function cidToKey(cid) {
  return new key.Key(`/${ base32.base32.encode(cid.multihash.bytes).toUpperCase().substring(1) }`);
}

exports.DEFAULT_FANOUT = DEFAULT_FANOUT;
exports.EMPTY_KEY = EMPTY_KEY;
exports.MAX_ITEMS = MAX_ITEMS;
exports.PIN_DS_KEY = PIN_DS_KEY;
exports.PinTypes = PinTypes;
exports.cidToKey = cidToKey;
