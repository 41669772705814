'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var toUrlSearchParams = require('../../../lib/to-url-search-params.js');
var utils = require('./utils.js');

function createLs(client) {
  async function ls(options = {}) {
    const {stat, headers, timeout, signal} = options;
    const response = await client.post('pin/remote/service/ls', {
      timeout,
      signal,
      headers,
      searchParams: stat === true ? toUrlSearchParams.toUrlSearchParams({ stat }) : undefined
    });
    const {RemoteServices} = await response.json();
    return RemoteServices.map(utils.decodeRemoteService);
  }
  return ls;
}

exports.createLs = createLs;
