'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var entry = require('./entry.js');
var base58 = require('multiformats/bases/base58');
var message = require('../message/message.js');

const WantType = {
  Block: message.Message.Wantlist.WantType.Block,
  Have: message.Message.Wantlist.WantType.Have
};
const sortBy = (fn, list) => {
  return Array.prototype.slice.call(list, 0).sort((a, b) => {
    const aa = fn(a);
    const bb = fn(b);
    return aa < bb ? -1 : aa > bb ? 1 : 0;
  });
};
class Wantlist {
  constructor(stats) {
    this.set = new Map();
    this._stats = stats;
  }
  get length() {
    return this.set.size;
  }
  add(cid, priority, wantType) {
    const cidStr = cid.toString(base58.base58btc);
    const entry$1 = this.set.get(cidStr);
    if (entry$1) {
      entry$1.inc();
      entry$1.priority = priority;
      if (entry$1.wantType === WantType.Have && wantType === WantType.Block) {
        entry$1.wantType = wantType;
      }
    } else {
      this.set.set(cidStr, new entry.WantListEntry(cid, priority, wantType));
      if (this._stats) {
        this._stats.push(null, 'wantListSize', 1);
      }
    }
  }
  remove(cid) {
    const cidStr = cid.toString(base58.base58btc);
    const entry = this.set.get(cidStr);
    if (!entry) {
      return;
    }
    entry.dec();
    if (entry.hasRefs()) {
      return;
    }
    this.set.delete(cidStr);
    if (this._stats) {
      this._stats.push(null, 'wantListSize', -1);
    }
  }
  removeForce(cidStr) {
    if (this.set.has(cidStr)) {
      this.set.delete(cidStr);
    }
  }
  forEach(fn) {
    return this.set.forEach(fn);
  }
  entries() {
    return this.set.entries();
  }
  sortedEntries() {
    return new Map(sortBy(o => o[1].key, Array.from(this.set.entries())));
  }
  contains(cid) {
    const cidStr = cid.toString(base58.base58btc);
    return this.set.has(cidStr);
  }
  get(cid) {
    const cidStr = cid.toString(base58.base58btc);
    return this.set.get(cidStr);
  }
}
Wantlist.Entry = entry.WantListEntry;

exports.Wantlist = Wantlist;
