'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createStat = configure.configure(api => {
  async function stat(cid$1, options = {}) {
    const res = await api.post('block/stat', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: cid$1.toString(),
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    return {
      cid: cid.CID.parse(data.Key),
      size: data.Size
    };
  }
  return stat;
});

exports.createStat = createStat;
