'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../../lib/configure.js');
var toUrlSearchParams = require('../../lib/to-url-search-params.js');

const createAddLink = configure.configure(api => {
  async function addLink(cid$1, dLink, options = {}) {
    const res = await api.post('object/patch/add-link', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: [
          `${ cid$1 }`,
          dLink.Name || dLink.name || '',
          (dLink.Hash || dLink.cid || '').toString() || null
        ],
        ...options
      }),
      headers: options.headers
    });
    const {Hash} = await res.json();
    return cid.CID.parse(Hash);
  }
  return addLink;
});

exports.createAddLink = createAddLink;
