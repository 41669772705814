'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createLocal({repo}) {
  async function* refsLocal(options = {}) {
    for await (const cid of repo.blocks.queryKeys({}, { signal: options.signal })) {
      yield { ref: cid.toString() };
    }
  }
  return withTimeoutOption.withTimeoutOption(refsLocal);
}

exports.createLocal = createLocal;
