'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var core = require('./core.js');

const configure = fn => {
  return options => {
    return fn(new core.Client(options), options);
  };
};

exports.configure = configure;
