'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var multiaddr = require('multiaddr');

function createClear({repo}) {
  async function clear(options = {}) {
    const config = await repo.config.getAll(options);
    const removed = config.Bootstrap || [];
    config.Bootstrap = [];
    await repo.config.replace(config);
    return { Peers: removed.map(ma => new multiaddr.Multiaddr(ma)) };
  }
  return withTimeoutOption.withTimeoutOption(clear);
}

exports.createClear = createClear;
