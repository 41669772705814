'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('./profiles/index.js');
var get = require('./get.js');
var getAll = require('./get-all.js');
var replace = require('./replace.js');
var set = require('./set.js');

function createConfig(config) {
  return {
    getAll: getAll.createGetAll(config),
    get: get.createGet(config),
    set: set.createSet(config),
    replace: replace.createReplace(config),
    profiles: index.createProfiles(config)
  };
}

exports.createConfig = createConfig;
