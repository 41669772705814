'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var findPeer = require('./find-peer.js');
var findProvs = require('./find-provs.js');
var get = require('./get.js');
var provide = require('./provide.js');
var put = require('./put.js');
var query = require('./query.js');

function createDht(config) {
  return {
    findPeer: findPeer.createFindPeer(config),
    findProvs: findProvs.createFindProvs(config),
    get: get.createGet(config),
    provide: provide.createProvide(config),
    put: put.createPut(config),
    query: query.createQuery(config)
  };
}

exports.createDht = createDht;
