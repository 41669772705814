'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var interfaceDatastore = require('interface-datastore');
var keytransform = require('./keytransform.js');

class NamespaceDatastore extends keytransform.KeyTransformDatastore {
  constructor(child, prefix) {
    super(child, {
      convert(key) {
        return prefix.child(key);
      },
      invert(key) {
        if (prefix.toString() === '/') {
          return key;
        }
        if (!prefix.isAncestorOf(key)) {
          throw new Error(`Expected prefix: (${ prefix.toString() }) in key: ${ key.toString() }`);
        }
        return new interfaceDatastore.Key(key.toString().slice(prefix.toString().length), false);
      }
    });
    this.prefix = prefix;
  }
  query(q, options) {
    if (q.prefix && this.prefix.toString() !== '/') {
      return super.query(Object.assign({}, q, { prefix: this.prefix.child(new interfaceDatastore.Key(q.prefix)).toString() }));
    }
    return super.query(q, options);
  }
  queryKeys(q, options) {
    if (q.prefix && this.prefix.toString() !== '/') {
      return super.queryKeys(Object.assign({}, q, { prefix: this.prefix.child(new interfaceDatastore.Key(q.prefix)).toString() }));
    }
    return super.queryKeys(q, options);
  }
}

exports.NamespaceDatastore = NamespaceDatastore;
