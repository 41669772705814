'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index$3 = require('./want-manager/index.js');
var network = require('./network.js');
var index$2 = require('./decision-engine/index.js');
var notifications = require('./notifications.js');
var index = require('./utils/index.js');
var index$1 = require('./stats/index.js');
var nativeAbortController = require('native-abort-controller');
var anySignal = require('any-signal');
var base = require('blockstore-core/base');
var cid = require('multiformats/cid');

const defaultOptions = {
  statsEnabled: false,
  statsComputeThrottleTimeout: 1000,
  statsComputeThrottleMaxQueueSize: 1000
};
const statsKeys = [
  'blocksReceived',
  'dataReceived',
  'dupBlksReceived',
  'dupDataReceived',
  'blocksSent',
  'dataSent',
  'providesBufferLength',
  'wantListLength',
  'peerCount'
];
class Bitswap extends base.BaseBlockstore {
  constructor(libp2p, blockstore, options = {}) {
    super();
    this._libp2p = libp2p;
    this._log = index.logger(this.peerId);
    this._options = Object.assign({}, defaultOptions, options);
    this._stats = new index$1.Stats(statsKeys, {
      enabled: this._options.statsEnabled,
      computeThrottleTimeout: this._options.statsComputeThrottleTimeout,
      computeThrottleMaxQueueSize: this._options.statsComputeThrottleMaxQueueSize
    });
    this.network = new network.Network(libp2p, this, this._stats, { hashLoader: options.hashLoader });
    this.blockstore = blockstore;
    this.engine = new index$2.DecisionEngine(this.peerId, blockstore, this.network, this._stats);
    this.wm = new index$3.WantManager(this.peerId, this.network, this._stats);
    this.notifications = new notifications.Notifications(this.peerId);
    this.started = false;
  }
  isStarted() {
    return this.started;
  }
  get peerId() {
    return this._libp2p.peerId;
  }
  async _receiveMessage(peerId, incoming) {
    try {
      await this.engine.messageReceived(peerId, incoming);
    } catch (err) {
      this._log('failed to receive message', incoming);
    }
    if (incoming.blocks.size === 0) {
      return;
    }
    const received = [];
    for (const [cidStr, data] of incoming.blocks.entries()) {
      const cid$1 = cid.CID.parse(cidStr);
      received.push({
        wasWanted: this.wm.wantlist.contains(cid$1),
        cid: cid$1,
        data
      });
    }
    this.wm.cancelWants(received.filter(({wasWanted}) => wasWanted).map(({cid}) => cid));
    await Promise.all(received.map(({cid, wasWanted, data}) => this._handleReceivedBlock(peerId, cid, data, wasWanted)));
  }
  async _handleReceivedBlock(peerId, cid, data, wasWanted) {
    this._log('received block');
    const has = await this.blockstore.has(cid);
    this._updateReceiveCounters(peerId.toB58String(), cid, data, has);
    if (!wasWanted) {
      return;
    }
    await this.put(cid, data);
  }
  _updateReceiveCounters(peerIdStr, cid, data, exists) {
    this._stats.push(peerIdStr, 'blocksReceived', 1);
    this._stats.push(peerIdStr, 'dataReceived', data.length);
    if (exists) {
      this._stats.push(peerIdStr, 'dupBlksReceived', 1);
      this._stats.push(peerIdStr, 'dupDataReceived', data.length);
    }
  }
  _receiveError(err) {
    this._log.error('ReceiveError: %s', err.message);
  }
  _onPeerConnected(peerId) {
    this.wm.connected(peerId);
  }
  _onPeerDisconnected(peerId) {
    this.wm.disconnected(peerId);
    this.engine.peerDisconnected(peerId);
    this._stats.disconnected(peerId);
  }
  enableStats() {
    this._stats.enable();
  }
  disableStats() {
    this._stats.disable();
  }
  wantlistForPeer(peerId, _options) {
    return this.engine.wantlistForPeer(peerId);
  }
  ledgerForPeer(peerId) {
    return this.engine.ledgerForPeer(peerId);
  }
  async get(cid, options = {}) {
    const fetchFromNetwork = (cid, options) => {
      this.wm.wantBlocks([cid], options);
      return this.notifications.wantBlock(cid, options);
    };
    let promptedNetwork = false;
    const loadOrFetchFromNetwork = async (cid, options) => {
      try {
        const block = await this.blockstore.get(cid, options);
        return block;
      } catch (err) {
        if (err.code !== 'ERR_NOT_FOUND') {
          throw err;
        }
        if (!promptedNetwork) {
          promptedNetwork = true;
          this.network.findAndConnect(cid).catch(err => this._log.error(err));
        }
        return fetchFromNetwork(cid, options);
      }
    };
    const controller = new nativeAbortController.AbortController();
    const signal = options.signal ? anySignal.anySignal([
      options.signal,
      controller.signal
    ]) : controller.signal;
    const block = await Promise.race([
      this.notifications.wantBlock(cid, { signal }),
      loadOrFetchFromNetwork(cid, { signal })
    ]);
    controller.abort();
    return block;
  }
  async *getMany(cids, options = {}) {
    for await (const cid of cids) {
      yield this.get(cid, options);
    }
  }
  unwant(cids) {
    const cidsArray = Array.isArray(cids) ? cids : [cids];
    this.wm.unwantBlocks(cidsArray);
    cidsArray.forEach(cid => this.notifications.unwantBlock(cid));
  }
  cancelWants(cids) {
    this.wm.cancelWants(Array.isArray(cids) ? cids : [cids]);
  }
  async put(cid, block, _options) {
    await this.blockstore.put(cid, block);
    this._sendHaveBlockNotifications(cid, block);
  }
  async *putMany(source, options) {
    for await (const {key, value} of this.blockstore.putMany(source, options)) {
      this._sendHaveBlockNotifications(key, value);
      yield {
        key,
        value
      };
    }
  }
  _sendHaveBlockNotifications(cid, data) {
    this.notifications.hasBlock(cid, data);
    this.engine.receivedBlocks([{
        cid,
        data
      }]);
    this.network.provide(cid).catch(err => {
      this._log.error('Failed to provide: %s', err.message);
    });
  }
  getWantlist() {
    return this.wm.wantlist.entries();
  }
  peers() {
    return this.engine.peers();
  }
  stat() {
    return this._stats;
  }
  start() {
    this.wm.start();
    this.network.start();
    this.engine.start();
    this.started = true;
  }
  stop() {
    this._stats.stop();
    this.wm.stop();
    this.network.stop();
    this.engine.stop();
    this.started = false;
  }
  unwrap() {
    return this.blockstore;
  }
}

exports.Bitswap = Bitswap;
