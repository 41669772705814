'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var _export = require('./export.js');
var gen = require('./gen.js');
var _import = require('./import.js');
var info = require('./info.js');
var list = require('./list.js');
var rename = require('./rename.js');
var rm = require('./rm.js');

class KeyAPI {
  constructor({keychain}) {
    this.gen = gen.createGen({ keychain });
    this.list = list.createList({ keychain });
    this.rm = rm.createRm({ keychain });
    this.rename = rename.createRename({ keychain });
    this.export = _export.createExport({ keychain });
    this.import = _import.createImport({ keychain });
    this.info = info.createInfo({ keychain });
  }
}

exports.KeyAPI = KeyAPI;
