'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var put = require('../dag/put.js');

const createPut = (codecs, options) => {
  const fn = configure.configure(api => {
    const dagPut = put.createPut(codecs, options);
    async function put$1(obj, options = {}) {
      return dagPut(obj, {
        ...options,
        format: 'dag-pb',
        hashAlg: 'sha2-256',
        version: 0
      });
    }
    return put$1;
  });
  return fn(options);
};

exports.createPut = createPut;
