'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var objectToCamelWithMetadata = require('../lib/object-to-camel-with-metadata.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createStat = configure.configure(api => {
  async function stat(path, options = {}) {
    const res = await api.post('files/stat', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: path,
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    data.WithLocality = data.WithLocality || false;
    return toCoreInterface(objectToCamelWithMetadata.objectToCamelWithMetadata(data));
  }
  return stat;
});
function toCoreInterface(entry) {
  entry.cid = cid.CID.parse(entry.hash);
  delete entry.hash;
  return entry;
}

exports.createStat = createStat;
