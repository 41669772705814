'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createRm({keychain}) {
  const rm = name => keychain.removeKey(name);
  return withTimeoutOption.withTimeoutOption(rm);
}

exports.createRm = createRm;
