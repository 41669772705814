'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('./lib/configure.js');
var toUrlSearchParams = require('./lib/to-url-search-params.js');

const createStop = configure.configure(api => {
  async function stop(options = {}) {
    const res = await api.post('shutdown', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams(options),
      headers: options.headers
    });
    await res.text();
  }
  return stop;
});

exports.createStop = createStop;
