'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

function toPin(type, cid$1, metadata) {
  const pin = {
    type,
    cid: cid.CID.parse(cid$1)
  };
  if (metadata) {
    pin.metadata = metadata;
  }
  return pin;
}
const createLs = configure.configure(api => {
  async function* ls(options = {}) {
    let paths = [];
    if (options.paths) {
      paths = Array.isArray(options.paths) ? options.paths : [options.paths];
    }
    const res = await api.post('pin/ls', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        ...options,
        arg: paths.map(path => `${ path }`),
        stream: true
      }),
      headers: options.headers
    });
    for await (const pin of res.ndjson()) {
      if (pin.Keys) {
        for (const cid of Object.keys(pin.Keys)) {
          yield toPin(pin.Keys[cid].Type, cid, pin.Keys[cid].Metadata);
        }
        return;
      }
      yield toPin(pin.Type, pin.Cid, pin.Metadata);
    }
  }
  return ls;
});

exports.createLs = createLs;
