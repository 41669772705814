'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var toUrlSearchParams = require('../../lib/to-url-search-params.js');

const decodePin = ({
  Name: name,
  Status: status,
  Cid: cid$1
}) => {
  return {
    cid: cid.CID.parse(cid$1),
    name,
    status
  };
};
const encodeService = service => {
  if (typeof service === 'string' && service !== '') {
    return service;
  } else {
    throw new TypeError('service name must be passed');
  }
};
const encodeCID = cid$1 => {
  if (cid.CID.asCID(cid$1)) {
    return cid$1.toString();
  } else {
    throw new TypeError(`CID instance expected instead of ${ typeof cid$1 }`);
  }
};
const encodeQuery = ({service, cid, name, status, all}) => {
  const query = toUrlSearchParams.toUrlSearchParams({
    service: encodeService(service),
    name,
    force: all ? true : undefined
  });
  if (cid) {
    for (const value of cid) {
      query.append('cid', encodeCID(value));
    }
  }
  if (status) {
    for (const value of status) {
      query.append('status', value);
    }
  }
  return query;
};
const encodeAddParams = ({cid, service, background, name, origins}) => {
  const params = toUrlSearchParams.toUrlSearchParams({
    arg: encodeCID(cid),
    service: encodeService(service),
    name,
    background: background ? true : undefined
  });
  if (origins) {
    for (const origin of origins) {
      params.append('origin', origin.toString());
    }
  }
  return params;
};

exports.decodePin = decodePin;
exports.encodeAddParams = encodeAddParams;
exports.encodeCID = encodeCID;
exports.encodeQuery = encodeQuery;
exports.encodeService = encodeService;
