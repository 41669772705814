'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createSubs({ipns, options}) {
  const experimental = options.EXPERIMENTAL;
  async function subs(options = {}) {
    const pubsub = utils.getPubsubRouting(ipns, experimental);
    return pubsub.getSubscriptions(options);
  }
  return withTimeoutOption.withTimeoutOption(subs);
}

exports.createSubs = createSubs;
