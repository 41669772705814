'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createGen = configure.configure(api => {
  async function gen(name, options = {
    type: 'rsa',
    size: 2048
  }) {
    const res = await api.post('key/gen', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: name,
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    return objectToCamel.objectToCamel(data);
  }
  return gen;
});

exports.createGen = createGen;
