'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var key = require('interface-datastore/key');
var fromString = require('uint8arrays/from-string');

const apiFile = new key.Key('api');
function apiAddr(store) {
  return {
    async get() {
      const value = await store.get(apiFile);
      return value && value.toString();
    },
    set(value) {
      return store.put(apiFile, fromString.fromString(value.toString()));
    },
    delete() {
      return store.delete(apiFile);
    }
  };
}

exports.apiAddr = apiAddr;
