'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var wantlist = require('./wantlist.js');
var wantlistForPeer = require('./wantlist-for-peer.js');
var unwant = require('./unwant.js');
var stat = require('./stat.js');

class BitswapAPI {
  constructor({network}) {
    this.wantlist = wantlist.createWantlist({ network });
    this.wantlistForPeer = wantlistForPeer.createWantlistForPeer({ network });
    this.unwant = unwant.createUnwant({ network });
    this.stat = stat.createStat({ network });
  }
}

exports.BitswapAPI = BitswapAPI;
