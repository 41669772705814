'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../lib/object-to-camel.js');
var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');

const createImport = configure.configure(api => {
  async function importKey(name, pem, password, options = {}) {
    const res = await api.post('key/import', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: name,
        pem,
        password,
        ...options
      }),
      headers: options.headers
    });
    const data = await res.json();
    return objectToCamel.objectToCamel(data);
  }
  return importKey;
});

exports.createImport = createImport;
