'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var objectToCamel = require('../../lib/object-to-camel.js');
var configure = require('../../lib/configure.js');
var toUrlSearchParams = require('../../lib/to-url-search-params.js');

const createCancel = configure.configure(api => {
  async function cancel(name, options = {}) {
    const res = await api.post('name/pubsub/cancel', {
      signal: options.signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: name,
        ...options
      }),
      headers: options.headers
    });
    return objectToCamel.objectToCamel(await res.json());
  }
  return cancel;
});

exports.createCancel = createCancel;
