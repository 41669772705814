'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var ipfsUnixfsExporter = require('ipfs-unixfs-exporter');
var utils = require('../utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var cid = require('multiformats/cid');

function createCat({repo, preload}) {
  async function* cat(ipfsPath, options = {}) {
    ipfsPath = utils.normalizeCidPath(ipfsPath);
    if (options.preload !== false) {
      const pathComponents = ipfsPath.split('/');
      preload(cid.CID.parse(pathComponents[0]));
    }
    const file = await ipfsUnixfsExporter.exporter(ipfsPath, repo.blocks, options);
    if (file.type === 'directory') {
      throw new Error('this dag node is a directory');
    }
    if (!file.content) {
      throw new Error('this dag node has no content');
    }
    yield* file.content(options);
  }
  return withTimeoutOption.withTimeoutOption(cat);
}

exports.createCat = createCat;
