'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cancel = require('./cancel.js');
var state = require('./state.js');
var subs = require('./subs.js');

class PubSubAPI {
  constructor({ipns, options}) {
    this.cancel = cancel.createCancel({
      ipns,
      options
    });
    this.state = state.createState({
      ipns,
      options
    });
    this.subs = subs.createSubs({
      ipns,
      options
    });
  }
}

exports.PubSubAPI = PubSubAPI;
