'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var interfaceDatastore = require('interface-datastore');
var shard = require('./shard.js');
var base = require('./base.js');
var keytransform = require('./keytransform.js');
var errors = require('./errors.js');
var shardReadme = require('./shard-readme.js');

const shardKey = new interfaceDatastore.Key(shard.SHARDING_FN);
const shardReadmeKey = new interfaceDatastore.Key(shard.README_FN);
class ShardingDatastore extends base.BaseDatastore {
  constructor(store, shard) {
    super();
    this.child = new keytransform.KeyTransformDatastore(store, {
      convert: this._convertKey.bind(this),
      invert: this._invertKey.bind(this)
    });
    this.shard = shard;
  }
  async open() {
    await this.child.open();
    this.shard = await ShardingDatastore.create(this.child, this.shard);
  }
  _convertKey(key) {
    const s = key.toString();
    if (s === shardKey.toString() || s === shardReadmeKey.toString()) {
      return key;
    }
    const parent = new interfaceDatastore.Key(this.shard.fun(s));
    return parent.child(key);
  }
  _invertKey(key) {
    const s = key.toString();
    if (s === shardKey.toString() || s === shardReadmeKey.toString()) {
      return key;
    }
    return interfaceDatastore.Key.withNamespaces(key.list().slice(1));
  }
  static async createOrOpen(store, shard) {
    try {
      await ShardingDatastore.create(store, shard);
    } catch (err) {
      if (err && err.message !== 'datastore exists')
        throw err;
    }
    return ShardingDatastore.open(store);
  }
  static async open(store) {
    const shard$1 = await shard.readShardFun('/', store);
    return new ShardingDatastore(store, shard$1);
  }
  static async create(store, shard$1) {
    const hasShard = await store.has(shardKey);
    if (!hasShard && !shard$1) {
      throw errors.dbOpenFailedError(Error('Shard is required when datastore doesn\'t have a shard key already.'));
    }
    if (!hasShard) {
      const put = typeof store.putRaw === 'function' ? store.putRaw.bind(store) : store.put.bind(store);
      await Promise.all([
        put(shardKey, new TextEncoder().encode(shard$1.toString() + '\n')),
        put(shardReadmeKey, new TextEncoder().encode(shardReadme))
      ]);
      return shard$1;
    }
    const diskShard = await shard.readShardFun('/', store);
    const a = (diskShard || '').toString();
    const b = shard$1.toString();
    if (a !== b) {
      throw new Error(`specified fun ${ b } does not match repo shard fun ${ a }`);
    }
    return diskShard;
  }
  put(key, val, options) {
    return this.child.put(key, val, options);
  }
  get(key, options) {
    return this.child.get(key, options);
  }
  has(key, options) {
    return this.child.has(key, options);
  }
  delete(key, options) {
    return this.child.delete(key, options);
  }
  async *putMany(source, options = {}) {
    yield* this.child.putMany(source, options);
  }
  async *getMany(source, options = {}) {
    yield* this.child.getMany(source, options);
  }
  async *deleteMany(source, options = {}) {
    yield* this.child.deleteMany(source, options);
  }
  batch() {
    return this.child.batch();
  }
  query(q, options) {
    const tq = {
      offset: q.offset,
      limit: q.limit,
      orders: [],
      filters: [
        e => e.key.toString() !== shardKey.toString(),
        e => e.key.toString() !== shardReadmeKey.toString()
      ]
    };
    const {prefix} = q;
    if (prefix != null) {
      tq.filters.push(e => {
        return this._invertKey(e.key).toString().startsWith(prefix);
      });
    }
    if (q.filters != null) {
      const filters = q.filters.map(f => {
        const filter = ({key, value}) => {
          return f({
            key: this._invertKey(key),
            value
          });
        };
        return filter;
      });
      tq.filters = tq.filters.concat(filters);
    }
    if (q.orders != null) {
      tq.orders = q.orders.map(o => {
        const order = (a, b) => {
          return o({
            key: this._invertKey(a.key),
            value: a.value
          }, {
            key: this._invertKey(b.key),
            value: b.value
          });
        };
        return order;
      });
    }
    return this.child.query(tq, options);
  }
  queryKeys(q, options) {
    const tq = {
      offset: q.offset,
      limit: q.limit,
      orders: [],
      filters: [
        key => key.toString() !== shardKey.toString(),
        key => key.toString() !== shardReadmeKey.toString()
      ]
    };
    const {prefix} = q;
    if (prefix != null) {
      tq.filters.push(key => {
        return this._invertKey(key).toString().startsWith(prefix);
      });
    }
    if (q.filters != null) {
      const filters = q.filters.map(f => {
        const filter = key => {
          return f(this._invertKey(key));
        };
        return filter;
      });
      tq.filters = tq.filters.concat(filters);
    }
    if (q.orders != null) {
      tq.orders = q.orders.map(o => {
        const order = (a, b) => o(this._invertKey(a), this._invertKey(b));
        return order;
      });
    }
    return this.child.queryKeys(tq, options);
  }
  close() {
    return this.child.close();
  }
}

exports.ShardingDatastore = ShardingDatastore;
