'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var get = require('../get.js');
var put = require('../put.js');

function createAddLink({repo, preload}) {
  const get$1 = get.createGet({
    repo,
    preload
  });
  const put$1 = put.createPut({
    repo,
    preload
  });
  async function addLink(cid, link, options = {}) {
    const node = await get$1(cid, options);
    return put$1({
      ...node,
      Links: node.Links.concat([link])
    }, options);
  }
  return withTimeoutOption.withTimeoutOption(addLink);
}

exports.createAddLink = createAddLink;
