'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var key = require('interface-datastore/key');
var errors = require('datastore-core/errors');

const CONFIG_KEY = new key.Key('/config');
const VERSION_KEY = new key.Key('/version');
function findLevelJs(store) {
  let db = store;
  while (db.db || db.child) {
    db = db.db || db.child;
    if (db.type === 'level-js' || db.constructor.name === 'Level') {
      return db;
    }
  }
}
async function hasWithFallback(key, has, store) {
  const result = await has(key);
  if (result) {
    return result;
  }
  const levelJs = findLevelJs(store);
  if (!levelJs) {
    return false;
  }
  return new Promise((resolve, reject) => {
    const req = levelJs.store('readonly').get(key.toString());
    req.transaction.onabort = () => {
      reject(req.transaction.error);
    };
    req.transaction.oncomplete = () => {
      resolve(Boolean(req.result));
    };
  });
}
async function getWithFallback(key, get, has, store) {
  if (await has(key)) {
    return get(key);
  }
  const levelJs = findLevelJs(store);
  if (!levelJs) {
    throw errors.notFoundError();
  }
  return new Promise((resolve, reject) => {
    const req = levelJs.store('readonly').get(key.toString());
    req.transaction.onabort = () => {
      reject(req.transaction.error);
    };
    req.transaction.oncomplete = () => {
      if (req.result) {
        return resolve(req.result);
      }
      reject(errors.notFoundError());
    };
  });
}
function wrapStore(store) {
  const originalGet = store.get.bind(store);
  const originalHas = store.has.bind(store);
  store.get = key => getWithFallback(key, originalGet, originalHas, store);
  store.has = key => hasWithFallback(key, originalHas, store);
  return store;
}
function wrapBackends(backends) {
  return {
    ...backends,
    root: wrapStore(backends.root),
    datastore: wrapStore(backends.datastore),
    pins: wrapStore(backends.pins),
    keys: wrapStore(backends.keys)
  };
}

exports.CONFIG_KEY = CONFIG_KEY;
exports.VERSION_KEY = VERSION_KEY;
exports.findLevelJs = findLevelJs;
exports.hasWithFallback = hasWithFallback;
exports.wrapBackends = wrapBackends;
