'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var apply = require('./apply.js');
var list = require('./list.js');

function createProfiles(config) {
  return {
    apply: apply.createApply(config),
    list: list.createList(config)
  };
}

exports.createProfiles = createProfiles;
