'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var bitswap = require('./bitswap.js');

const createBitswap = (libp2p, blockstore, options = {}) => {
  return new bitswap.Bitswap(libp2p, blockstore, options);
};

exports.createBitswap = createBitswap;
