'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var anySignal = require('any-signal');

function filter(signals) {
  return signals.filter(Boolean);
}
function abortSignal(...signals) {
  return anySignal.anySignal(filter(signals));
}

exports.abortSignal = abortSignal;
