'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var level = require('./level.js');
var ls = require('./ls.js');
var tail = require('./tail.js');

function createLog(config) {
  return {
    level: level.createLevel(config),
    ls: ls.createLs(config),
    tail: tail.createTail(config)
  };
}

exports.createLog = createLog;
