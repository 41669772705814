'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var addrs = require('./addrs.js');
var connect = require('./connect.js');
var disconnect = require('./disconnect.js');
var localAddrs = require('./local-addrs.js');
var peers = require('./peers.js');

function createSwarm(config) {
  return {
    addrs: addrs.createAddrs(config),
    connect: connect.createConnect(config),
    disconnect: disconnect.createDisconnect(config),
    localAddrs: localAddrs.createLocalAddrs(config),
    peers: peers.createPeers(config)
  };
}

exports.createSwarm = createSwarm;
