'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('./utils.js');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createStat({repo, preload}) {
  async function stat(cid, options = {}) {
    cid = utils.cleanCid(cid);
    if (options.preload !== false) {
      preload(cid);
    }
    const block = await repo.blocks.get(cid);
    return {
      cid,
      size: block.length
    };
  }
  return withTimeoutOption.withTimeoutOption(stat);
}

exports.createStat = createStat;
