'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var add = require('./add.js');
var index = require('./add-all/index.js');
var cat = require('./cat.js');
var get = require('./get.js');
var ls = require('./ls.js');

class RootAPI {
  constructor({preload, repo, options}) {
    const addAll = index.createAddAll({
      preload,
      repo,
      options
    });
    this.addAll = addAll;
    this.add = add.createAdd({ addAll });
    this.cat = cat.createCat({
      repo,
      preload
    });
    this.get = get.createGet({
      repo,
      preload
    });
    this.ls = ls.createLs({
      repo,
      preload
    });
  }
}

exports.RootAPI = RootAPI;
