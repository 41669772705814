'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var murmur3 = require('@multiformats/murmur3');

const hamtHashCode = murmur3.murmur3128.code;
const hamtBucketBits = 8;
async function hamtHashFn(buf) {
  return (await murmur3.murmur3128.encode(buf)).slice(0, 8).reverse();
}

exports.hamtBucketBits = hamtBucketBits;
exports.hamtHashCode = hamtHashCode;
exports.hamtHashFn = hamtHashFn;
