'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var id = require('./id.js');

const createIsOnline = options => {
  const id$1 = id.createId(options);
  async function isOnline(options = {}) {
    const res = await id$1(options);
    return Boolean(res && res.addresses && res.addresses.length);
  }
  return isOnline;
};

exports.createIsOnline = createIsOnline;
