'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var utils = require('../../src/utils.js');
var fromString = require('uint8arrays/from-string');
var toString = require('uint8arrays/to-string');

async function keysToBinary(name, store, onProgress = () => {
}) {
  const db = utils.findLevelJs(store);
  if (!db) {
    onProgress(`${ name } did not need an upgrade`);
    return;
  }
  onProgress(`Upgrading ${ name }`);
  const upgrade = (key, value) => {
    return [
      {
        type: 'del',
        key: key
      },
      {
        type: 'put',
        key: fromString.fromString(key),
        value: value
      }
    ];
  };
  await withEach(db, upgrade);
}
async function keysToStrings(name, store, onProgress = () => {
}) {
  const db = utils.findLevelJs(store);
  if (!db) {
    onProgress(`${ name } did not need a downgrade`);
    return;
  }
  onProgress(`Downgrading ${ name }`);
  const downgrade = (key, value) => {
    return [
      {
        type: 'del',
        key: key
      },
      {
        type: 'put',
        key: toString.toString(key),
        value: value
      }
    ];
  };
  await withEach(db, downgrade);
}
function unwrap(store) {
  if (store.child) {
    return unwrap(store.child);
  }
  return store;
}
async function process(backends, onProgress, fn) {
  const datastores = Object.entries(backends).map(([key, backend]) => ({
    key,
    backend: unwrap(backend)
  })).filter(({key, backend}) => backend.constructor.name === 'LevelDatastore').map(({key, backend}) => ({
    name: key,
    store: backend
  }));
  onProgress(0, `Migrating ${ datastores.length } dbs`);
  let migrated = 0;
  const progress = message => {
    onProgress(Math.round(migrated / datastores.length * 100), message);
  };
  for (const {name, store} of datastores) {
    await store.open();
    try {
      await fn(name, store, progress);
    } finally {
      migrated++;
      await store.close();
    }
  }
  onProgress(100, `Migrated ${ datastores.length } dbs`);
}
const migration = {
  version: 10,
  description: 'Migrates datastore-level keys to binary',
  migrate: (backends, onProgress = () => {
  }) => {
    return process(backends, onProgress, keysToBinary);
  },
  revert: (backends, onProgress = () => {
  }) => {
    return process(backends, onProgress, keysToStrings);
  }
};
function withEach(db, fn) {
  function batch(operations, next) {
    const store = db.store('readwrite');
    const transaction = store.transaction;
    let index = 0;
    let error;
    transaction.onabort = () => next(error || transaction.error || new Error('aborted by user'));
    transaction.oncomplete = () => next();
    function loop() {
      const op = operations[index++];
      const key = op.key;
      let req;
      try {
        req = op.type === 'del' ? store.delete(key) : store.put(op.value, key);
      } catch (err) {
        error = err;
        transaction.abort();
        return;
      }
      if (index < operations.length) {
        req.onsuccess = loop;
      }
    }
    loop();
  }
  return new Promise((resolve, reject) => {
    const it = db.iterator();
    const id = data => data;
    it._deserializeKey = it._deserializeValue = id;
    next();
    function next() {
      const handleNext = (err, key, value) => {
        if (err || key === undefined) {
          const handleEnd = err2 => {
            if (err2) {
              reject(err2);
              return;
            }
            resolve();
          };
          it.end(handleEnd);
          return;
        }
        batch(fn(key, value), next);
      };
      it.next(handleNext);
    }
  });
}

exports.migration = migration;
