'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var errors = require('../errors.js');

async function hasWithFallback(key, has, store) {
  const result = await has(key);
  if (result) {
    return result;
  }
  const levelJs = findLevelJs(store);
  if (!levelJs) {
    return false;
  }
  return new Promise((resolve, reject) => {
    const req = levelJs.store('readonly').get(key.toString());
    req.transaction.onabort = () => {
      reject(req.transaction.error);
    };
    req.transaction.oncomplete = () => {
      resolve(Boolean(req.result));
    };
  });
}
async function getWithFallback(key, get, has, store) {
  if (await has(key)) {
    return get(key);
  }
  const levelJs = findLevelJs(store);
  if (!levelJs) {
    throw new errors.NotFoundError();
  }
  return new Promise((resolve, reject) => {
    const req = levelJs.store('readonly').get(key.toString());
    req.transaction.onabort = () => {
      reject(req.transaction.error);
    };
    req.transaction.oncomplete = () => {
      if (req.result) {
        return resolve(req.result);
      }
      reject(new errors.NotFoundError());
    };
  });
}
function findLevelJs(store) {
  let db = store;
  while (db.db || db.child) {
    db = db.db || db.child;
    if (db.type === 'level-js' || db.constructor.name === 'Level') {
      return db;
    }
  }
}

exports.getWithFallback = getWithFallback;
exports.hasWithFallback = hasWithFallback;
