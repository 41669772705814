'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var addLink = require('./add-link.js');
var appendData = require('./append-data.js');
var rmLink = require('./rm-link.js');
var setData = require('./set-data.js');

class ObjectPatchAPI {
  constructor({repo, preload}) {
    this.addLink = addLink.createAddLink({
      repo,
      preload
    });
    this.appendData = appendData.createAppendData({
      repo,
      preload
    });
    this.rmLink = rmLink.createRmLink({
      repo,
      preload
    });
    this.setData = setData.createSetData({
      repo,
      preload
    });
  }
}

exports.ObjectPatchAPI = ObjectPatchAPI;
