'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var abortSignal = require('../lib/abort-signal.js');
var nativeAbortController = require('native-abort-controller');

const createPublish = configure.configure(api => {
  async function publish(topic, data, options = {}) {
    const searchParams = toUrlSearchParams.toUrlSearchParams({
      arg: topic,
      ...options
    });
    const controller = new nativeAbortController.AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const res = await api.post('pubsub/pub', {
      signal,
      searchParams,
      ...await multipartRequest.multipartRequest(data, controller, options.headers)
    });
    await res.text();
  }
  return publish;
});

exports.createPublish = createPublish;
