'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var gc = require('./gc.js');
var stat = require('./stat.js');
var version = require('./version.js');

class RepoAPI {
  constructor({repo, hashers}) {
    this.gc = gc.createGc({
      repo,
      hashers
    });
    this.stat = stat.createStat({ repo });
    this.version = version.createVersion({ repo });
    this.setApiAddr = addr => repo.apiAddr.set(addr);
  }
}

exports.RepoAPI = RepoAPI;
